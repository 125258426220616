import React from 'react';

export default function Imprint() {
  return (
    <div id="privacy_policy" className="container">
      <div id="privacy_policy_de">
        <h1>Datenschutzerkl&auml;rung</h1>
        <h2 id="m716">Pr&auml;ambel</h2>
        <p>
          Mit der folgenden Datenschutzerkl&auml;rung m&ouml;chten wir Sie
          dar&uuml;ber aufkl&auml;ren, welche Arten Ihrer personenbezogenen
          Daten (nachfolgend auch kurz als "Daten" bezeichnet) wir zu welchen
          Zwecken und in welchem Umfang verarbeiten. Die
          Datenschutzerkl&auml;rung gilt f&uuml;r alle von uns
          durchgef&uuml;hrten Verarbeitungen personenbezogener Daten, sowohl im
          Rahmen der Erbringung unserer Leistungen als auch insbesondere auf
          unseren Webseiten, in mobilen Applikationen sowie innerhalb externer
          Onlinepr&auml;senzen, wie z. B. unserer Social-Media-Profile
          (nachfolgend zusammenfassend bezeichnet als "Onlineangebot").
        </p>
        <p>Die verwendeten Begriffe sind nicht geschlechtsspezifisch.</p>
        <p>Stand: 6. April 2025</p>
        <h2>Inhalts&uuml;bersicht</h2>{' '}
        <ul className="index">
          <li>
            <a className="index-link" href="#m716">
              Pr&auml;ambel
            </a>
          </li>
          <li>
            <a className="index-link" href="#m3">
              Verantwortlicher
            </a>
          </li>
          <li>
            <a className="index-link" href="#mOverview">
              &Uuml;bersicht der Verarbeitungen
            </a>
          </li>
          <li>
            <a className="index-link" href="#m2427">
              Ma&szlig;gebliche Rechtsgrundlagen
            </a>
          </li>
          <li>
            <a className="index-link" href="#m27">
              Sicherheitsma&szlig;nahmen
            </a>
          </li>
          <li>
            <a className="index-link" href="#m25">
              &Uuml;bermittlung von personenbezogenen Daten
            </a>
          </li>
          <li>
            <a className="index-link" href="#m24">
              Internationale Datentransfers
            </a>
          </li>
          <li>
            <a className="index-link" href="#m12">
              Allgemeine Informationen zur Datenspeicherung und L&ouml;schung
            </a>
          </li>
          <li>
            <a className="index-link" href="#m10">
              Rechte der betroffenen Personen
            </a>
          </li>
          <li>
            <a className="index-link" href="#m13">
              Gesch&auml;ftsprozesse und -verfahren
            </a>
          </li>
          <li>
            <a className="index-link" href="#m3575">
              Im Rahmen der Gesch&auml;ftst&auml;tigkeit eingesetzte Anbieter
              und Services
            </a>
          </li>
          <li>
            <a className="index-link" href="#m326">
              Zahlungsverfahren
            </a>
          </li>
          <li>
            <a className="index-link" href="#m225">
              Bereitstellung des Onlineangebots und Webhosting
            </a>
          </li>
          <li>
            <a className="index-link" href="#m367">
              Registrierung, Anmeldung und Nutzerkonto
            </a>
          </li>
          <li>
            <a className="index-link" href="#m182">
              Kontakt- und Anfrageverwaltung
            </a>
          </li>
          <li>
            <a className="index-link" href="#m391">
              Kommunikation via Messenger
            </a>
          </li>
          <li>
            <a className="index-link" href="#m735">
              Videokonferenzen, Onlinemeetings, Webinare und Bildschirm-Sharing
            </a>
          </li>
          <li>
            <a className="index-link" href="#m29">
              Cloud-Dienste
            </a>
          </li>
          <li>
            <a className="index-link" href="#m17">
              Newsletter und elektronische Benachrichtigungen
            </a>
          </li>
          <li>
            <a className="index-link" href="#m136">
              Pr&auml;senzen in sozialen Netzwerken (Social Media)
            </a>
          </li>
          <li>
            <a className="index-link" href="#m723">
              Management, Organisation und Hilfswerkzeuge
            </a>
          </li>
          <li>
            <a className="index-link" href="#m15">
              &Auml;nderung und Aktualisierung
            </a>
          </li>
          <li>
            <a className="index-link" href="#m42">
              Begriffsdefinitionen
            </a>
          </li>
        </ul>
        <h2 id="m3">Verantwortlicher</h2>
        <p>
          SoCraTes Deutschland e.V.
          <br />
          Am Hirschanger 1<br />
          90610 Winkelhaid
        </p>
        <p>E-Mail-Adresse: info@socrates-conference.de</p>
        <p>Telefon: +499187 7061771</p>
        <h2 id="mOverview">&Uuml;bersicht der Verarbeitungen</h2>
        <p>
          Die nachfolgende &Uuml;bersicht fasst die Arten der verarbeiteten
          Daten und die Zwecke ihrer Verarbeitung zusammen und verweist auf die
          betroffenen Personen.
        </p>
        <h3>Arten der verarbeiteten Daten</h3>
        <ul>
          <li>Bestandsdaten.</li>
          <li>Zahlungsdaten.</li>
          <li>Kontaktdaten.</li>
          <li>Inhaltsdaten.</li>
          <li>Vertragsdaten.</li>
          <li>Nutzungsdaten.</li>
          <li>Meta-, Kommunikations- und Verfahrensdaten.</li>
          <li>Bild- und/ oder Videoaufnahmen.</li>
          <li>Tonaufnahmen.</li>
          <li>Protokolldaten.</li>
        </ul>
        <h3>Kategorien betroffener Personen</h3>
        <ul>
          <li>Leistungsempf&auml;nger und Auftraggeber.</li>
          <li>Besch&auml;ftigte.</li>
          <li>Interessenten.</li>
          <li>Kommunikationspartner.</li>
          <li>Nutzer.</li>
          <li>Mitglieder.</li>
          <li>Gesch&auml;fts- und Vertragspartner.</li>
          <li>Abgebildete Personen.</li>
          <li>Dritte Personen.</li>
          <li>Kunden.</li>
        </ul>
        <h3>Zwecke der Verarbeitung</h3>
        <ul>
          <li>
            Erbringung vertraglicher Leistungen und Erf&uuml;llung vertraglicher
            Pflichten.
          </li>
          <li>Kommunikation.</li>
          <li>Sicherheitsma&szlig;nahmen.</li>
          <li>Direktmarketing.</li>
          <li>B&uuml;ro- und Organisationsverfahren.</li>
          <li>Organisations- und Verwaltungsverfahren.</li>
          <li>Feedback.</li>
          <li>Marketing.</li>
          <li>
            Bereitstellung unseres Onlineangebotes und Nutzerfreundlichkeit.
          </li>
          <li>Informationstechnische Infrastruktur.</li>
          <li>&Ouml;ffentlichkeitsarbeit und Informationszwecke.</li>
          <li>Finanz- und Zahlungsmanagement.</li>
          <li>&Ouml;ffentlichkeitsarbeit.</li>
          <li>Absatzf&ouml;rderung.</li>
          <li>Gesch&auml;ftsprozesse und betriebswirtschaftliche Verfahren.</li>
        </ul>
        <h2 id="m2427">Ma&szlig;gebliche Rechtsgrundlagen</h2>
        <p>
          <strong>Ma&szlig;gebliche Rechtsgrundlagen nach der DSGVO: </strong>Im
          Folgenden erhalten Sie eine &Uuml;bersicht der Rechtsgrundlagen der
          DSGVO, auf deren Basis wir personenbezogene Daten verarbeiten. Bitte
          nehmen Sie zur Kenntnis, dass neben den Regelungen der DSGVO nationale
          Datenschutzvorgaben in Ihrem bzw. unserem Wohn- oder Sitzland gelten
          k&ouml;nnen. Sollten ferner im Einzelfall speziellere Rechtsgrundlagen
          ma&szlig;geblich sein, teilen wir Ihnen diese in der
          Datenschutzerkl&auml;rung mit.
        </p>
        <ul>
          <li>
            <strong>Einwilligung (Art. 6 Abs. 1 S. 1 lit. a) DSGVO)</strong> -
            Die betroffene Person hat ihre Einwilligung in die Verarbeitung der
            sie betreffenden personenbezogenen Daten f&uuml;r einen spezifischen
            Zweck oder mehrere bestimmte Zwecke gegeben.
          </li>
          <li>
            <strong>
              Vertragserf&uuml;llung und vorvertragliche Anfragen (Art. 6 Abs. 1
              S. 1 lit. b) DSGVO)
            </strong>{' '}
            - Die Verarbeitung ist f&uuml;r die Erf&uuml;llung eines Vertrags,
            dessen Vertragspartei die betroffene Person ist, oder zur
            Durchf&uuml;hrung vorvertraglicher Ma&szlig;nahmen erforderlich, die
            auf Anfrage der betroffenen Person erfolgen.
          </li>
          <li>
            <strong>
              Rechtliche Verpflichtung (Art. 6 Abs. 1 S. 1 lit. c) DSGVO)
            </strong>{' '}
            - Die Verarbeitung ist zur Erf&uuml;llung einer rechtlichen
            Verpflichtung erforderlich, der der Verantwortliche unterliegt.
          </li>
          <li>
            <strong>
              Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO)
            </strong>{' '}
            - die Verarbeitung ist zur Wahrung der berechtigten Interessen des
            Verantwortlichen oder eines Dritten notwendig, vorausgesetzt, dass
            die Interessen, Grundrechte und Grundfreiheiten der betroffenen
            Person, die den Schutz personenbezogener Daten verlangen, nicht
            &uuml;berwiegen.
          </li>
        </ul>
        <p>
          <strong>Nationale Datenschutzregelungen in Deutschland: </strong>
          Zus&auml;tzlich zu den Datenschutzregelungen der DSGVO gelten
          nationale Regelungen zum Datenschutz in Deutschland. Hierzu
          geh&ouml;rt insbesondere das Gesetz zum Schutz vor Missbrauch
          personenbezogener Daten bei der Datenverarbeitung
          (Bundesdatenschutzgesetz – BDSG). Das BDSG enth&auml;lt insbesondere
          Spezialregelungen zum Recht auf Auskunft, zum Recht auf L&ouml;schung,
          zum Widerspruchsrecht, zur Verarbeitung besonderer Kategorien
          personenbezogener Daten, zur Verarbeitung f&uuml;r andere Zwecke und
          zur &Uuml;bermittlung sowie automatisierten Entscheidungsfindung im
          Einzelfall einschlie&szlig;lich Profiling. Ferner k&ouml;nnen
          Landesdatenschutzgesetze der einzelnen Bundesl&auml;nder zur Anwendung
          gelangen.
        </p>
        <p>
          <strong>Hinweis auf Geltung DSGVO und Schweizer DSG: </strong>Diese
          Datenschutzhinweise dienen sowohl der Informationserteilung nach dem
          Schweizer DSG als auch nach der Datenschutzgrundverordnung (DSGVO).
          Aus diesem Grund bitten wir Sie zu beachten, dass aufgrund der
          breiteren r&auml;umlichen Anwendung und Verst&auml;ndlichkeit die
          Begriffe der DSGVO verwendet werden. Insbesondere statt der im
          Schweizer DSG verwendeten Begriffe „Bearbeitung" von „Personendaten",
          "&uuml;berwiegendes Interesse" und "besonders sch&uuml;tzenswerte
          Personendaten" werden die in der DSGVO verwendeten Begriffe
          „Verarbeitung" von „personenbezogenen Daten" sowie "berechtigtes
          Interesse" und "besondere Kategorien von Daten" verwendet. Die
          gesetzliche Bedeutung der Begriffe wird jedoch im Rahmen der Geltung
          des Schweizer DSG weiterhin nach dem Schweizer DSG bestimmt.
        </p>
        <h2 id="m27">Sicherheitsma&szlig;nahmen</h2>
        <p>
          Wir treffen nach Ma&szlig;gabe der gesetzlichen Vorgaben unter
          Ber&uuml;cksichtigung des Stands der Technik, der
          Implementierungskosten und der Art, des Umfangs, der Umst&auml;nde und
          der Zwecke der Verarbeitung sowie der unterschiedlichen
          Eintrittswahrscheinlichkeiten und des Ausma&szlig;es der Bedrohung der
          Rechte und Freiheiten nat&uuml;rlicher Personen geeignete technische
          und organisatorische Ma&szlig;nahmen, um ein dem Risiko angemessenes
          Schutzniveau zu gew&auml;hrleisten.
        </p>
        <p>
          Zu den Ma&szlig;nahmen geh&ouml;ren insbesondere die Sicherung der
          Vertraulichkeit, Integrit&auml;t und Verf&uuml;gbarkeit von Daten
          durch Kontrolle des physischen und elektronischen Zugangs zu den Daten
          als auch des sie betreffenden Zugriffs, der Eingabe, der Weitergabe,
          der Sicherung der Verf&uuml;gbarkeit und ihrer Trennung. Des Weiteren
          haben wir Verfahren eingerichtet, die eine Wahrnehmung von
          Betroffenenrechten, die L&ouml;schung von Daten und Reaktionen auf die
          Gef&auml;hrdung der Daten gew&auml;hrleisten. Ferner
          ber&uuml;cksichtigen wir den Schutz personenbezogener Daten bereits
          bei der Entwicklung bzw. Auswahl von Hardware, Software sowie
          Verfahren entsprechend dem Prinzip des Datenschutzes, durch
          Technikgestaltung und durch datenschutzfreundliche Voreinstellungen.
        </p>
        <p>
          Sicherung von Online-Verbindungen durch
          TLS-/SSL-Verschl&uuml;sselungstechnologie (HTTPS): Um die Daten der
          Nutzer, die &uuml;ber unsere Online-Dienste &uuml;bertragen werden,
          vor unerlaubten Zugriffen zu sch&uuml;tzen, setzen wir auf die
          TLS-/SSL-Verschl&uuml;sselungstechnologie. Secure Sockets Layer (SSL)
          und Transport Layer Security (TLS) sind die Eckpfeiler der sicheren
          Daten&uuml;bertragung im Internet. Diese Technologien
          verschl&uuml;sseln die Informationen, die zwischen der Website oder
          App und dem Browser des Nutzers (oder zwischen zwei Servern)
          &uuml;bertragen werden, wodurch die Daten vor unbefugtem Zugriff
          gesch&uuml;tzt sind. TLS, als die weiterentwickelte und sicherere
          Version von SSL, gew&auml;hrleistet, dass alle Daten&uuml;bertragungen
          den h&ouml;chsten Sicherheitsstandards entsprechen. Wenn eine Website
          durch ein SSL-/TLS-Zertifikat gesichert ist, wird dies durch die
          Anzeige von HTTPS in der URL signalisiert. Dies dient als ein
          Indikator f&uuml;r die Nutzer, dass ihre Daten sicher und
          verschl&uuml;sselt &uuml;bertragen werden.
        </p>
        <h2 id="m25">&Uuml;bermittlung von personenbezogenen Daten</h2>
        <p>
          Im Rahmen unserer Verarbeitung von personenbezogenen Daten kommt es
          vor, dass diese an andere Stellen, Unternehmen, rechtlich
          selbstst&auml;ndige Organisationseinheiten oder Personen
          &uuml;bermittelt beziehungsweise ihnen gegen&uuml;ber offengelegt
          werden. Zu den Empf&auml;ngern dieser Daten k&ouml;nnen z. B. mit
          IT-Aufgaben beauftragte Dienstleister geh&ouml;ren oder Anbieter von
          Diensten und Inhalten, die in eine Website eingebunden sind. In
          solchen F&auml;llen beachten wir die gesetzlichen Vorgaben und
          schlie&szlig;en insbesondere entsprechende Vertr&auml;ge bzw.
          Vereinbarungen, die dem Schutz Ihrer Daten dienen, mit den
          Empf&auml;ngern Ihrer Daten ab.
        </p>
        <h2 id="m24">Internationale Datentransfers</h2>
        <p>
          Datenverarbeitung in Drittl&auml;ndern: Sofern wir Daten in ein
          Drittland (d. h. au&szlig;erhalb der Europ&auml;ischen Union (EU) oder
          des Europ&auml;ischen Wirtschaftsraums (EWR)) &uuml;bermitteln oder
          dies im Rahmen der Nutzung von Diensten Dritter oder der Offenlegung
          bzw. &Uuml;bermittlung von Daten an andere Personen, Stellen oder
          Unternehmen geschieht (was erkennbar wird anhand der Postadresse des
          jeweiligen Anbieters oder wenn in der Datenschutzerkl&auml;rung
          ausdr&uuml;cklich auf den Datentransfer in Drittl&auml;nder
          hingewiesen wird), erfolgt dies stets im Einklang mit den gesetzlichen
          Vorgaben.
        </p>
        <p>
          F&uuml;r Daten&uuml;bermittlungen in die USA st&uuml;tzen wir uns
          vorrangig auf das Data Privacy Framework (DPF), welches durch einen
          Angemessenheitsbeschluss der EU-Kommission vom 10.07.2023 als sicherer
          Rechtsrahmen anerkannt wurde. Zus&auml;tzlich haben wir mit den
          jeweiligen Anbietern Standardvertragsklauseln abgeschlossen, die den
          Vorgaben der EU-Kommission entsprechen und vertragliche
          Verpflichtungen zum Schutz Ihrer Daten festlegen.
        </p>
        <p>
          Diese zweifache Absicherung gew&auml;hrleistet einen umfassenden
          Schutz Ihrer Daten: Das DPF bildet die prim&auml;re Schutzebene,
          w&auml;hrend die Standardvertragsklauseln als zus&auml;tzliche
          Sicherheit dienen. Sollten sich &Auml;nderungen im Rahmen des DPF
          ergeben, greifen die Standardvertragsklauseln als zuverl&auml;ssige
          R&uuml;ckfalloption ein. So stellen wir sicher, dass Ihre Daten auch
          bei etwaigen politischen oder rechtlichen Ver&auml;nderungen stets
          angemessen gesch&uuml;tzt bleiben.
        </p>
        <p>
          Bei den einzelnen Diensteanbietern informieren wir Sie dar&uuml;ber,
          ob sie nach dem DPF zertifiziert sind und ob Standardvertragsklauseln
          vorliegen. Weitere Informationen zum DPF und eine Liste der
          zertifizierten Unternehmen finden Sie auf der Website des
          US-Handelsministeriums unter{' '}
          <a
            href="https://www.dataprivacyframework.gov/"
            target="_blank"
            rel="noreferrer"
          >
            https://www.dataprivacyframework.gov/
          </a>{' '}
          (in englischer Sprache).
        </p>
        <p>
          F&uuml;r Daten&uuml;bermittlungen in andere Drittl&auml;nder gelten
          entsprechende Sicherheitsma&szlig;nahmen, insbesondere
          Standardvertragsklauseln, ausdr&uuml;ckliche Einwilligungen oder
          gesetzlich erforderliche &Uuml;bermittlungen. Informationen zu
          Drittlandtransfers und geltenden Angemessenheitsbeschl&uuml;ssen
          k&ouml;nnen Sie dem Informationsangebot der EU-Kommission entnehmen:{' '}
          <a
            href="https://commission.europa.eu/law/law-topic/data-protection/international-dimension-data-protection_en?prefLang=de"
            target="_blank"
            rel="noreferrer"
          >
            https://commission.europa.eu/law/law-topic/data-protection/international-dimension-data-protection_en?prefLang=de.
          </a>
        </p>
        <h2 id="m12">
          Allgemeine Informationen zur Datenspeicherung und L&ouml;schung
        </h2>
        <p>
          Wir l&ouml;schen personenbezogene Daten, die wir verarbeiten,
          gem&auml;&szlig; den gesetzlichen Bestimmungen, sobald die
          zugrundeliegenden Einwilligungen widerrufen werden oder keine weiteren
          rechtlichen Grundlagen f&uuml;r die Verarbeitung bestehen. Dies
          betrifft F&auml;lle, in denen der urspr&uuml;ngliche
          Verarbeitungszweck entf&auml;llt oder die Daten nicht mehr
          ben&ouml;tigt werden. Ausnahmen von dieser Regelung bestehen, wenn
          gesetzliche Pflichten oder besondere Interessen eine l&auml;ngere
          Aufbewahrung oder Archivierung der Daten erfordern.
        </p>
        <p>
          Insbesondere m&uuml;ssen Daten, die aus handels- oder
          steuerrechtlichen Gr&uuml;nden aufbewahrt werden m&uuml;ssen oder
          deren Speicherung notwendig ist zur Rechtsverfolgung oder zum Schutz
          der Rechte anderer nat&uuml;rlicher oder juristischer Personen,
          entsprechend archiviert werden.
        </p>
        <p>
          Unsere Datenschutzhinweise enthalten zus&auml;tzliche Informationen
          zur Aufbewahrung und L&ouml;schung von Daten, die speziell f&uuml;r
          bestimmte Verarbeitungsprozesse gelten.
        </p>
        <p>
          Bei mehreren Angaben zur Aufbewahrungsdauer oder L&ouml;schungsfristen
          eines Datums, ist stets die l&auml;ngste Frist ma&szlig;geblich.
        </p>
        <p>
          Beginnt eine Frist nicht ausdr&uuml;cklich zu einem bestimmten Datum
          und betr&auml;gt sie mindestens ein Jahr, so startet sie automatisch
          am Ende des Kalenderjahres, in dem das fristausl&ouml;sende Ereignis
          eingetreten ist. Im Fall laufender Vertragsverh&auml;ltnisse, in deren
          Rahmen Daten gespeichert werden, ist das fristausl&ouml;sende Ereignis
          der Zeitpunkt des Wirksamwerdens der K&uuml;ndigung oder sonstige
          Beendigung des Rechtsverh&auml;ltnisses.
        </p>
        <p>
          Daten, die nicht mehr f&uuml;r den urspr&uuml;nglich vorgesehenen
          Zweck, sondern aufgrund gesetzlicher Vorgaben oder anderer Gr&uuml;nde
          aufbewahrt werden, verarbeiten wir ausschlie&szlig;lich zu den
          Gr&uuml;nden, die ihre Aufbewahrung rechtfertigen.
        </p>
        <p>
          <strong>
            Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:
          </strong>
        </p>
        <ul className="m-elements">
          <li>
            <strong>Aufbewahrung und L&ouml;schung von Daten: </strong>Die
            folgenden allgemeinen Fristen gelten f&uuml;r die Aufbewahrung und
            Archivierung nach deutschem Recht:
            <ul>
              {' '}
              <li>
                10 Jahre - Aufbewahrungsfrist f&uuml;r B&uuml;cher und
                Aufzeichnungen, Jahresabschl&uuml;sse, Inventare, Lageberichte,
                Er&ouml;ffnungsbilanz sowie die zu ihrem Verst&auml;ndnis
                erforderlichen Arbeitsanweisungen und sonstigen
                Organisationsunterlagen (§ 147 Abs. 1 Nr. 1 i.V.m. Abs. 3 AO, §
                14b Abs. 1 UStG, § 257 Abs. 1 Nr. 1 i.V.m. Abs. 4 HGB).
              </li>
              <li>
                8 Jahre - Buchungsbelege, wie z. B. Rechnungen und Kostenbelege
                (§ 147 Abs. 1 Nr. 4 und 4a i.V.m. Abs. 3 Satz 1 AO sowie § 257
                Abs. 1 Nr. 4 i.V.m. Abs. 4 HGB).
              </li>
              <li>
                6 Jahre - &Uuml;brige Gesch&auml;ftsunterlagen: empfangene
                Handels- oder Gesch&auml;ftsbriefe, Wiedergaben der abgesandten
                Handels- oder Gesch&auml;ftsbriefe, sonstige Unterlagen, soweit
                sie f&uuml;r die Besteuerung von Bedeutung sind, z. B.
                Stundenlohnzettel, Betriebsabrechnungsb&ouml;gen,
                Kalkulationsunterlagen, Preisauszeichnungen, aber auch
                Lohnabrechnungsunterlagen, soweit sie nicht bereits
                Buchungsbelege sind und Kassenstreifen (§ 147 Abs. 1 Nr. 2, 3, 5
                i.V.m. Abs. 3 AO, § 257 Abs. 1 Nr. 2 u. 3 i.V.m. Abs. 4 HGB).
              </li>
              <li>
                3 Jahre - Daten, die erforderlich sind, um potenzielle
                Gew&auml;hrleistungs- und Schadensersatzanspr&uuml;che oder
                &auml;hnliche vertragliche Anspr&uuml;che und Rechte zu
                ber&uuml;cksichtigen sowie damit verbundene Anfragen zu
                bearbeiten, basierend auf fr&uuml;heren
                Gesch&auml;ftserfahrungen und &uuml;blichen Branchenpraktiken,
                werden f&uuml;r die Dauer der regul&auml;ren gesetzlichen
                Verj&auml;hrungsfrist von drei Jahren gespeichert (§§ 195, 199
                BGB).
              </li>{' '}
            </ul>
          </li>
        </ul>
        <h2 id="m10">Rechte der betroffenen Personen</h2>
        <p>
          Rechte der betroffenen Personen aus der DSGVO: Ihnen stehen als
          Betroffene nach der DSGVO verschiedene Rechte zu, die sich
          insbesondere aus Art. 15 bis 21 DSGVO ergeben:
        </p>
        <ul>
          <li>
            <strong>
              Widerspruchsrecht: Sie haben das Recht, aus Gr&uuml;nden, die sich
              aus Ihrer besonderen Situation ergeben, jederzeit gegen die
              Verarbeitung der Sie betreffenden personenbezogenen Daten, die
              aufgrund von Art. 6 Abs. 1 lit. e oder f DSGVO erfolgt,
              Widerspruch einzulegen; dies gilt auch f&uuml;r ein auf diese
              Bestimmungen gest&uuml;tztes Profiling. Werden die Sie
              betreffenden personenbezogenen Daten verarbeitet, um Direktwerbung
              zu betreiben, haben Sie das Recht, jederzeit Widerspruch gegen die
              Verarbeitung der Sie betreffenden personenbezogenen Daten zum
              Zwecke derartiger Werbung einzulegen; dies gilt auch f&uuml;r das
              Profiling, soweit es mit solcher Direktwerbung in Verbindung
              steht.
            </strong>
          </li>
          <li>
            <strong>Widerrufsrecht bei Einwilligungen:</strong> Sie haben das
            Recht, erteilte Einwilligungen jederzeit zu widerrufen.
          </li>
          <li>
            <strong>Auskunftsrecht:</strong> Sie haben das Recht, eine
            Best&auml;tigung dar&uuml;ber zu verlangen, ob betreffende Daten
            verarbeitet werden und auf Auskunft &uuml;ber diese Daten sowie auf
            weitere Informationen und Kopie der Daten entsprechend den
            gesetzlichen Vorgaben.
          </li>
          <li>
            <strong>Recht auf Berichtigung:</strong> Sie haben entsprechend den
            gesetzlichen Vorgaben das Recht, die Vervollst&auml;ndigung der Sie
            betreffenden Daten oder die Berichtigung der Sie betreffenden
            unrichtigen Daten zu verlangen.
          </li>
          <li>
            <strong>
              Recht auf L&ouml;schung und Einschr&auml;nkung der Verarbeitung:
            </strong>{' '}
            Sie haben nach Ma&szlig;gabe der gesetzlichen Vorgaben das Recht, zu
            verlangen, dass Sie betreffende Daten unverz&uuml;glich
            gel&ouml;scht werden, bzw. alternativ nach Ma&szlig;gabe der
            gesetzlichen Vorgaben eine Einschr&auml;nkung der Verarbeitung der
            Daten zu verlangen.
          </li>
          <li>
            <strong>Recht auf Daten&uuml;bertragbarkeit:</strong> Sie haben das
            Recht, Sie betreffende Daten, die Sie uns bereitgestellt haben, nach
            Ma&szlig;gabe der gesetzlichen Vorgaben in einem strukturierten,
            g&auml;ngigen und maschinenlesbaren Format zu erhalten oder deren
            &Uuml;bermittlung an einen anderen Verantwortlichen zu fordern.
          </li>
          <li>
            <strong>Beschwerde bei Aufsichtsbeh&ouml;rde:</strong> Entsprechend
            den gesetzlichen Vorgaben und unbeschadet eines anderweitigen
            verwaltungsrechtlichen oder gerichtlichen Rechtsbehelfs, haben Sie
            ferner das Recht, bei einer Datenschutzaufsichtsbeh&ouml;rde,
            insbesondere einer Aufsichtsbeh&ouml;rde im Mitgliedstaat, in dem
            Sie sich gew&ouml;hnlich aufhalten, der Aufsichtsbeh&ouml;rde Ihres
            Arbeitsplatzes oder des Ortes des mutma&szlig;lichen
            Versto&szlig;es, eine Beschwerde einzulegen, wenn Sie der Ansicht
            sei sollten, dass die Verarbeitung der Ihre Person betreffenden
            personenbezogenen Daten gegen die DSGVO verst&ouml;&szlig;t.
          </li>
        </ul>
        <h2 id="m13">Gesch&auml;ftsprozesse und -verfahren</h2>
        <p>
          Personenbezogene Daten von Leistungsempf&auml;ngern und Auftraggebern
          – darunter Kunden, Klienten oder in speziellen F&auml;llen Mandanten,
          Patienten oder Gesch&auml;ftspartner sowie weitere Dritte – werden im
          Rahmen vertraglicher sowie vergleichbarer Rechtsverh&auml;ltnisse und
          vorvertraglicher Ma&szlig;nahmen wie der Anbahnung von
          Gesch&auml;ftsbeziehungen verarbeitet. Diese Datenverarbeitung
          unterst&uuml;tzt und erleichtert betriebswirtschaftliche Abl&auml;ufe
          in Bereichen wie Kundenmanagement, Vertrieb, Zahlungsverkehr,
          Buchhaltung und Projektmanagement.
        </p>
        <p>
          Die erfassten Daten dienen dazu, vertragliche Verpflichtungen zu
          erf&uuml;llen und betriebliche Prozesse effizient zu gestalten. Hierzu
          geh&ouml;rt die Abwicklung von Gesch&auml;ftstransaktionen, das
          Management von Kundenbeziehungen, die Optimierung von
          Vertriebsstrategien sowie die Gew&auml;hrleistung interner Rechnungs-
          und Finanzprozesse. Zus&auml;tzlich unterst&uuml;tzen die Daten die
          Wahrung der Rechte des Verantwortlichen und f&ouml;rdern
          Verwaltungsaufgaben sowie die Organisation des Unternehmens.
        </p>
        <p>
          Personenbezogene Daten k&ouml;nnen an Dritte weitergegeben werden,
          sofern dies zur Erf&uuml;llung der genannten Zwecke oder gesetzlicher
          Verpflichtungen notwendig ist. Nach Ablauf gesetzlicher
          Aufbewahrungsfristen oder wenn der Zweck der Verarbeitung
          entf&auml;llt, werden die Daten gel&ouml;scht. Dies umfasst auch
          Daten, die aufgrund von steuerrechtlichen und gesetzlichen
          Nachweispflichten l&auml;nger gespeichert werden m&uuml;ssen.
        </p>
        <ul className="m-elements">
          <li>
            <strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z. B. der
            vollst&auml;ndige Name, Wohnadresse, Kontaktinformationen,
            Kundennummer, etc.); Zahlungsdaten (z. B. Bankverbindungen,
            Rechnungen, Zahlungshistorie); Kontaktdaten (z. B. Post- und
            E-Mail-Adressen oder Telefonnummern); Inhaltsdaten (z. B. textliche
            oder bildliche Nachrichten und Beitr&auml;ge sowie die sie
            betreffenden Informationen, wie z. B. Angaben zur Autorenschaft oder
            Zeitpunkt der Erstellung); Vertragsdaten (z. B. Vertragsgegenstand,
            Laufzeit, Kundenkategorie); Nutzungsdaten (z. B. Seitenaufrufe und
            Verweildauer, Klickpfade, Nutzungsintensit&auml;t und -frequenz,
            verwendete Ger&auml;tetypen und Betriebssysteme, Interaktionen mit
            Inhalten und Funktionen); Meta-, Kommunikations- und Verfahrensdaten
            (z. B. IP-Adressen, Zeitangaben, Identifikationsnummern, beteiligte
            Personen). Protokolldaten (z. B. Logfiles betreffend Logins oder den
            Abruf von Daten oder Zugriffszeiten.).
          </li>
          <li>
            <strong>Betroffene Personen:</strong> Leistungsempf&auml;nger und
            Auftraggeber; Interessenten; Kommunikationspartner; Gesch&auml;fts-
            und Vertragspartner; Kunden; Dritte Personen; Nutzer (z. B.
            Webseitenbesucher, Nutzer von Onlinediensten). Besch&auml;ftigte
            (z. B. Angestellte, Bewerber, Aushilfskr&auml;fte und sonstige
            Mitarbeiter).
          </li>
          <li>
            <strong>Zwecke der Verarbeitung:</strong> Erbringung vertraglicher
            Leistungen und Erf&uuml;llung vertraglicher Pflichten; B&uuml;ro-
            und Organisationsverfahren; Gesch&auml;ftsprozesse und
            betriebswirtschaftliche Verfahren; Sicherheitsma&szlig;nahmen;
            Bereitstellung unseres Onlineangebotes und Nutzerfreundlichkeit;
            Kommunikation; Marketing; Absatzf&ouml;rderung;
            &Ouml;ffentlichkeitsarbeit; Finanz- und Zahlungsmanagement.
            Informationstechnische Infrastruktur (Betrieb und Bereitstellung von
            Informationssystemen und technischen Ger&auml;ten (Computer, Server
            etc.)).
          </li>
          <li>
            <strong>Aufbewahrung und L&ouml;schung:</strong> L&ouml;schung
            entsprechend Angaben im Abschnitt "Allgemeine Informationen zur
            Datenspeicherung und L&ouml;schung".
          </li>
          <li className="">
            <strong>Rechtsgrundlagen:</strong> Vertragserf&uuml;llung und
            vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO);
            Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO).
            Rechtliche Verpflichtung (Art. 6 Abs. 1 S. 1 lit. c) DSGVO).
          </li>
        </ul>
        <p>
          <strong>
            Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:
          </strong>
        </p>
        <ul className="m-elements">
          <li>
            <strong>
              Kundenmanagement und Customer-Relationship-Management (CRM):{' '}
            </strong>
            Verfahren, die im Rahmen des Kundenmanagements und
            Customer-Relationship-Managements (CRM) erforderlich sind (z. B.
            Kundenakquisition unter Einhaltung der Datenschutzvorgaben,
            Ma&szlig;nahmen zur F&ouml;rderung von Kundenbindung und
            -loyalit&auml;t, effektive Kundenkommunikation, Beschwerdemanagement
            und Kundenservice mit Ber&uuml;cksichtigung des Datenschutzes,
            Datenmanagement und -analyse zur Unterst&uuml;tzung der
            Kundenbeziehung, Verwaltung von CRM-Systemen, sichere
            Kontoverwaltung, Kundensegmentierung und Zielgruppenbildung);{' '}
            <span className="">
              <strong>Rechtsgrundlagen:</strong> Vertragserf&uuml;llung und
              vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO),
              Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO).
            </span>
          </li>
          <li>
            <strong>Kontaktverwaltung und Kontaktpflege: </strong>Verfahren, die
            im Rahmen der Organisation, Pflege und Sicherung von
            Kontaktinformationen erforderlich sind (z. B. die Einrichtung und
            Wartung einer zentralen Kontaktdatenbank, regelm&auml;&szlig;ige
            Aktualisierungen der Kontaktinformationen, &Uuml;berwachung der
            Datenintegrit&auml;t, Implementierung von
            Datenschutzma&szlig;nahmen, Sicherstellung der Zugriffskontrollen,
            Durchf&uuml;hrung von Backups und Wiederherstellungen der
            Kontaktdaten, Schulung von Mitarbeitern im effektiven Umgang mit
            Kontaktmanagement-Software, regelm&auml;&szlig;ige
            &Uuml;berpr&uuml;fung der Kommunikationshistorie und Anpassung der
            Kontaktstrategien);{' '}
            <span className="">
              <strong>Rechtsgrundlagen:</strong> Vertragserf&uuml;llung und
              vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO),
              Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO).
            </span>
          </li>
          <li>
            <strong>Kundenkonto: </strong>Kunden k&ouml;nnen innerhalb unseres
            Onlineangebotes ein Konto anlegen (z. B. Kunden- bzw. Nutzerkonto,
            kurz "Kundenkonto"). Falls die Registrierung eines Kundenkontos
            erforderlich ist, werden Kunden hierauf ebenso hingewiesen wie auf
            die f&uuml;r die Registrierung erforderlichen Angaben. Die
            Kundenkonten sind nicht &ouml;ffentlich und k&ouml;nnen von
            Suchmaschinen nicht indexiert werden. Im Rahmen der Registrierung
            sowie anschlie&szlig;ender Anmeldungen und Nutzungen des
            Kundenkontos speichern wir die IP-Adressen der Kunden nebst den
            Zugriffszeitpunkten, um die Registrierung nachweisen und etwaigem
            Missbrauch des Kundenkontos vorbeugen zu k&ouml;nnen. Wurde das
            Kundenkonto gek&uuml;ndigt, werden die Daten des Kundenkontos nach
            dem K&uuml;ndigungszeitpunkt gel&ouml;scht, sofern sie nicht
            f&uuml;r andere Zwecke als die Bereitstellung im Kundenkonto
            aufbewahrt werden oder aus rechtlichen Gr&uuml;nden aufbewahrt
            werden m&uuml;ssen (z. B. interne Speicherung von Kundendaten,
            Bestellvorg&auml;ngen oder Rechnungen). Es liegt in der
            Verantwortung der Kunden, ihre Daten bei K&uuml;ndigung des
            Kundenkontos zu sichern;{' '}
            <span className="">
              <strong>Rechtsgrundlagen:</strong> Vertragserf&uuml;llung und
              vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO),
              Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO).
            </span>
          </li>
          <li>
            <strong>Allgemeiner Zahlungsverkehr: </strong>Verfahren, die bei der
            Durchf&uuml;hrung von Zahlungsvorg&auml;ngen, der &Uuml;berwachung
            von Bankkonten und der Kontrolle von Zahlungsstr&ouml;men
            erforderlich sind (z. B. Erstellung und Pr&uuml;fung von
            &Uuml;berweisungen, Abwicklung des Lastschriftverkehrs, Kontrolle
            von Kontoausz&uuml;gen, &Uuml;berwachung von Zahlungseing&auml;ngen
            und -ausg&auml;ngen, R&uuml;cklastschriftmanagement,
            Kontenabstimmung, Cash-Management);{' '}
            <span className="">
              <strong>Rechtsgrundlagen:</strong> Vertragserf&uuml;llung und
              vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO),
              Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO).
            </span>
          </li>
          <li>
            <strong>
              Buchhaltung, Kreditorenbuchhaltung, Debitorenbuchhaltung:{' '}
            </strong>
            Verfahren, die bei der Erfassung, Bearbeitung und Kontrolle von
            Gesch&auml;ftsvorg&auml;ngen im Bereich der Kreditoren- und
            Debitorenbuchhaltung erforderlich sind (z. B. Erstellung und
            Pr&uuml;fung von eingehenden und ausgehenden Rechnungen,
            &Uuml;berwachung und Verwaltung von offenen Posten,
            Durchf&uuml;hrung des Zahlungsverkehrs, Abwicklung des Mahnwesens,
            Kontenabstimmung im Rahmen von Forderungen und Verbindlichkeiten,
            Kreditorenbuchhaltung und Debitorenbuchhaltung);{' '}
            <span className="">
              <strong>Rechtsgrundlagen:</strong> Vertragserf&uuml;llung und
              vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO),
              Rechtliche Verpflichtung (Art. 6 Abs. 1 S. 1 lit. c) DSGVO),
              Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO).
            </span>
          </li>
          <li>
            <strong>Finanzbuchhaltung und Steuern: </strong>Verfahren, die bei
            der Erfassung, Verwaltung und Kontrolle von finanzrelevanten
            Gesch&auml;ftsvorf&auml;llen sowie bei der Berechnung, Meldung und
            Zahlung von Steuern erforderlich sind (z. B. Kontierung und
            Verbuchung von Gesch&auml;ftsvorf&auml;llen, Erstellung von
            Quartals- und Jahresabschl&uuml;ssen, Durchf&uuml;hrung des
            Zahlungsverkehrs, Abwicklung des Mahnwesens, Kontenabstimmung,
            steuerliche Beratung, Erstellung und Einreichung von
            Steuererkl&auml;rungen, Abwicklung des Steuerwesens);{' '}
            <span className="">
              <strong>Rechtsgrundlagen:</strong> Vertragserf&uuml;llung und
              vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO),
              Rechtliche Verpflichtung (Art. 6 Abs. 1 S. 1 lit. c) DSGVO),
              Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO).
            </span>
          </li>
          <li>
            <strong>Marketing, Werbung und Absatzf&ouml;rderung: </strong>
            Verfahren, die im Rahmen von Marketing, Werbung und
            Absatzf&ouml;rderung erforderlich sind (z. B. Marktanalyse und
            Zielgruppenbestimmung, Entwicklung von Marketingstrategien, Planung
            und Durchf&uuml;hrung von Werbekampagnen, Gestaltung und Produktion
            von Werbematerialien, Online-Marketing einschlie&szlig;lich SEO und
            Social Media Kampagnen, Eventmarketing und Messebeteiligungen,
            Kundenbindungsprogramme, Verkaufsf&ouml;rderungsma&szlig;nahmen,
            Performance-Messung und Optimierung der Marketingaktivit&auml;ten,
            Budgetverwaltung und Kostenkontrolle);{' '}
            <span className="">
              <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6
              Abs. 1 S. 1 lit. f) DSGVO).
            </span>
          </li>
          <li>
            <strong>&Ouml;ffentlichkeitsarbeit: </strong>Verfahren, die im
            Rahmen der &Ouml;ffentlichkeitsarbeit und Public Relations
            erforderlich sind (z. B. Entwicklung und Umsetzung von
            Kommunikationsstrategien, Planung und Durchf&uuml;hrung von
            PR-Kampagnen, Erstellung und Verbreitung von Pressemitteilungen,
            Pflege von Medienkontakten, Monitoring und Analyse der
            Medienresonanz, Organisation von Pressekonferenzen und
            &ouml;ffentlichen Veranstaltungen, Krisenkommunikation, Erstellung
            von Content f&uuml;r soziale Medien und Unternehmenswebseiten,
            Betreuung des Corporate Branding);{' '}
            <span className="">
              <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6
              Abs. 1 S. 1 lit. f) DSGVO).
            </span>
          </li>
          <li>
            <strong>Discord: </strong>Chat, Audio- und Video&uuml;bertragungen,
            Instant Messaging und Community-Management;{' '}
            <strong>Dienstanbieter:</strong> Discord, Inc., 444 De Haro St,
            Suite 200, San Francisco, California 94107, USA;{' '}
            <span className="">
              <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6
              Abs. 1 S. 1 lit. f) DSGVO);{' '}
            </span>
            <strong>Website:</strong>{' '}
            <a href="https://discord.com/" target="_blank" rel="noreferrer">
              https://discord.com/
            </a>
            ; <strong>Datenschutzerkl&auml;rung:</strong>{' '}
            <a
              href="https://discord.com/privacy"
              target="_blank"
              rel="noreferrer"
            >
              https://discord.com/privacy
            </a>
            . <strong>Grundlage Drittlandtransfers:</strong> Data Privacy
            Framework (DPF), Data Privacy Framework (DPF).
          </li>
        </ul>
        <h2 id="m3575">
          Im Rahmen der Gesch&auml;ftst&auml;tigkeit eingesetzte Anbieter und
          Services
        </h2>
        <p>
          Im Rahmen unserer Gesch&auml;ftst&auml;tigkeit nutzen wir unter
          Beachtung der gesetzlichen Vorgaben zus&auml;tzliche Dienste,
          Plattformen, Schnittstellen oder Plug-ins von Drittanbietern (kurz
          "Dienste"). Deren Nutzung beruht auf unseren Interessen an einer
          ordnungsgem&auml;&szlig;en, rechtm&auml;&szlig;igen und
          wirtschaftlichen F&uuml;hrung unseres Gesch&auml;ftsbetriebs und
          unserer internen Organisation.
        </p>
        <ul className="m-elements">
          <li>
            <strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z. B. der
            vollst&auml;ndige Name, Wohnadresse, Kontaktinformationen,
            Kundennummer, etc.); Zahlungsdaten (z. B. Bankverbindungen,
            Rechnungen, Zahlungshistorie); Kontaktdaten (z. B. Post- und
            E-Mail-Adressen oder Telefonnummern); Inhaltsdaten (z. B. textliche
            oder bildliche Nachrichten und Beitr&auml;ge sowie die sie
            betreffenden Informationen, wie z. B. Angaben zur Autorenschaft oder
            Zeitpunkt der Erstellung). Vertragsdaten (z. B. Vertragsgegenstand,
            Laufzeit, Kundenkategorie).
          </li>
          <li>
            <strong>Betroffene Personen:</strong> Leistungsempf&auml;nger und
            Auftraggeber; Interessenten; Gesch&auml;fts- und Vertragspartner.
            Mitglieder.
          </li>
          <li>
            <strong>Zwecke der Verarbeitung:</strong> Erbringung vertraglicher
            Leistungen und Erf&uuml;llung vertraglicher Pflichten; B&uuml;ro-
            und Organisationsverfahren. Gesch&auml;ftsprozesse und
            betriebswirtschaftliche Verfahren.
          </li>
          <li>
            <strong>Aufbewahrung und L&ouml;schung:</strong> L&ouml;schung
            entsprechend Angaben im Abschnitt "Allgemeine Informationen zur
            Datenspeicherung und L&ouml;schung".
          </li>
          <li className="">
            <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6
            Abs. 1 S. 1 lit. f) DSGVO).
          </li>
        </ul>
        <p>
          <strong>
            Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:
          </strong>
        </p>
        <ul className="m-elements">
          <li>
            <strong>Lexware: </strong>Software f&uuml;r Rechnungsstellung,
            Buchhaltung, Banking und Steuereinreichung mit Belegspeicherung;{' '}
            <strong>Dienstanbieter:</strong> Haufe Service Center GmbH,
            Munzinger Stra&szlig;e 9, 79111 Freiburg, Deutschland;{' '}
            <span className="">
              <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6
              Abs. 1 S. 1 lit. f) DSGVO);{' '}
            </span>
            <strong>Website:</strong>{' '}
            <a
              href="https://www.lexoffice.de/datenschutz/"
              target="_blank"
              rel="noreferrer"
            >
              https://www.lexoffice.de/datenschutz/
            </a>
            . <strong>Datenschutzerkl&auml;rung:</strong>{' '}
            <a
              href="https://datenschutz.lexware.de/"
              target="_blank"
              rel="noreferrer"
            >
              https://datenschutz.lexware.de/
            </a>
            .
          </li>
        </ul>
        <h2 id="m326">Zahlungsverfahren</h2>
        <p>
          Im Rahmen von Vertrags- und sonstigen Rechtsbeziehungen, aufgrund
          gesetzlicher Pflichten oder sonst auf Grundlage unserer berechtigten
          Interessen bieten wir den betroffenen Personen effiziente und sichere
          Zahlungsm&ouml;glichkeiten an und setzen hierzu neben Banken und
          Kreditinstituten weitere Dienstleister ein (zusammenfassend
          "Zahlungsdienstleister").
        </p>
        <p>
          Zu den durch die Zahlungsdienstleister verarbeiteten Daten
          geh&ouml;ren Bestandsdaten, wie z. B. der Name und die Adresse,
          Bankdaten, wie z. B. Kontonummern oder Kreditkartennummern,
          Passw&ouml;rter, TANs und Pr&uuml;fsummen sowie die Vertrags-, Summen-
          und empf&auml;ngerbezogenen Angaben. Die Angaben sind erforderlich, um
          die Transaktionen durchzuf&uuml;hren. Die eingegebenen Daten werden
          jedoch nur durch die Zahlungsdienstleister verarbeitet und bei diesen
          gespeichert. D. h., wir erhalten keine konto- oder
          kreditkartenbezogenen Informationen, sondern lediglich Informationen
          mit Best&auml;tigung oder Negativbeauskunftung der Zahlung. Unter
          Umst&auml;nden werden die Daten seitens der Zahlungsdienstleister an
          Wirtschaftsauskunfteien &uuml;bermittelt. Diese &Uuml;bermittlung
          bezweckt die Identit&auml;ts- und Bonit&auml;tspr&uuml;fung. Hierzu
          verweisen wir auf die AGB und die Datenschutzhinweise der
          Zahlungsdienstleister.
        </p>
        <p>
          F&uuml;r die Zahlungsgesch&auml;fte gelten die
          Gesch&auml;ftsbedingungen und die Datenschutzhinweise der jeweiligen
          Zahlungsdienstleister, welche innerhalb der jeweiligen Webseiten bzw.
          Transaktionsapplikationen abrufbar sind. Wir verweisen auf diese
          ebenfalls zwecks weiterer Informationen und Geltendmachung von
          Widerrufs-, Auskunfts- und anderen Betroffenenrechten.
        </p>
        <ul className="m-elements">
          <li>
            <strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z. B. der
            vollst&auml;ndige Name, Wohnadresse, Kontaktinformationen,
            Kundennummer, etc.); Zahlungsdaten (z. B. Bankverbindungen,
            Rechnungen, Zahlungshistorie); Vertragsdaten (z. B.
            Vertragsgegenstand, Laufzeit, Kundenkategorie); Nutzungsdaten (z. B.
            Seitenaufrufe und Verweildauer, Klickpfade, Nutzungsintensit&auml;t
            und -frequenz, verwendete Ger&auml;tetypen und Betriebssysteme,
            Interaktionen mit Inhalten und Funktionen). Meta-, Kommunikations-
            und Verfahrensdaten (z. B. IP-Adressen, Zeitangaben,
            Identifikationsnummern, beteiligte Personen).
          </li>
          <li>
            <strong>Betroffene Personen:</strong> Leistungsempf&auml;nger und
            Auftraggeber; Gesch&auml;fts- und Vertragspartner. Interessenten.
          </li>
          <li>
            <strong>Zwecke der Verarbeitung:</strong> Erbringung vertraglicher
            Leistungen und Erf&uuml;llung vertraglicher Pflichten.
            Gesch&auml;ftsprozesse und betriebswirtschaftliche Verfahren.
          </li>
          <li>
            <strong>Aufbewahrung und L&ouml;schung:</strong> L&ouml;schung
            entsprechend Angaben im Abschnitt "Allgemeine Informationen zur
            Datenspeicherung und L&ouml;schung".
          </li>
          <li className="">
            <strong>Rechtsgrundlagen:</strong> Vertragserf&uuml;llung und
            vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO).
            Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO).
          </li>
        </ul>
        <p>
          <strong>
            Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:
          </strong>
        </p>
        <ul className="m-elements">
          <li>
            <strong>Stripe: </strong>Zahlungsdienstleistungen (technische
            Anbindung von Online-Bezahlmethoden);{' '}
            <strong>Dienstanbieter:</strong> Stripe, Inc., 510 Townsend Street,
            San Francisco, CA 94103, USA;{' '}
            <span className="">
              <strong>Rechtsgrundlagen:</strong> Vertragserf&uuml;llung und
              vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO);{' '}
            </span>
            <strong>Website:</strong>{' '}
            <a href="https://stripe.com" target="_blank" rel="noreferrer">
              https://stripe.com
            </a>
            ; <strong>Datenschutzerkl&auml;rung:</strong>{' '}
            <a
              href="https://stripe.com/de/privacy"
              target="_blank"
              rel="noreferrer"
            >
              https://stripe.com/de/privacy
            </a>
            . <strong>Grundlage Drittlandtransfers:</strong> Data Privacy
            Framework (DPF), Data Privacy Framework (DPF).
          </li>
        </ul>
        <h2 id="m225">Bereitstellung des Onlineangebots und Webhosting</h2>
        <p>
          Wir verarbeiten die Daten der Nutzer, um ihnen unsere Online-Dienste
          zur Verf&uuml;gung stellen zu k&ouml;nnen. Zu diesem Zweck verarbeiten
          wir die IP-Adresse des Nutzers, die notwendig ist, um die Inhalte und
          Funktionen unserer Online-Dienste an den Browser oder das
          Endger&auml;t der Nutzer zu &uuml;bermitteln.
        </p>
        <ul className="m-elements">
          <li>
            <strong>Verarbeitete Datenarten:</strong> Nutzungsdaten (z. B.
            Seitenaufrufe und Verweildauer, Klickpfade, Nutzungsintensit&auml;t
            und -frequenz, verwendete Ger&auml;tetypen und Betriebssysteme,
            Interaktionen mit Inhalten und Funktionen); Meta-, Kommunikations-
            und Verfahrensdaten (z. B. IP-Adressen, Zeitangaben,
            Identifikationsnummern, beteiligte Personen); Protokolldaten (z. B.
            Logfiles betreffend Logins oder den Abruf von Daten oder
            Zugriffszeiten.). Inhaltsdaten (z. B. textliche oder bildliche
            Nachrichten und Beitr&auml;ge sowie die sie betreffenden
            Informationen, wie z. B. Angaben zur Autorenschaft oder Zeitpunkt
            der Erstellung).
          </li>
          <li>
            <strong>Betroffene Personen:</strong> Nutzer (z. B.
            Webseitenbesucher, Nutzer von Onlinediensten). Gesch&auml;fts- und
            Vertragspartner.
          </li>
          <li>
            <strong>Zwecke der Verarbeitung:</strong> Bereitstellung unseres
            Onlineangebotes und Nutzerfreundlichkeit; Informationstechnische
            Infrastruktur (Betrieb und Bereitstellung von Informationssystemen
            und technischen Ger&auml;ten (Computer, Server etc.)).
            Sicherheitsma&szlig;nahmen.
          </li>
          <li>
            <strong>Aufbewahrung und L&ouml;schung:</strong> L&ouml;schung
            entsprechend Angaben im Abschnitt "Allgemeine Informationen zur
            Datenspeicherung und L&ouml;schung".
          </li>
          <li className="">
            <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6
            Abs. 1 S. 1 lit. f) DSGVO).
          </li>
        </ul>
        <p>
          <strong>
            Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:
          </strong>
        </p>
        <ul className="m-elements">
          <li>
            <strong>
              Bereitstellung Onlineangebot auf gemietetem Speicherplatz:{' '}
            </strong>
            F&uuml;r die Bereitstellung unseres Onlineangebotes nutzen wir
            Speicherplatz, Rechenkapazit&auml;t und Software, die wir von einem
            entsprechenden Serveranbieter (auch "Webhoster" genannt) mieten oder
            anderweitig beziehen;{' '}
            <span className="">
              <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6
              Abs. 1 S. 1 lit. f) DSGVO).
            </span>
          </li>
          <li>
            <strong>Erhebung von Zugriffsdaten und Logfiles: </strong>Der
            Zugriff auf unser Onlineangebot wird in Form von sogenannten
            "Server-Logfiles" protokolliert. Zu den Serverlogfiles k&ouml;nnen
            die Adresse und der Name der abgerufenen Webseiten und Dateien,
            Datum und Uhrzeit des Abrufs, &uuml;bertragene Datenmengen, Meldung
            &uuml;ber erfolgreichen Abruf, Browsertyp nebst Version, das
            Betriebssystem des Nutzers, Referrer URL (die zuvor besuchte Seite)
            und im Regelfall IP-Adressen und der anfragende Provider
            geh&ouml;ren. Die Serverlogfiles k&ouml;nnen zum einen zu
            Sicherheitszwecken eingesetzt werden, z. B. um eine &Uuml;berlastung
            der Server zu vermeiden (insbesondere im Fall von
            missbr&auml;uchlichen Angriffen, sogenannten DDoS-Attacken), und zum
            anderen, um die Auslastung der Server und ihre Stabilit&auml;t
            sicherzustellen;{' '}
            <span className="">
              <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6
              Abs. 1 S. 1 lit. f) DSGVO).{' '}
            </span>
            <strong>L&ouml;schung von Daten:</strong> Logfile-Informationen
            werden f&uuml;r die Dauer von maximal 30 Tagen gespeichert und
            danach gel&ouml;scht oder anonymisiert. Daten, deren weitere
            Aufbewahrung zu Beweiszwecken erforderlich ist, sind bis zur
            endg&uuml;ltigen Kl&auml;rung des jeweiligen Vorfalls von der
            L&ouml;schung ausgenommen.
          </li>
          <li>
            <strong>Content-Delivery-Network: </strong>Wir setzen ein
            "Content-Delivery-Network" (CDN) ein. Ein CDN ist ein Dienst, mit
            dessen Hilfe Inhalte eines Onlineangebotes, insbesondere gro&szlig;e
            Mediendateien, wie Grafiken oder Programm-Skripte, mit Hilfe
            regional verteilter und &uuml;ber das Internet verbundener Server
            schneller und sicherer ausgeliefert werden k&ouml;nnen;{' '}
            <span className="">
              <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6
              Abs. 1 S. 1 lit. f) DSGVO).
            </span>
          </li>
          <li>
            <strong>Amazon Web Services (AWS): </strong>Leistungen auf dem
            Gebiet der Bereitstellung von informationstechnischer Infrastruktur
            und verbundenen Dienstleistungen (z. B. Speicherplatz und/oder
            Rechenkapazit&auml;ten); <strong>Dienstanbieter:</strong> Amazon Web
            Services EMEA SARL, 38 avenue John F. Kennedy, L-1855, Luxemburg;{' '}
            <span className="">
              <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6
              Abs. 1 S. 1 lit. f) DSGVO);{' '}
            </span>
            <strong>Website:</strong>{' '}
            <a
              href="https://aws.amazon.com/de/"
              target="_blank"
              rel="noreferrer"
            >
              https://aws.amazon.com/de/
            </a>
            ; <strong>Datenschutzerkl&auml;rung:</strong>{' '}
            <a
              href="https://aws.amazon.com/de/privacy/"
              target="_blank"
              rel="noreferrer"
            >
              https://aws.amazon.com/de/privacy/
            </a>
            ; <strong>Auftragsverarbeitungsvertrag:</strong>{' '}
            <a
              href="https://aws.amazon.com/de/compliance/gdpr-center/"
              target="_blank"
              rel="noreferrer"
            >
              https://aws.amazon.com/de/compliance/gdpr-center/
            </a>
            . <strong>Grundlage Drittlandtransfers:</strong> Data Privacy
            Framework (DPF), Standardvertragsklauseln (
            <a
              href="https://aws.amazon.com/service-terms/"
              target="_blank"
              rel="noreferrer"
            >
              https://aws.amazon.com/service-terms/
            </a>
            ), Data Privacy Framework (DPF)Standardvertragsklauseln (
            <a
              href="https://aws.amazon.com/service-terms/"
              target="_blank"
              rel="noreferrer"
            >
              https://aws.amazon.com/service-terms/
            </a>
            ).
          </li>
          <li>
            <strong>STRATO: </strong>Leistungen auf dem Gebiet der
            Bereitstellung von informationstechnischer Infrastruktur und
            verbundenen Dienstleistungen (z. B. Speicherplatz und/oder
            Rechenkapazit&auml;ten); <strong>Dienstanbieter:</strong> STRATO AG,
            Pascalstra&szlig;e 10,10587 Berlin, Deutschland;{' '}
            <span className="">
              <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6
              Abs. 1 S. 1 lit. f) DSGVO);{' '}
            </span>
            <strong>Website:</strong>{' '}
            <a href="https://www.strato.de" target="_blank" rel="noreferrer">
              https://www.strato.de
            </a>
            ; <strong>Datenschutzerkl&auml;rung:</strong>{' '}
            <a
              href="https://www.strato.de/datenschutz/"
              target="_blank"
              rel="noreferrer"
            >
              https://www.strato.de/datenschutz/
            </a>
            . <strong>Auftragsverarbeitungsvertrag:</strong> Wird vom
            Dienstanbieter bereitgestellt.
          </li>
          <li>
            <strong>Amazon CloudFront: </strong>Content-Delivery-Network (CDN) -
            Dienst, mit dessen Hilfe Inhalte eines Onlineangebotes, insbesondere
            gro&szlig;e Mediendateien, wie Grafiken oder Programm-Skripte mit
            Hilfe regional verteilter und &uuml;ber das Internet verbundener
            Server, schneller und sicherer ausgeliefert werden k&ouml;nnen;{' '}
            <strong>Dienstanbieter:</strong> Amazon Web Services EMEA SARL, 38
            avenue John F. Kennedy, L-1855, Luxemburg;{' '}
            <span className="">
              <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6
              Abs. 1 S. 1 lit. f) DSGVO);{' '}
            </span>
            <strong>Website:</strong>{' '}
            <a
              href="https://aws.amazon.com/de/cloudfront/"
              target="_blank"
              rel="noreferrer"
            >
              https://aws.amazon.com/de/cloudfront/
            </a>
            ; <strong>Datenschutzerkl&auml;rung:</strong>{' '}
            <a
              href="https://aws.amazon.com/privacy/"
              target="_blank"
              rel="noreferrer"
            >
              https://aws.amazon.com/privacy/
            </a>
            ; <strong>Auftragsverarbeitungsvertrag:</strong>{' '}
            <a
              href="https://aws.amazon.com/de/compliance/gdpr-center/"
              target="_blank"
              rel="noreferrer"
            >
              https://aws.amazon.com/de/compliance/gdpr-center/
            </a>
            . <strong>Grundlage Drittlandtransfers:</strong>{' '}
            Standardvertragsklauseln (Werden vom Dienstanbieter bereitgestellt),
            Standardvertragsklauseln (Werden vom Dienstanbieter bereitgestellt).
          </li>
        </ul>
        <h2 id="m367">Registrierung, Anmeldung und Nutzerkonto</h2>
        <p>
          Nutzer k&ouml;nnen ein Nutzerkonto anlegen. Im Rahmen der
          Registrierung werden den Nutzern die erforderlichen Pflichtangaben
          mitgeteilt und zu Zwecken der Bereitstellung des Nutzerkontos auf
          Grundlage vertraglicher Pflichterf&uuml;llung verarbeitet. Zu den
          verarbeiteten Daten geh&ouml;ren insbesondere die Login-Informationen
          (Nutzername, Passwort sowie eine E-Mail-Adresse).
        </p>
        <p>
          Im Rahmen der Inanspruchnahme unserer Registrierungs- und
          Anmeldefunktionen sowie der Nutzung des Nutzerkontos speichern wir die
          IP-Adresse und den Zeitpunkt der jeweiligen Nutzerhandlung. Die
          Speicherung erfolgt auf Grundlage unserer berechtigten Interessen als
          auch jener der Nutzer an einem Schutz vor Missbrauch und sonstiger
          unbefugter Nutzung. Eine Weitergabe dieser Daten an Dritte erfolgt
          grunds&auml;tzlich nicht, es sei denn, sie ist zur Verfolgung unserer
          Anspr&uuml;che erforderlich oder es besteht eine gesetzliche
          Verpflichtung hierzu.
        </p>
        <p>
          Die Nutzer k&ouml;nnen &uuml;ber Vorg&auml;nge, die f&uuml;r deren
          Nutzerkonto relevant sind, wie z. B. technische &Auml;nderungen, per
          E-Mail informiert werden.
        </p>
        <ul className="m-elements">
          <li>
            <strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z. B. der
            vollst&auml;ndige Name, Wohnadresse, Kontaktinformationen,
            Kundennummer, etc.); Kontaktdaten (z. B. Post- und E-Mail-Adressen
            oder Telefonnummern); Inhaltsdaten (z. B. textliche oder bildliche
            Nachrichten und Beitr&auml;ge sowie die sie betreffenden
            Informationen, wie z. B. Angaben zur Autorenschaft oder Zeitpunkt
            der Erstellung); Nutzungsdaten (z. B. Seitenaufrufe und
            Verweildauer, Klickpfade, Nutzungsintensit&auml;t und -frequenz,
            verwendete Ger&auml;tetypen und Betriebssysteme, Interaktionen mit
            Inhalten und Funktionen). Protokolldaten (z. B. Logfiles betreffend
            Logins oder den Abruf von Daten oder Zugriffszeiten.).
          </li>
          <li>
            <strong>Betroffene Personen:</strong> Nutzer (z. B.
            Webseitenbesucher, Nutzer von Onlinediensten).
          </li>
          <li>
            <strong>Zwecke der Verarbeitung:</strong> Erbringung vertraglicher
            Leistungen und Erf&uuml;llung vertraglicher Pflichten;
            Sicherheitsma&szlig;nahmen; Organisations- und Verwaltungsverfahren.
            Bereitstellung unseres Onlineangebotes und Nutzerfreundlichkeit.
          </li>
          <li>
            <strong>Aufbewahrung und L&ouml;schung:</strong> L&ouml;schung
            entsprechend Angaben im Abschnitt "Allgemeine Informationen zur
            Datenspeicherung und L&ouml;schung". L&ouml;schung nach
            K&uuml;ndigung.
          </li>
          <li className="">
            <strong>Rechtsgrundlagen:</strong> Vertragserf&uuml;llung und
            vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO).
            Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO).
          </li>
        </ul>
        <p>
          <strong>
            Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:
          </strong>
        </p>
        <ul className="m-elements">
          <li>
            <strong>Registrierung mit Klarnamen: </strong>Aufgrund der Natur
            unserer Community bitten wir die Nutzer unser Angebot nur unter
            Verwendung von Klarnamen zu nutzen. D. h. die Nutzung von
            Pseudonymen ist nicht zul&auml;ssig;{' '}
            <span className="">
              <strong>Rechtsgrundlagen:</strong> Vertragserf&uuml;llung und
              vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO).
            </span>
          </li>
          <li>
            <strong>Profile der Nutzer sind nicht &ouml;ffentlich: </strong>Die
            Profile der Nutzer sind &ouml;ffentlich nicht sichtbar und nicht
            zug&auml;nglich.
          </li>
          <li>
            <strong>L&ouml;schung von Daten nach K&uuml;ndigung: </strong>Wenn
            Nutzer ihr Nutzerkonto gek&uuml;ndigt haben, werden deren Daten im
            Hinblick auf das Nutzerkonto, vorbehaltlich einer gesetzlichen
            Erlaubnis, Pflicht oder Einwilligung der Nutzer, gel&ouml;scht;{' '}
            <span className="">
              <strong>Rechtsgrundlagen:</strong> Vertragserf&uuml;llung und
              vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO).
            </span>
          </li>
          <li>
            <strong>Keine Aufbewahrungspflicht f&uuml;r Daten: </strong>Es
            obliegt den Nutzern, ihre Daten bei erfolgter K&uuml;ndigung vor dem
            Vertragsende zu sichern. Wir sind berechtigt, s&auml;mtliche
            w&auml;hrend der Vertragsdauer gespeicherte Daten des Nutzers
            unwiederbringlich zu l&ouml;schen;{' '}
            <span className="">
              <strong>Rechtsgrundlagen:</strong> Vertragserf&uuml;llung und
              vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO).
            </span>
          </li>
        </ul>
        <h2 id="m182">Kontakt- und Anfrageverwaltung</h2>
        <p>
          Bei der Kontaktaufnahme mit uns (z. B. per Post, Kontaktformular,
          E-Mail, Telefon oder via soziale Medien) sowie im Rahmen bestehender
          Nutzer- und Gesch&auml;ftsbeziehungen werden die Angaben der
          anfragenden Personen verarbeitet, soweit dies zur Beantwortung der
          Kontaktanfragen und etwaiger angefragter Ma&szlig;nahmen erforderlich
          ist.
        </p>
        <ul className="m-elements">
          <li>
            <strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z. B. der
            vollst&auml;ndige Name, Wohnadresse, Kontaktinformationen,
            Kundennummer, etc.); Kontaktdaten (z. B. Post- und E-Mail-Adressen
            oder Telefonnummern); Inhaltsdaten (z. B. textliche oder bildliche
            Nachrichten und Beitr&auml;ge sowie die sie betreffenden
            Informationen, wie z. B. Angaben zur Autorenschaft oder Zeitpunkt
            der Erstellung); Nutzungsdaten (z. B. Seitenaufrufe und
            Verweildauer, Klickpfade, Nutzungsintensit&auml;t und -frequenz,
            verwendete Ger&auml;tetypen und Betriebssysteme, Interaktionen mit
            Inhalten und Funktionen). Meta-, Kommunikations- und Verfahrensdaten
            (z. B. IP-Adressen, Zeitangaben, Identifikationsnummern, beteiligte
            Personen).
          </li>
          <li>
            <strong>Betroffene Personen:</strong> Kommunikationspartner.
          </li>
          <li>
            <strong>Zwecke der Verarbeitung:</strong> Kommunikation;
            Organisations- und Verwaltungsverfahren; Feedback (z. B. Sammeln von
            Feedback via Online-Formular). Bereitstellung unseres
            Onlineangebotes und Nutzerfreundlichkeit.
          </li>
          <li>
            <strong>Aufbewahrung und L&ouml;schung:</strong> L&ouml;schung
            entsprechend Angaben im Abschnitt "Allgemeine Informationen zur
            Datenspeicherung und L&ouml;schung".
          </li>
          <li className="">
            <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6
            Abs. 1 S. 1 lit. f) DSGVO). Vertragserf&uuml;llung und
            vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO).
          </li>
        </ul>
        <p>
          <strong>
            Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:
          </strong>
        </p>
        <ul className="m-elements">
          <li>
            <strong>Kontaktformular: </strong>Bei Kontaktaufnahme &uuml;ber
            unser Kontaktformular, per E-Mail oder anderen Kommunikationswegen,
            verarbeiten wir die uns &uuml;bermittelten personenbezogenen Daten
            zur Beantwortung und Bearbeitung des jeweiligen Anliegens. Dies
            umfasst in der Regel Angaben wie Name, Kontaktinformationen und
            gegebenenfalls weitere Informationen, die uns mitgeteilt werden und
            zur angemessenen Bearbeitung erforderlich sind. Wir nutzen diese
            Daten ausschlie&szlig;lich f&uuml;r den angegebenen Zweck der
            Kontaktaufnahme und Kommunikation;{' '}
            <span className="">
              <strong>Rechtsgrundlagen:</strong> Vertragserf&uuml;llung und
              vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO),
              Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO).
            </span>
          </li>
        </ul>
        <h2 id="m391">Kommunikation via Messenger</h2>
        <p>
          Wir setzen zu Zwecken der Kommunikation Messenger ein und bitten daher
          darum, die nachfolgenden Hinweise zur Funktionsf&auml;higkeit der
          Messenger, zur Verschl&uuml;sselung, zur Nutzung der Metadaten der
          Kommunikation und zu Ihren Widerspruchsm&ouml;glichkeiten zu beachten.
        </p>
        <p>
          Sie k&ouml;nnen uns auch auf alternativen Wegen, z. B. via Telefon
          oder E-Mail, kontaktieren. Bitte nutzen Sie die Ihnen mitgeteilten
          Kontaktm&ouml;glichkeiten oder die innerhalb unseres Onlineangebotes
          angegebenen Kontaktm&ouml;glichkeiten.
        </p>
        <p>
          Im Fall einer Ende-zu-Ende-Verschl&uuml;sselung von Inhalten (d. h.,
          der Inhalt Ihrer Nachricht und Anh&auml;nge) weisen wir darauf hin,
          dass die Kommunikationsinhalte (d. h., der Inhalt der Nachricht und
          angeh&auml;ngte Bilder) von Ende zu Ende verschl&uuml;sselt werden.
          Das bedeutet, dass der Inhalt der Nachrichten nicht einsehbar ist,
          nicht einmal durch die Messenger-Anbieter selbst. Sie sollten immer
          eine aktuelle Version der Messenger mit aktivierter
          Verschl&uuml;sselung nutzen, damit die Verschl&uuml;sselung der
          Nachrichteninhalte sichergestellt ist.{' '}
        </p>
        <p>
          Wir weisen unsere Kommunikationspartner jedoch zus&auml;tzlich darauf
          hin, dass die Anbieter der Messenger zwar nicht den Inhalt einsehen,
          aber in Erfahrung bringen k&ouml;nnen, dass und wann
          Kommunikationspartner mit uns kommunizieren sowie technische
          Informationen zum verwendeten Ger&auml;t der Kommunikationspartner und
          je nach Einstellungen ihres Ger&auml;tes auch Standortinformationen
          (sogenannte Metadaten) verarbeitet werden.
        </p>
        <p>
          <strong>Hinweise zu Rechtsgrundlagen: </strong> Sofern wir
          Kommunikationspartner vor der Kommunikation mit ihnen via Messenger um
          eine Erlaubnis bitten, ist die Rechtsgrundlage unserer Verarbeitung
          ihrer Daten deren Einwilligung. Im &Uuml;brigen, falls wir nicht um
          eine Einwilligung bitten und sie z. B. von sich aus Kontakt mit uns
          aufnehmen, nutzen wir Messenger im Verh&auml;ltnis zu unseren
          Vertragspartnern sowie im Rahmen der Vertragsanbahnung als eine
          vertragliche Ma&szlig;nahme und im Fall anderer Interessenten und
          Kommunikationspartner auf Grundlage unserer berechtigten Interessen an
          einer schnellen und effizienten Kommunikation und Erf&uuml;llung der
          Bed&uuml;rfnisse unser Kommunikationspartner an der Kommunikation via
          Messenger. Ferner weisen wir Sie darauf hin, dass wir die uns
          mitgeteilten Kontaktdaten ohne Ihre Einwilligung nicht erstmalig an
          die Messenger &uuml;bermitteln.
        </p>
        <p>
          <strong>Widerruf, Widerspruch und L&ouml;schung:</strong> Sie
          k&ouml;nnen jederzeit eine erteilte Einwilligung widerrufen und der
          Kommunikation mit uns via Messenger jederzeit widersprechen. Im Fall
          der Kommunikation via Messenger l&ouml;schen wir die Nachrichten
          entsprechend unseren generellen L&ouml;schrichtlinien (d. h. z. B.,
          wie oben beschrieben, nach Ende vertraglicher Beziehungen, im Kontext
          von Archivierungsvorgaben etc.) und sonst, sobald wir davon ausgehen
          k&ouml;nnen, etwaige Ausk&uuml;nfte der Kommunikationspartner
          beantwortet zu haben, wenn kein R&uuml;ckbezug auf eine vorhergehende
          Konversation zu erwarten ist und der L&ouml;schung keine gesetzlichen
          Aufbewahrungspflichten entgegenstehen.
        </p>
        <p>
          <strong>
            Vorbehalt des Verweises auf andere Kommunikationswege:
          </strong>{' '}
          Um Ihre Sicherheit zu gew&auml;hrleisten, bitten wir um
          Verst&auml;ndnis, dass wir aus bestimmten Gr&uuml;nden Anfragen
          &uuml;ber Messenger m&ouml;glicherweise nicht beantworten k&ouml;nnen.
          Dies betrifft Situationen, in denen etwa Vertragsdetails besonders
          vertraulich behandelt werden m&uuml;ssen oder eine Antwort &uuml;ber
          Messenger den formellen Anforderungen nicht entspricht. In diesen
          F&auml;llen empfehlen wir Ihnen, auf geeignetere
          Kommunikationskan&auml;le zur&uuml;ckzugreifen.
        </p>
        <ul className="m-elements">
          <li>
            <strong>Verarbeitete Datenarten:</strong> Kontaktdaten (z. B. Post-
            und E-Mail-Adressen oder Telefonnummern); Inhaltsdaten (z. B.
            textliche oder bildliche Nachrichten und Beitr&auml;ge sowie die sie
            betreffenden Informationen, wie z. B. Angaben zur Autorenschaft oder
            Zeitpunkt der Erstellung); Nutzungsdaten (z. B. Seitenaufrufe und
            Verweildauer, Klickpfade, Nutzungsintensit&auml;t und -frequenz,
            verwendete Ger&auml;tetypen und Betriebssysteme, Interaktionen mit
            Inhalten und Funktionen). Meta-, Kommunikations- und Verfahrensdaten
            (z. B. IP-Adressen, Zeitangaben, Identifikationsnummern, beteiligte
            Personen).
          </li>
          <li>
            <strong>Betroffene Personen:</strong> Kommunikationspartner.
          </li>
          <li>
            <strong>Zwecke der Verarbeitung:</strong> Kommunikation.
          </li>
          <li>
            <strong>Aufbewahrung und L&ouml;schung:</strong> L&ouml;schung
            entsprechend Angaben im Abschnitt "Allgemeine Informationen zur
            Datenspeicherung und L&ouml;schung".
          </li>
          <li className="">
            <strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S. 1
            lit. a) DSGVO); Vertragserf&uuml;llung und vorvertragliche Anfragen
            (Art. 6 Abs. 1 S. 1 lit. b) DSGVO). Berechtigte Interessen (Art. 6
            Abs. 1 S. 1 lit. f) DSGVO).
          </li>
        </ul>
        <p>
          <strong>
            Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:
          </strong>
        </p>
        <ul className="m-elements">
          <li>
            <strong>LinkedIn: </strong>Soziales Netzwerk - Wir sind gemeinsam
            mit LinkedIn Irland Unlimited Company f&uuml;r die Erhebung (jedoch
            nicht die weitere Verarbeitung) von Daten der Besucher
            verantwortlich, die zur Erstellung der „Page-Insights" (Statistiken)
            unserer LinkedIn-Profile genutzt werden. Zu diesen Daten
            geh&ouml;ren Informationen &uuml;ber die Arten von Inhalten, die
            Nutzer sich ansehen oder mit denen sie interagieren, sowie die von
            ihnen vorgenommenen Handlungen. Au&szlig;erdem werden Details
            &uuml;ber die genutzten Ger&auml;te erfasst, wie z. B. IP-Adressen,
            Betriebssystem, Browsertyp, Spracheinstellungen und Cookie-Daten,
            sowie Angaben aus den Nutzerprofilen, wie Berufsfunktion, Land,
            Branche, Hierarchieebene, Unternehmensgr&ouml;&szlig;e und
            Besch&auml;ftigungsstatus. Datenschutzinformationen zur Verarbeitung
            von Nutzerdaten durch LinkedIn k&ouml;nnen den Datenschutzhinweisen
            von LinkedIn entnommen werden:{' '}
            <a
              href="https://www.linkedin.com/legal/privacy-policy"
              target="_blank"
              rel="noreferrer"
            >
              https://www.linkedin.com/legal/privacy-policy.
            </a>
            <br />
            Wir haben mit LinkedIn Irland eine spezielle Vereinbarung
            geschlossen („Page Insights Joint Controller Addendum",{' '}
            <a
              href="https://legal.linkedin.com/pages-joint-controller-addendum"
              target="_blank"
              rel="noreferrer"
            >
              https://legal.linkedin.com/pages-joint-controller-addendum
            </a>
            ), in der insbesondere geregelt wird, welche
            Sicherheitsma&szlig;nahmen LinkedIn beachten muss und in der
            LinkedIn sich bereit erkl&auml;rt hat, die Rechte der Betroffenen zu
            erf&uuml;llen (d. h. Nutzer k&ouml;nnen z. B. Auskunfts- oder
            L&ouml;schungsanfragen direkt an LinkedIn richten). Die Rechte der
            Nutzer (insbesondere das Recht auf Auskunft, L&ouml;schung,
            Widerspruch und Beschwerde bei der zust&auml;ndigen
            Aufsichtsbeh&ouml;rde) werden durch die Vereinbarungen mit LinkedIn
            nicht eingeschr&auml;nkt. Die gemeinsame Verantwortlichkeit
            beschr&auml;nkt sich auf die Erhebung und &Uuml;bermittlung der
            Daten an LinkedIn Irland Unlimited Company, ein Unternehmen mit Sitz
            in der EU. Die weitere Verarbeitung der Daten obliegt
            ausschlie&szlig;lich LinkedIn Irland Unlimited Company, insbesondere
            was die &Uuml;bermittlung der Daten an die Muttergesellschaft
            LinkedIn Corporation in den USA betrifft;{' '}
            <strong>Dienstanbieter:</strong> LinkedIn Ireland Unlimited Company,
            Wilton Place, Dublin 2, Irland;{' '}
            <span className="">
              <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6
              Abs. 1 S. 1 lit. f) DSGVO);{' '}
            </span>
            <strong>Website:</strong>{' '}
            <a href="https://www.linkedin.com" target="_blank" rel="noreferrer">
              https://www.linkedin.com
            </a>
            ; <strong>Datenschutzerkl&auml;rung:</strong>{' '}
            <a
              href="https://www.linkedin.com/legal/privacy-policy"
              target="_blank"
              rel="noreferrer"
            >
              https://www.linkedin.com/legal/privacy-policy
            </a>
            ; <strong>Grundlage Drittlandtransfers:</strong> Data Privacy
            Framework (DPF), Standardvertragsklauseln (
            <a
              href="https://legal.linkedin.com/dpa"
              target="_blank"
              rel="noreferrer"
            >
              https://legal.linkedin.com/dpa
            </a>
            ), Data Privacy Framework (DPF)Standardvertragsklauseln (
            <a
              href="https://legal.linkedin.com/dpa"
              target="_blank"
              rel="noreferrer"
            >
              https://legal.linkedin.com/dpa
            </a>
            ). <strong>Widerspruchsm&ouml;glichkeit (Opt-Out):</strong>{' '}
            <a
              href="https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out"
              target="_blank"
              rel="noreferrer"
            >
              https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out
            </a>
            .
          </li>
          <li>
            <strong>LinkedIn-Nachrichten: </strong>Nachrichtenversand &uuml;ber
            das soziale Netzwerk LinkedIn; <strong>Dienstanbieter:</strong>{' '}
            LinkedIn Ireland Unlimited Company, Wilton Place, Dublin 2, Irland;{' '}
            <span className="">
              <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6
              Abs. 1 S. 1 lit. f) DSGVO);{' '}
            </span>
            <strong>Website:</strong>{' '}
            <a href="https://www.linkedin.com" target="_blank" rel="noreferrer">
              https://www.linkedin.com
            </a>
            ; <strong>Datenschutzerkl&auml;rung:</strong>{' '}
            <a
              href="https://www.linkedin.com/legal/privacy-policy"
              target="_blank"
              rel="noreferrer"
            >
              https://www.linkedin.com/legal/privacy-policy
            </a>
            . <strong>Grundlage Drittlandtransfers:</strong> Data Privacy
            Framework (DPF), Data Privacy Framework (DPF).
          </li>
        </ul>
        <h2 id="m735">
          Videokonferenzen, Onlinemeetings, Webinare und Bildschirm-Sharing
        </h2>
        <p>
          Wir setzen Plattformen und Anwendungen anderer Anbieter (nachfolgend
          bezeichnet als "Konferenzplattformen") zu Zwecken der
          Durchf&uuml;hrung von Video- und Audio-Konferenzen, Webinaren und
          sonstigen Arten von Video und Audio-Meetings (nachfolgend
          zusammenfassend bezeichnet als "Konferenz") ein. Bei der Auswahl der
          Konferenzplattformen und ihrer Leistungen beachten wir die
          gesetzlichen Vorgaben.{' '}
        </p>
        <p>
          <strong>Durch Konferenzplattformen verarbeitete Daten:</strong> Im
          Rahmen der Teilnahme an einer Konferenz verarbeiten die
          Konferenzplattformen die im Folgenden genannten personenbezogene Daten
          der Teilnehmer. Der Umfang der Verarbeitung h&auml;ngt zum einen davon
          ab, welche Daten im Rahmen einer konkreten Konferenz gefordert werden
          (z. B. Angabe von Zugangsdaten oder Klarnamen) und welche optionalen
          Angaben durch die Teilnehmer get&auml;tigt werden. Neben der
          Verarbeitung zur Durchf&uuml;hrung der Konferenz, k&ouml;nnen die
          Daten der Teilnehmer durch die Konferenzplattformen ebenfalls zu
          Sicherheitszwecken oder Serviceoptimierung verarbeitet werden. Zu den
          verarbeiteten Daten geh&ouml;ren Daten zur Person (Vorname, Nachname),
          Kontaktinformationen (E-Mail-Adresse, Telefonnummer), Zugangsdaten
          (Zugangscodes oder Passw&ouml;rter), Profilbilder, Angaben zur
          beruflichen Stellung/Funktion, die IP-Adresse des Internetzugangs,
          Angaben zu den Endger&auml;ten der Teilnehmer, deren Betriebssystem,
          dem Browser und dessen technischen und sprachlichen Einstellungen,
          Informationen zu den inhaltlichen Kommunikationsvorg&auml;ngen, d. h.
          Eingaben in Chats sowie Audio- und Videodaten, als auch die Nutzung
          anderer zur Verf&uuml;gung stehender Funktionen (z. B. Umfragen).
          Inhalte der Kommunikationen werden in dem durch die Konferenzanbieter
          technisch bereitgestellten Umfang verschl&uuml;sselt. Wenn die
          Teilnehmer bei den Konferenzplattformen als Benutzer registriert sind,
          dann k&ouml;nnen weitere Daten entsprechend der Vereinbarung mit dem
          jeweiligen Konferenzanbieter verarbeitet werden.
        </p>
        <p>
          <strong>Protokollierung und Aufnahmen:</strong> Falls Texteingaben,
          Teilnahmeergebnisse (z. B. von Umfragen) sowie Video- oder
          Audioaufnahmen protokolliert werden, wird dies den Teilnehmern im
          Vorwege transparent mitgeteilt und sie werden – soweit erforderlich –
          um eine Zustimmung gebeten.
        </p>
        <p>
          <strong>Datenschutzma&szlig;nahmen der Teilnehmer:</strong> Bitte
          beachten Sie zu den Details der Verarbeitung Ihrer Daten durch die
          Konferenzplattformen deren Datenschutzhinweise und w&auml;hlen im
          Rahmen der Einstellungen der Konferenzplattformen, die f&uuml;r Sie
          optimalen Sicherheits- und Datenschutzeinstellungen. Bitte sorgen Sie
          ferner f&uuml;r die Dauer einer Videokonferenz f&uuml;r den Daten- und
          Pers&ouml;nlichkeitsschutz im Hintergrund Ihrer Aufnahme (z. B. durch
          Hinweise an Mitbewohner, Abschlie&szlig;en von T&uuml;ren und Nutzung,
          soweit technisch m&ouml;glich, der Funktion zur Unkenntlichmachung des
          Hintergrunds). Links zu den Konferenzr&auml;umen sowie Zugangsdaten,
          d&uuml;rfen nicht an unberechtigte Dritte weitergegeben werden.
        </p>
        <p>
          <strong>Hinweise zu Rechtsgrundlagen:</strong> Sofern neben den
          Konferenzplattformen auch wir die Daten der Nutzer verarbeiten und die
          Nutzer um deren Einwilligung in den Einsatz der Konferenzplattformen
          oder bestimmter Funktionen bitten (z. B. Einverst&auml;ndnis mit einer
          Aufzeichnung von Konferenzen), ist die Rechtsgrundlage der
          Verarbeitung diese Einwilligung. Ferner kann unsere Verarbeitung zur
          Erf&uuml;llung unserer vertraglichen Pflichten erforderlich sein
          (z. B. in Teilnehmerlisten, im Fall von Aufarbeitung von
          Gespr&auml;chsergebnissen, etc.). Im &Uuml;brigen werden die Daten der
          Nutzer auf Grundlage unserer berechtigten Interessen an einer
          effizienten und sicheren Kommunikation mit unseren
          Kommunikationspartnern verarbeitet.
        </p>
        <ul className="m-elements">
          <li>
            <strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z. B. der
            vollst&auml;ndige Name, Wohnadresse, Kontaktinformationen,
            Kundennummer, etc.); Kontaktdaten (z. B. Post- und E-Mail-Adressen
            oder Telefonnummern); Inhaltsdaten (z. B. textliche oder bildliche
            Nachrichten und Beitr&auml;ge sowie die sie betreffenden
            Informationen, wie z. B. Angaben zur Autorenschaft oder Zeitpunkt
            der Erstellung); Nutzungsdaten (z. B. Seitenaufrufe und
            Verweildauer, Klickpfade, Nutzungsintensit&auml;t und -frequenz,
            verwendete Ger&auml;tetypen und Betriebssysteme, Interaktionen mit
            Inhalten und Funktionen); Bild- und/ oder Videoaufnahmen (z. B.
            Fotografien oder Videoaufnahmen einer Person); Tonaufnahmen.
            Protokolldaten (z. B. Logfiles betreffend Logins oder den Abruf von
            Daten oder Zugriffszeiten.).
          </li>
          <li>
            <strong>Betroffene Personen:</strong> Kommunikationspartner; Nutzer
            (z. B. Webseitenbesucher, Nutzer von Onlinediensten). Abgebildete
            Personen.
          </li>
          <li>
            <strong>Zwecke der Verarbeitung:</strong> Erbringung vertraglicher
            Leistungen und Erf&uuml;llung vertraglicher Pflichten;
            Kommunikation. B&uuml;ro- und Organisationsverfahren.
          </li>
          <li>
            <strong>Aufbewahrung und L&ouml;schung:</strong> L&ouml;schung
            entsprechend Angaben im Abschnitt "Allgemeine Informationen zur
            Datenspeicherung und L&ouml;schung".
          </li>
          <li className="">
            <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6
            Abs. 1 S. 1 lit. f) DSGVO).
          </li>
        </ul>
        <p>
          <strong>
            Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:
          </strong>
        </p>
        <ul className="m-elements">
          <li>
            <strong>Discord: </strong>Chat, Audio- und Video&uuml;bertragungen,
            Instant Messaging und Community-Management;{' '}
            <strong>Dienstanbieter:</strong> Discord, Inc., 444 De Haro St,
            Suite 200, San Francisco, California 94107, USA;{' '}
            <span className="">
              <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6
              Abs. 1 S. 1 lit. f) DSGVO);{' '}
            </span>
            <strong>Website:</strong>{' '}
            <a href="https://discord.com/" target="_blank" rel="noreferrer">
              https://discord.com/
            </a>
            ; <strong>Datenschutzerkl&auml;rung:</strong>{' '}
            <a
              href="https://discord.com/privacy"
              target="_blank"
              rel="noreferrer"
            >
              https://discord.com/privacy
            </a>
            . <strong>Grundlage Drittlandtransfers:</strong> Data Privacy
            Framework (DPF), Data Privacy Framework (DPF).
          </li>
          <li>
            <strong>Slack: </strong>Messenger- und Konferenz-Software;{' '}
            <strong>Dienstanbieter:</strong> Slack Technologies Limited, Level
            1, Block A Nova Atria North, Sandyford Business District, Dublin 18,
            Irland;{' '}
            <span className="">
              <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6
              Abs. 1 S. 1 lit. f) DSGVO);{' '}
            </span>
            <strong>Website:</strong>{' '}
            <a
              href="https://slack.com/intl/de-de/"
              target="_blank"
              rel="noreferrer"
            >
              https://slack.com/intl/de-de/
            </a>
            ; <strong>Datenschutzerkl&auml;rung:</strong>{' '}
            <a
              href="https://slack.com/intl/de-de/legal"
              target="_blank"
              rel="noreferrer"
            >
              https://slack.com/intl/de-de/legal
            </a>
            ; <strong>Auftragsverarbeitungsvertrag:</strong>{' '}
            <a
              href="https://slack.com/intl/de-de/terms-of-service/data-processing"
              target="_blank"
              rel="noreferrer"
            >
              https://slack.com/intl/de-de/terms-of-service/data-processing
            </a>
            . <strong>Grundlage Drittlandtransfers:</strong> Data Privacy
            Framework (DPF), Standardvertragsklauseln (
            <a
              href="https://slack.com/intl/de-de/terms-of-service/data-processing"
              target="_blank"
              rel="noreferrer"
            >
              https://slack.com/intl/de-de/terms-of-service/data-processing
            </a>
            ), Data Privacy Framework (DPF)Standardvertragsklauseln (
            <a
              href="https://slack.com/intl/de-de/terms-of-service/data-processing"
              target="_blank"
              rel="noreferrer"
            >
              https://slack.com/intl/de-de/terms-of-service/data-processing
            </a>
            ).
          </li>
        </ul>
        <h2 id="m29">Cloud-Dienste</h2>
        <p>
          Wir nutzen &uuml;ber das Internet zug&auml;ngliche und auf den Servern
          ihrer Anbieter ausgef&uuml;hrte Softwaredienste (sogenannte
          "Cloud-Dienste", auch bezeichnet als "Software as a Service") f&uuml;r
          die Speicherung und Verwaltung von Inhalten (z. B.
          Dokumentenspeicherung und -verwaltung, Austausch von Dokumenten,
          Inhalten und Informationen mit bestimmten Empf&auml;ngern oder
          Ver&ouml;ffentlichung von Inhalten und Informationen).
        </p>
        <p>
          In diesem Rahmen k&ouml;nnen personenbezogenen Daten verarbeitet und
          auf den Servern der Anbieter gespeichert werden, soweit diese
          Bestandteil von Kommunikationsvorg&auml;ngen mit uns sind oder von uns
          sonst, wie im Rahmen dieser Datenschutzerkl&auml;rung dargelegt,
          verarbeitet werden. Zu diesen Daten k&ouml;nnen insbesondere
          Stammdaten und Kontaktdaten der Nutzer, Daten zu Vorg&auml;ngen,
          Vertr&auml;gen, sonstigen Prozessen und deren Inhalte geh&ouml;ren.
          Die Anbieter der Cloud-Dienste verarbeiten ferner Nutzungsdaten und
          Metadaten, die von ihnen zu Sicherheitszwecken und zur
          Serviceoptimierung verwendet werden.
        </p>
        <p>
          Sofern wir mit Hilfe der Cloud-Dienste f&uuml;r andere Nutzer oder
          &ouml;ffentlich zug&auml;ngliche Webseiten Formulare o.a. Dokumente
          und Inhalte bereitstellen, k&ouml;nnen die Anbieter Cookies auf den
          Ger&auml;ten der Nutzer f&uuml;r Zwecke der Webanalyse oder, um sich
          Einstellungen der Nutzer (z. B. im Fall der Mediensteuerung) zu
          merken, speichern.
        </p>
        <ul className="m-elements">
          <li>
            <strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z. B. der
            vollst&auml;ndige Name, Wohnadresse, Kontaktinformationen,
            Kundennummer, etc.); Kontaktdaten (z. B. Post- und E-Mail-Adressen
            oder Telefonnummern); Inhaltsdaten (z. B. textliche oder bildliche
            Nachrichten und Beitr&auml;ge sowie die sie betreffenden
            Informationen, wie z. B. Angaben zur Autorenschaft oder Zeitpunkt
            der Erstellung). Nutzungsdaten (z. B. Seitenaufrufe und
            Verweildauer, Klickpfade, Nutzungsintensit&auml;t und -frequenz,
            verwendete Ger&auml;tetypen und Betriebssysteme, Interaktionen mit
            Inhalten und Funktionen).
          </li>
          <li>
            <strong>Betroffene Personen:</strong> Interessenten;
            Kommunikationspartner; Gesch&auml;fts- und Vertragspartner. Nutzer
            (z. B. Webseitenbesucher, Nutzer von Onlinediensten).
          </li>
          <li>
            <strong>Zwecke der Verarbeitung:</strong> B&uuml;ro- und
            Organisationsverfahren. Informationstechnische Infrastruktur
            (Betrieb und Bereitstellung von Informationssystemen und technischen
            Ger&auml;ten (Computer, Server etc.)).
          </li>
          <li>
            <strong>Aufbewahrung und L&ouml;schung:</strong> L&ouml;schung
            entsprechend Angaben im Abschnitt "Allgemeine Informationen zur
            Datenspeicherung und L&ouml;schung".
          </li>
          <li className="">
            <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6
            Abs. 1 S. 1 lit. f) DSGVO).
          </li>
        </ul>
        <p>
          <strong>
            Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:
          </strong>
        </p>
        <ul className="m-elements">
          <li>
            <strong>Google Cloud-Speicher: </strong>Cloudspeicher,
            Cloudinfrastrukturdienste und cloudbasierte Anwendungssoftware;{' '}
            <strong>Dienstanbieter:</strong> Google Cloud EMEA Limited, 70 Sir
            John Rogerson’s Quay, Dublin 2, Irland;{' '}
            <span className="">
              <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6
              Abs. 1 S. 1 lit. f) DSGVO);{' '}
            </span>
            <strong>Website:</strong>{' '}
            <a
              href="https://cloud.google.com/"
              target="_blank"
              rel="noreferrer"
            >
              https://cloud.google.com/
            </a>
            ; <strong>Datenschutzerkl&auml;rung:</strong>{' '}
            <a
              href="https://policies.google.com/privacy"
              target="_blank"
              rel="noreferrer"
            >
              https://policies.google.com/privacy
            </a>
            ; <strong>Auftragsverarbeitungsvertrag:</strong>{' '}
            <a
              href="https://cloud.google.com/terms/data-processing-addendum"
              target="_blank"
              rel="noreferrer"
            >
              https://cloud.google.com/terms/data-processing-addendum
            </a>
            ; <strong>Grundlage Drittlandtransfers:</strong> Data Privacy
            Framework (DPF), Standardvertragsklauseln (
            <a
              href="https://cloud.google.com/terms/eu-model-contract-clause"
              target="_blank"
              rel="noreferrer"
            >
              https://cloud.google.com/terms/eu-model-contract-clause
            </a>
            ), Data Privacy Framework (DPF)Standardvertragsklauseln (
            <a
              href="https://cloud.google.com/terms/eu-model-contract-clause"
              target="_blank"
              rel="noreferrer"
            >
              https://cloud.google.com/terms/eu-model-contract-clause
            </a>
            ). <strong>Weitere Informationen:</strong>{' '}
            <a
              href="https://cloud.google.com/privacy"
              target="_blank"
              rel="noreferrer"
            >
              https://cloud.google.com/privacy
            </a>
            .
          </li>
          <li>
            <strong>Google Workspace: </strong>Cloudbasierte Anwendungssoftware
            (z. B. Text- und Tabellenberarbeitung, Termine- und
            Kontakteverwaltung), Cloudspeicher und Cloudinfrastrukturdienste;{' '}
            <strong>Dienstanbieter:</strong> Google Cloud EMEA Limited, 70 Sir
            John Rogerson’s Quay, Dublin 2, Irland;{' '}
            <span className="">
              <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6
              Abs. 1 S. 1 lit. f) DSGVO);{' '}
            </span>
            <strong>Website:</strong>{' '}
            <a
              href="https://workspace.google.com/"
              target="_blank"
              rel="noreferrer"
            >
              https://workspace.google.com/
            </a>
            ; <strong>Datenschutzerkl&auml;rung:</strong>{' '}
            <a
              href="https://policies.google.com/privacy"
              target="_blank"
              rel="noreferrer"
            >
              https://policies.google.com/privacy
            </a>
            ; <strong>Auftragsverarbeitungsvertrag:</strong>{' '}
            <a
              href="https://cloud.google.com/terms/data-processing-addendum"
              target="_blank"
              rel="noreferrer"
            >
              https://cloud.google.com/terms/data-processing-addendum
            </a>
            ; <strong>Grundlage Drittlandtransfers:</strong> Data Privacy
            Framework (DPF), Standardvertragsklauseln (
            <a
              href="https://cloud.google.com/terms/eu-model-contract-clause"
              target="_blank"
              rel="noreferrer"
            >
              https://cloud.google.com/terms/eu-model-contract-clause
            </a>
            ), Data Privacy Framework (DPF)Standardvertragsklauseln (
            <a
              href="https://cloud.google.com/terms/eu-model-contract-clause"
              target="_blank"
              rel="noreferrer"
            >
              https://cloud.google.com/terms/eu-model-contract-clause
            </a>
            ). <strong>Weitere Informationen:</strong>{' '}
            <a
              href="https://cloud.google.com/privacy"
              target="_blank"
              rel="noreferrer"
            >
              https://cloud.google.com/privacy
            </a>
            .
          </li>
          <li>
            <strong>ClickUp: </strong>Projektmanagement - Organisation und
            Verwaltung von Teams, Gruppen, Arbeitsabl&auml;ufen, Projekten und
            Prozessen; <strong>Dienstanbieter:</strong> Mango Technologies,
            Inc., 580 Howard St, Suite 101, San Francisco, California 94105,
            USA;{' '}
            <span className="">
              <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6
              Abs. 1 S. 1 lit. f) DSGVO);{' '}
            </span>
            <strong>Website:</strong>{' '}
            <a href="https://clickup.com/" target="_blank" rel="noreferrer">
              https://clickup.com/
            </a>
            ; <strong>Datenschutzerkl&auml;rung:</strong>{' '}
            <a
              href="https://clickup.com/terms/privacy"
              target="_blank"
              rel="noreferrer"
            >
              https://clickup.com/terms/privacy
            </a>
            ; <strong>Auftragsverarbeitungsvertrag:</strong>{' '}
            <a
              href="https://clickup.com/terms/dpa"
              target="_blank"
              rel="noreferrer"
            >
              https://clickup.com/terms/dpa
            </a>
            . <strong>Grundlage Drittlandtransfers:</strong> Data Privacy
            Framework (DPF), Data Privacy Framework (DPF).
          </li>
        </ul>
        <h2 id="m17">Newsletter und elektronische Benachrichtigungen</h2>
        <p>
          Wir versenden Newsletter, E-Mails und weitere elektronische
          Benachrichtigungen (nachfolgend „Newsletter") ausschlie&szlig;lich mit
          der Einwilligung der Empf&auml;nger oder aufgrund einer gesetzlichen
          Grundlage. Sofern im Rahmen einer Anmeldung zum Newsletter dessen
          Inhalte genannt werden, sind diese Inhalte f&uuml;r die Einwilligung
          der Nutzer ma&szlig;geblich. F&uuml;r die Anmeldung zu unserem
          Newsletter ist normalerweise die Angabe Ihrer E-Mail-Adresse
          ausreichend. Um Ihnen jedoch einen personalisierten Service bieten zu
          k&ouml;nnen, bitten wir gegebenenfalls um die Angabe Ihres Namens
          f&uuml;r eine pers&ouml;nliche Ansprache im Newsletter oder um weitere
          Informationen, falls diese f&uuml;r den Zweck des Newsletters
          notwendig sind.
        </p>
        <p>
          L&ouml;schung und Einschr&auml;nkung der Verarbeitung: Wir k&ouml;nnen
          die ausgetragenen E-Mail-Adressen bis zu drei Jahren auf Grundlage
          unserer berechtigten Interessen speichern, bevor wir sie l&ouml;schen,
          um eine ehemals gegebene Einwilligung nachweisen zu k&ouml;nnen. Die
          Verarbeitung dieser Daten wird auf den Zweck einer potenziellen Abwehr
          von Anspr&uuml;chen beschr&auml;nkt. Ein individueller
          L&ouml;schungsantrag ist jederzeit m&ouml;glich, sofern zugleich das
          ehemalige Bestehen einer Einwilligung best&auml;tigt wird. Im Fall von
          Pflichten zur dauerhaften Beachtung von Widerspr&uuml;chen behalten
          wir uns die Speicherung der E-Mail-Adresse alleine zu diesem Zweck in
          einer Sperrliste (sogenannte „Blocklist") vor.
        </p>
        <p>
          Die Protokollierung des Anmeldeverfahrens erfolgt auf Grundlage
          unserer berechtigten Interessen zum Zweck des Nachweises seines
          ordnungsgem&auml;&szlig;en Ablaufs. Soweit wir einen Dienstleister mit
          dem Versand von E-Mails beauftragen, erfolgt dies auf Grundlage
          unserer berechtigten Interessen an einem effizienten und sicheren
          Versandsystem.
        </p>
        <strong>Inhalte:</strong>{' '}
        <p>Informationen zur Lotterie f&uuml;r die n&auml;chste Konferenz</p>
        <ul className="m-elements">
          <li>
            <strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z. B. der
            vollst&auml;ndige Name, Wohnadresse, Kontaktinformationen,
            Kundennummer, etc.); Kontaktdaten (z. B. Post- und E-Mail-Adressen
            oder Telefonnummern); Meta-, Kommunikations- und Verfahrensdaten (z.
            B. IP-Adressen, Zeitangaben, Identifikationsnummern, beteiligte
            Personen); Inhaltsdaten (z. B. textliche oder bildliche Nachrichten
            und Beitr&auml;ge sowie die sie betreffenden Informationen, wie z.
            B. Angaben zur Autorenschaft oder Zeitpunkt der Erstellung).
            Nutzungsdaten (z. B. Seitenaufrufe und Verweildauer, Klickpfade,
            Nutzungsintensit&auml;t und -frequenz, verwendete Ger&auml;tetypen
            und Betriebssysteme, Interaktionen mit Inhalten und Funktionen).
          </li>
          <li>
            <strong>Betroffene Personen:</strong> Kommunikationspartner; Nutzer
            (z. B. Webseitenbesucher, Nutzer von Onlinediensten).
            Gesch&auml;fts- und Vertragspartner.
          </li>
          <li>
            <strong>Zwecke der Verarbeitung:</strong> Direktmarketing (z. B. per
            E-Mail oder postalisch). Informationstechnische Infrastruktur
            (Betrieb und Bereitstellung von Informationssystemen und technischen
            Ger&auml;ten (Computer, Server etc.)).
          </li>
          <li className="">
            <strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S. 1
            lit. a) DSGVO). Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f)
            DSGVO).
          </li>
          <li>
            <strong>Widerspruchsm&ouml;glichkeit (Opt-Out): </strong>Sie
            k&ouml;nnen den Empfang unseres Newsletters jederzeit k&uuml;ndigen,
            d. .h. Ihre Einwilligungen widerrufen, bzw. dem weiteren Empfang
            widersprechen. Einen Link zur K&uuml;ndigung des Newsletters finden
            Sie entweder am Ende eines jeden Newsletters oder k&ouml;nnen sonst
            eine der oben angegebenen Kontaktm&ouml;glichkeiten,
            vorzugsw&uuml;rdig E-Mail, hierzu nutzen.
          </li>
        </ul>
        <p>
          <strong>
            Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:
          </strong>
        </p>
        <ul className="m-elements">
          <li>
            <strong>Amazon Web Services (AWS): </strong>Leistungen auf dem
            Gebiet der Bereitstellung von informationstechnischer Infrastruktur
            und verbundenen Dienstleistungen (z. B. Speicherplatz und/oder
            Rechenkapazit&auml;ten); <strong>Dienstanbieter:</strong> Amazon Web
            Services EMEA SARL, 38 avenue John F. Kennedy, L-1855, Luxemburg;{' '}
            <span className="">
              <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6
              Abs. 1 S. 1 lit. f) DSGVO);{' '}
            </span>
            <strong>Website:</strong>{' '}
            <a
              href="https://aws.amazon.com/de/"
              target="_blank"
              rel="noreferrer"
            >
              https://aws.amazon.com/de/
            </a>
            ; <strong>Datenschutzerkl&auml;rung:</strong>{' '}
            <a
              href="https://aws.amazon.com/de/privacy/"
              target="_blank"
              rel="noreferrer"
            >
              https://aws.amazon.com/de/privacy/
            </a>
            ; <strong>Auftragsverarbeitungsvertrag:</strong>{' '}
            <a
              href="https://aws.amazon.com/de/compliance/gdpr-center/"
              target="_blank"
              rel="noreferrer"
            >
              https://aws.amazon.com/de/compliance/gdpr-center/
            </a>
            . <strong>Grundlage Drittlandtransfers:</strong> Data Privacy
            Framework (DPF), Standardvertragsklauseln (
            <a
              href="https://aws.amazon.com/service-terms/"
              target="_blank"
              rel="noreferrer"
            >
              https://aws.amazon.com/service-terms/
            </a>
            ), Data Privacy Framework (DPF)Standardvertragsklauseln (
            <a
              href="https://aws.amazon.com/service-terms/"
              target="_blank"
              rel="noreferrer"
            >
              https://aws.amazon.com/service-terms/
            </a>
            ).
          </li>
        </ul>
        <h2 id="m136">Pr&auml;senzen in sozialen Netzwerken (Social Media)</h2>
        <p>
          Wir unterhalten Onlinepr&auml;senzen innerhalb sozialer Netzwerke und
          verarbeiten in diesem Rahmen Nutzerdaten, um mit den dort aktiven
          Nutzern zu kommunizieren oder Informationen &uuml;ber uns anzubieten.
        </p>
        <p>
          Wir weisen darauf hin, dass dabei Nutzerdaten au&szlig;erhalb des
          Raumes der Europ&auml;ischen Union verarbeitet werden k&ouml;nnen.
          Hierdurch k&ouml;nnen sich f&uuml;r die Nutzer Risiken ergeben, weil
          so zum Beispiel die Durchsetzung der Nutzerrechte erschwert werden
          k&ouml;nnte.
        </p>
        <p>
          Ferner werden die Daten der Nutzer innerhalb sozialer Netzwerke im
          Regelfall f&uuml;r Marktforschungs- und Werbezwecke verarbeitet. So
          k&ouml;nnen beispielsweise anhand des Nutzungsverhaltens und sich
          daraus ergebender Interessen der Nutzer Nutzungsprofile erstellt
          werden. Letztere finden m&ouml;glicherweise wiederum Verwendung, um
          etwa Werbeanzeigen innerhalb und au&szlig;erhalb der Netzwerke zu
          schalten, die mutma&szlig;lich den Interessen der Nutzer entsprechen.
          Daher werden im Regelfall Cookies auf den Rechnern der Nutzer
          gespeichert, in denen das Nutzungsverhalten und die Interessen der
          Nutzer gespeichert werden. Zudem k&ouml;nnen in den Nutzungsprofilen
          auch Daten unabh&auml;ngig der von den Nutzern verwendeten
          Ger&auml;ten gespeichert werden (insbesondere, wenn sie Mitglieder der
          jeweiligen Plattformen und dort eingeloggt sind).
        </p>
        <p>
          F&uuml;r eine detaillierte Darstellung der jeweiligen
          Verarbeitungsformen und der Widerspruchsm&ouml;glichkeiten (Opt-out)
          verweisen wir auf die Datenschutzerkl&auml;rungen und Angaben der
          Betreiber der jeweiligen Netzwerke.
        </p>
        <p>
          Auch im Fall von Auskunftsanfragen und der Geltendmachung von
          Betroffenenrechten weisen wir darauf hin, dass diese am effektivsten
          bei den Anbietern geltend gemacht werden k&ouml;nnen. Nur Letztere
          haben jeweils Zugriff auf die Nutzerdaten und k&ouml;nnen direkt
          entsprechende Ma&szlig;nahmen ergreifen und Ausk&uuml;nfte geben.
          Sollten Sie dennoch Hilfe ben&ouml;tigen, dann k&ouml;nnen Sie sich an
          uns wenden.
        </p>
        <ul className="m-elements">
          <li>
            <strong>Verarbeitete Datenarten:</strong> Kontaktdaten (z. B. Post-
            und E-Mail-Adressen oder Telefonnummern); Inhaltsdaten (z. B.
            textliche oder bildliche Nachrichten und Beitr&auml;ge sowie die sie
            betreffenden Informationen, wie z. B. Angaben zur Autorenschaft oder
            Zeitpunkt der Erstellung); Nutzungsdaten (z. B. Seitenaufrufe und
            Verweildauer, Klickpfade, Nutzungsintensit&auml;t und -frequenz,
            verwendete Ger&auml;tetypen und Betriebssysteme, Interaktionen mit
            Inhalten und Funktionen). Meta-, Kommunikations- und Verfahrensdaten
            (z. B. IP-Adressen, Zeitangaben, Identifikationsnummern, beteiligte
            Personen).
          </li>
          <li>
            <strong>Betroffene Personen:</strong> Nutzer (z. B.
            Webseitenbesucher, Nutzer von Onlinediensten).
          </li>
          <li>
            <strong>Zwecke der Verarbeitung:</strong> Kommunikation; Feedback
            (z. B. Sammeln von Feedback via Online-Formular);
            &Ouml;ffentlichkeitsarbeit. &Ouml;ffentlichkeitsarbeit und
            Informationszwecke.
          </li>
          <li>
            <strong>Aufbewahrung und L&ouml;schung:</strong> L&ouml;schung
            entsprechend Angaben im Abschnitt "Allgemeine Informationen zur
            Datenspeicherung und L&ouml;schung".
          </li>
          <li className="">
            <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6
            Abs. 1 S. 1 lit. f) DSGVO).
          </li>
        </ul>
        <p>
          <strong>
            Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:
          </strong>
        </p>
        <ul className="m-elements">
          <li>
            <strong>Bluesky: </strong>Dezentralisiertes Social-Media-Netzwerk -
            erm&ouml;glicht das Erstellen, Teilen und Kommentieren von Inhalten
            sowie das Folgen von Nutzerprofilen;{' '}
            <strong>Dienstanbieter:</strong> Bluesky, PBLLC., Seattle, USA,{' '}
            <a href="mailto:support@bsky.app">support@bsky.app</a>;{' '}
            <span className="">
              <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6
              Abs. 1 S. 1 lit. f) DSGVO);{' '}
            </span>
            <strong>Website:</strong>{' '}
            <a href="https://bsky.social/" target="_blank" rel="noreferrer">
              https://bsky.social/
            </a>
            . <strong>Datenschutzerkl&auml;rung:</strong>{' '}
            <a
              href="https://bsky.social/about/support/privacy-policy"
              target="_blank"
              rel="noreferrer"
            >
              https://bsky.social/about/support/privacy-policy
            </a>
            .
          </li>
          <li>
            <strong>LinkedIn: </strong>Soziales Netzwerk - Wir sind gemeinsam
            mit LinkedIn Irland Unlimited Company f&uuml;r die Erhebung (jedoch
            nicht die weitere Verarbeitung) von Daten der Besucher
            verantwortlich, die zur Erstellung der „Page-Insights" (Statistiken)
            unserer LinkedIn-Profile genutzt werden. Zu diesen Daten
            geh&ouml;ren Informationen &uuml;ber die Arten von Inhalten, die
            Nutzer sich ansehen oder mit denen sie interagieren, sowie die von
            ihnen vorgenommenen Handlungen. Au&szlig;erdem werden Details
            &uuml;ber die genutzten Ger&auml;te erfasst, wie z. B. IP-Adressen,
            Betriebssystem, Browsertyp, Spracheinstellungen und Cookie-Daten,
            sowie Angaben aus den Nutzerprofilen, wie Berufsfunktion, Land,
            Branche, Hierarchieebene, Unternehmensgr&ouml;&szlig;e und
            Besch&auml;ftigungsstatus. Datenschutzinformationen zur Verarbeitung
            von Nutzerdaten durch LinkedIn k&ouml;nnen den Datenschutzhinweisen
            von LinkedIn entnommen werden:{' '}
            <a
              href="https://www.linkedin.com/legal/privacy-policy"
              target="_blank"
              rel="noreferrer"
            >
              https://www.linkedin.com/legal/privacy-policy.
            </a>
            <br />
            Wir haben mit LinkedIn Irland eine spezielle Vereinbarung
            geschlossen („Page Insights Joint Controller Addendum",{' '}
            <a
              href="https://legal.linkedin.com/pages-joint-controller-addendum"
              target="_blank"
              rel="noreferrer"
            >
              https://legal.linkedin.com/pages-joint-controller-addendum
            </a>
            ), in der insbesondere geregelt wird, welche
            Sicherheitsma&szlig;nahmen LinkedIn beachten muss und in der
            LinkedIn sich bereit erkl&auml;rt hat, die Rechte der Betroffenen zu
            erf&uuml;llen (d. h. Nutzer k&ouml;nnen z. B. Auskunfts- oder
            L&ouml;schungsanfragen direkt an LinkedIn richten). Die Rechte der
            Nutzer (insbesondere das Recht auf Auskunft, L&ouml;schung,
            Widerspruch und Beschwerde bei der zust&auml;ndigen
            Aufsichtsbeh&ouml;rde) werden durch die Vereinbarungen mit LinkedIn
            nicht eingeschr&auml;nkt. Die gemeinsame Verantwortlichkeit
            beschr&auml;nkt sich auf die Erhebung und &Uuml;bermittlung der
            Daten an LinkedIn Irland Unlimited Company, ein Unternehmen mit Sitz
            in der EU. Die weitere Verarbeitung der Daten obliegt
            ausschlie&szlig;lich LinkedIn Irland Unlimited Company, insbesondere
            was die &Uuml;bermittlung der Daten an die Muttergesellschaft
            LinkedIn Corporation in den USA betrifft;{' '}
            <strong>Dienstanbieter:</strong> LinkedIn Ireland Unlimited Company,
            Wilton Place, Dublin 2, Irland;{' '}
            <span className="">
              <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6
              Abs. 1 S. 1 lit. f) DSGVO);{' '}
            </span>
            <strong>Website:</strong>{' '}
            <a href="https://www.linkedin.com" target="_blank" rel="noreferrer">
              https://www.linkedin.com
            </a>
            ; <strong>Datenschutzerkl&auml;rung:</strong>{' '}
            <a
              href="https://www.linkedin.com/legal/privacy-policy"
              target="_blank"
              rel="noreferrer"
            >
              https://www.linkedin.com/legal/privacy-policy
            </a>
            ; <strong>Grundlage Drittlandtransfers:</strong> Data Privacy
            Framework (DPF), Standardvertragsklauseln (
            <a
              href="https://legal.linkedin.com/dpa"
              target="_blank"
              rel="noreferrer"
            >
              https://legal.linkedin.com/dpa
            </a>
            ), Data Privacy Framework (DPF)Standardvertragsklauseln (
            <a
              href="https://legal.linkedin.com/dpa"
              target="_blank"
              rel="noreferrer"
            >
              https://legal.linkedin.com/dpa
            </a>
            ). <strong>Widerspruchsm&ouml;glichkeit (Opt-Out):</strong>{' '}
            <a
              href="https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out"
              target="_blank"
              rel="noreferrer"
            >
              https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out
            </a>
            .
          </li>
        </ul>
        <h2 id="m723">Management, Organisation und Hilfswerkzeuge</h2>
        <p>
          Wir setzen Dienstleistungen, Plattformen und Software anderer Anbieter
          (nachfolgend bezeichnet als "Drittanbieter") zu Zwecken der
          Organisation, Verwaltung, Planung sowie Erbringung unserer Leistungen
          ein. Bei der Auswahl der Drittanbieter und ihrer Leistungen beachten
          wir die gesetzlichen Vorgaben.{' '}
        </p>
        <p>
          In diesem Rahmen k&ouml;nnen personenbezogenen Daten verarbeitet und
          auf den Servern der Drittanbieter gespeichert werden. Hiervon
          k&ouml;nnen diverse Daten betroffen sein, die wir entsprechend dieser
          Datenschutzerkl&auml;rung verarbeiten. Zu diesen Daten k&ouml;nnen
          insbesondere Stammdaten und Kontaktdaten der Nutzer, Daten zu
          Vorg&auml;ngen, Vertr&auml;gen, sonstigen Prozessen und deren Inhalte
          geh&ouml;ren.
        </p>
        <p>
          Sofern Nutzer im Rahmen der Kommunikation, von Gesch&auml;fts- oder
          anderen Beziehungen mit uns auf die Drittanbieter bzw. deren Software
          oder Plattformen verwiesen werden, k&ouml;nnen die Drittanbieter
          Nutzungsdaten und Metadaten zu Sicherheitszwecken, zur
          Serviceoptimierung oder zu Marketingzwecken verarbeiten. Wir bitten
          daher darum, die Datenschutzhinweise der jeweiligen Drittanbieter zu
          beachten.
        </p>
        <ul className="m-elements">
          <li>
            <strong>Verarbeitete Datenarten:</strong> Inhaltsdaten (z. B.
            textliche oder bildliche Nachrichten und Beitr&auml;ge sowie die sie
            betreffenden Informationen, wie z. B. Angaben zur Autorenschaft oder
            Zeitpunkt der Erstellung); Nutzungsdaten (z. B. Seitenaufrufe und
            Verweildauer, Klickpfade, Nutzungsintensit&auml;t und -frequenz,
            verwendete Ger&auml;tetypen und Betriebssysteme, Interaktionen mit
            Inhalten und Funktionen). Meta-, Kommunikations- und Verfahrensdaten
            (z. B. IP-Adressen, Zeitangaben, Identifikationsnummern, beteiligte
            Personen).
          </li>
          <li>
            <strong>Betroffene Personen:</strong> Kommunikationspartner; Nutzer
            (z. B. Webseitenbesucher, Nutzer von Onlinediensten).
            Gesch&auml;fts- und Vertragspartner.
          </li>
          <li>
            <strong>Zwecke der Verarbeitung:</strong> Erbringung vertraglicher
            Leistungen und Erf&uuml;llung vertraglicher Pflichten; B&uuml;ro-
            und Organisationsverfahren; Bereitstellung unseres Onlineangebotes
            und Nutzerfreundlichkeit; Informationstechnische Infrastruktur
            (Betrieb und Bereitstellung von Informationssystemen und technischen
            Ger&auml;ten (Computer, Server etc.)). Sicherheitsma&szlig;nahmen.
          </li>
          <li>
            <strong>Aufbewahrung und L&ouml;schung:</strong> L&ouml;schung
            entsprechend Angaben im Abschnitt "Allgemeine Informationen zur
            Datenspeicherung und L&ouml;schung".
          </li>
          <li className="">
            <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6
            Abs. 1 S. 1 lit. f) DSGVO).
          </li>
        </ul>
        <p>
          <strong>
            Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:
          </strong>
        </p>
        <ul className="m-elements">
          <li>
            <strong>GitHub: </strong>Plattform f&uuml;r die Versionskontrolle
            von Softwareprojekten. Entwickler k&ouml;nnen ihren Code in
            Repositories hochladen und &Auml;nderungen verfolgen sowie Tools zur
            Projektverwaltung in der Softwareentwicklung nutzen;{' '}
            <strong>Dienstanbieter:</strong> GitHub B.V., Niederlande,{' '}
            <a
              href="https://support.github.com/contact/privacy"
              target="_blank"
              rel="noreferrer"
            >
              https://support.github.com/contact/privacy
            </a>
            ;{' '}
            <span className="">
              <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6
              Abs. 1 S. 1 lit. f) DSGVO);{' '}
            </span>
            <strong>Website:</strong>{' '}
            <a href="https://github.com" target="_blank" rel="noreferrer">
              https://github.com
            </a>
            . <strong>Datenschutzerkl&auml;rung:</strong>{' '}
            <a
              href="https://docs.github.com/de/site-policy/privacy-policies/github-general-privacy-statement"
              target="_blank"
              rel="noreferrer"
            >
              https://docs.github.com/de/site-policy/privacy-policies/github-general-privacy-statement
            </a>
            .
          </li>
          <li>
            <strong>GitLab: </strong>GitLab bietet eine umfassende L&ouml;sung
            f&uuml;r die Softwareentwicklung mit Funktionen wie
            Repository-Verwaltung, um Code zentral zu speichern und zu
            versionieren. Continuous Integration/Continuous Deployment (CI/CD)
            automatisiert den Prozess des Testens und Bereitstellens von Code.
            Mit Code-Review und Zusammenarbeit k&ouml;nnen Teams &Auml;nderungen
            &uuml;berpr&uuml;fen und effizient am Quellcode arbeiten.
            Issue-Tracking erm&ouml;glicht die Verwaltung von Aufgaben und
            Fehlern. Zudem bietet GitLab Projektmanagement-Tools zur Planung und
            Organisation von Projekten. Sicherheits- und Compliance-Funktionen
            sorgen f&uuml;r den Schutz des Codes, w&auml;hrend Monitoring und
            Fehlerverfolgung die &Uuml;berwachung und schnelle Problemerkennung
            unterst&uuml;tzen; <strong>Dienstanbieter:</strong> GitLab B.V.,
            Eduard van Beinumstraat 28, 1077 CZ Amsterdam, Niederlande;{' '}
            <span className="">
              <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6
              Abs. 1 S. 1 lit. f) DSGVO);{' '}
            </span>
            <strong>Website:</strong>{' '}
            <a
              href="https://about.gitlab.com/"
              target="_blank"
              rel="noreferrer"
            >
              https://about.gitlab.com/
            </a>
            ; <strong>Datenschutzerkl&auml;rung:</strong>{' '}
            <a
              href="https://about.gitlab.com/privacy/"
              target="_blank"
              rel="noreferrer"
            >
              https://about.gitlab.com/privacy/
            </a>
            ; <strong>Auftragsverarbeitungsvertrag:</strong>{' '}
            <a
              href="https://handbook.gitlab.com/handbook/legal/data-processing-agreement/"
              target="_blank"
              rel="noreferrer"
            >
              https://handbook.gitlab.com/handbook/legal/data-processing-agreement/
            </a>{' '}
            Wird vom Dienstanbieter bereitgestellt.{' '}
            <strong>Grundlage Drittlandtransfers:</strong> Data Privacy
            Framework (DPF), Standardvertragsklauseln (
            <a
              href="https://handbook.gitlab.com/handbook/legal/data-processing-agreement/"
              target="_blank"
              rel="noreferrer"
            >
              https://handbook.gitlab.com/handbook/legal/data-processing-agreement/
            </a>
            ), Data Privacy Framework (DPF)Standardvertragsklauseln (
            <a
              href="https://handbook.gitlab.com/handbook/legal/data-processing-agreement/"
              target="_blank"
              rel="noreferrer"
            >
              https://handbook.gitlab.com/handbook/legal/data-processing-agreement/
            </a>
            ).
          </li>
        </ul>
        <h2 id="m15">&Auml;nderung und Aktualisierung</h2>
        <p>
          Wir bitten Sie, sich regelm&auml;&szlig;ig &uuml;ber den Inhalt
          unserer Datenschutzerkl&auml;rung zu informieren. Wir passen die
          Datenschutzerkl&auml;rung an, sobald die &Auml;nderungen der von uns
          durchgef&uuml;hrten Datenverarbeitungen dies erforderlich machen. Wir
          informieren Sie, sobald durch die &Auml;nderungen eine
          Mitwirkungshandlung Ihrerseits (z. B. Einwilligung) oder eine sonstige
          individuelle Benachrichtigung erforderlich wird.
        </p>
        <p>
          Sofern wir in dieser Datenschutzerkl&auml;rung Adressen und
          Kontaktinformationen von Unternehmen und Organisationen angeben,
          bitten wir zu beachten, dass die Adressen sich &uuml;ber die Zeit
          &auml;ndern k&ouml;nnen und bitten die Angaben vor Kontaktaufnahme zu
          pr&uuml;fen.
        </p>
        <h2 id="m42">Begriffsdefinitionen</h2>
        <p>
          In diesem Abschnitt erhalten Sie eine &Uuml;bersicht &uuml;ber die in
          dieser Datenschutzerkl&auml;rung verwendeten Begrifflichkeiten. Soweit
          die Begrifflichkeiten gesetzlich definiert sind, gelten deren
          gesetzliche Definitionen. Die nachfolgenden Erl&auml;uterungen sollen
          dagegen vor allem dem Verst&auml;ndnis dienen.
        </p>
        <ul className="glossary">
          <li>
            <strong>Besch&auml;ftigte:</strong> Als Besch&auml;ftigte werden
            Personen bezeichnet, die in einem Besch&auml;ftigungsverh&auml;ltnis
            stehen, sei es als Mitarbeiter, Angestellte oder in &auml;hnlichen
            Positionen. Ein Besch&auml;ftigungsverh&auml;ltnis ist eine
            rechtliche Beziehung zwischen einem Arbeitgeber und einem
            Arbeitnehmer, die durch einen Arbeitsvertrag oder eine Vereinbarung
            festgelegt wird. Es beinhaltet die Verpflichtung des Arbeitgebers,
            dem Arbeitnehmer eine Verg&uuml;tung zu zahlen, w&auml;hrend der
            Arbeitnehmer seine Arbeitsleistung erbringt. Das
            Besch&auml;ftigungsverh&auml;ltnis umfasst verschiedene Phasen,
            darunter die Begr&uuml;ndung, in der der Arbeitsvertrag geschlossen
            wird, die Durchf&uuml;hrung, in der der Arbeitnehmer seine
            Arbeitst&auml;tigkeit aus&uuml;bt, und die Beendigung, wenn das
            Besch&auml;ftigungsverh&auml;ltnis endet, sei es durch
            K&uuml;ndigung, Aufhebungsvertrag oder anderweitig.
            Besch&auml;ftigtendaten sind alle Informationen, die sich auf diese
            Personen beziehen und im Kontext ihrer Besch&auml;ftigung stehen.
            Dies umfasst Aspekte wie pers&ouml;nliche Identifikationsdaten,
            Identifikationsnummern, Gehalts- und Bankdaten, Arbeitszeiten,
            Urlaubsanspr&uuml;che, Gesundheitsdaten und Leistungsbeurteilungen.{' '}
          </li>
          <li>
            <strong>Bestandsdaten:</strong> Bestandsdaten umfassen wesentliche
            Informationen, die f&uuml;r die Identifikation und Verwaltung von
            Vertragspartnern, Benutzerkonten, Profilen und &auml;hnlichen
            Zuordnungen notwendig sind. Diese Daten k&ouml;nnen u.a.
            pers&ouml;nliche und demografische Angaben wie Namen,
            Kontaktinformationen (Adressen, Telefonnummern, E-Mail-Adressen),
            Geburtsdaten und spezifische Identifikatoren (Benutzer-IDs)
            beinhalten. Bestandsdaten bilden die Grundlage f&uuml;r jegliche
            formelle Interaktion zwischen Personen und Diensten, Einrichtungen
            oder Systemen, indem sie eine eindeutige Zuordnung und Kommunikation
            erm&ouml;glichen.{' '}
          </li>
          <li>
            <strong>Inhaltsdaten:</strong> Inhaltsdaten umfassen Informationen,
            die im Zuge der Erstellung, Bearbeitung und Ver&ouml;ffentlichung
            von Inhalten aller Art generiert werden. Diese Kategorie von Daten
            kann Texte, Bilder, Videos, Audiodateien und andere multimediale
            Inhalte einschlie&szlig;en, die auf verschiedenen Plattformen und
            Medien ver&ouml;ffentlicht werden. Inhaltsdaten sind nicht nur auf
            den eigentlichen Inhalt beschr&auml;nkt, sondern beinhalten auch
            Metadaten, die Informationen &uuml;ber den Inhalt selbst liefern,
            wie Tags, Beschreibungen, Autoreninformationen und
            Ver&ouml;ffentlichungsdaten{' '}
          </li>
          <li>
            <strong>Kontaktdaten:</strong> Kontaktdaten sind essentielle
            Informationen, die die Kommunikation mit Personen oder
            Organisationen erm&ouml;glichen. Sie umfassen u.a. Telefonnummern,
            postalische Adressen und E-Mail-Adressen, sowie Kommunikationsmittel
            wie soziale Medien-Handles und Instant-Messaging-Identifikatoren.{' '}
          </li>
          <li>
            <strong>Meta-, Kommunikations- und Verfahrensdaten:</strong> Meta-,
            Kommunikations- und Verfahrensdaten sind Kategorien, die
            Informationen &uuml;ber die Art und Weise enthalten, wie Daten
            verarbeitet, &uuml;bermittelt und verwaltet werden. Meta-Daten, auch
            bekannt als Daten &uuml;ber Daten, umfassen Informationen, die den
            Kontext, die Herkunft und die Struktur anderer Daten beschreiben.
            Sie k&ouml;nnen Angaben zur Dateigr&ouml;&szlig;e, dem
            Erstellungsdatum, dem Autor eines Dokuments und den
            &Auml;nderungshistorien beinhalten. Kommunikationsdaten erfassen den
            Austausch von Informationen zwischen Nutzern &uuml;ber verschiedene
            Kan&auml;le, wie E-Mail-Verkehr, Anrufprotokolle, Nachrichten in
            sozialen Netzwerken und Chat-Verl&auml;ufe, inklusive der
            beteiligten Personen, Zeitstempel und &Uuml;bertragungswege.
            Verfahrensdaten beschreiben die Prozesse und Abl&auml;ufe innerhalb
            von Systemen oder Organisationen, einschlie&szlig;lich
            Workflow-Dokumentationen, Protokolle von Transaktionen und
            Aktivit&auml;ten, sowie Audit-Logs, die zur Nachverfolgung und
            &Uuml;berpr&uuml;fung von Vorg&auml;ngen verwendet werden.{' '}
          </li>
          <li>
            <strong>Nutzungsdaten:</strong> Nutzungsdaten beziehen sich auf
            Informationen, die erfassen, wie Nutzer mit digitalen Produkten,
            Dienstleistungen oder Plattformen interagieren. Diese Daten umfassen
            eine breite Palette von Informationen, die aufzeigen, wie Nutzer
            Anwendungen nutzen, welche Funktionen sie bevorzugen, wie lange sie
            auf bestimmten Seiten verweilen und &uuml;ber welche Pfade sie durch
            eine Anwendung navigieren. Nutzungsdaten k&ouml;nnen auch die
            H&auml;ufigkeit der Nutzung, Zeitstempel von Aktivit&auml;ten,
            IP-Adressen, Ger&auml;teinformationen und Standortdaten
            einschlie&szlig;en. Sie sind besonders wertvoll f&uuml;r die Analyse
            des Nutzerverhaltens, die Optimierung von Benutzererfahrungen, das
            Personalisieren von Inhalten und das Verbessern von Produkten oder
            Dienstleistungen. Dar&uuml;ber hinaus spielen Nutzungsdaten eine
            entscheidende Rolle beim Erkennen von Trends, Vorlieben und
            m&ouml;glichen Problembereichen innerhalb digitaler Angebote{' '}
          </li>
          <li>
            <strong>Personenbezogene Daten:</strong> "Personenbezogene Daten"
            sind alle Informationen, die sich auf eine identifizierte oder
            identifizierbare nat&uuml;rliche Person (im Folgenden "betroffene
            Person") beziehen; als identifizierbar wird eine nat&uuml;rliche
            Person angesehen, die direkt oder indirekt, insbesondere mittels
            Zuordnung zu einer Kennung wie einem Namen, zu einer Kennnummer, zu
            Standortdaten, zu einer Online-Kennung (z. B. Cookie) oder zu einem
            oder mehreren besonderen Merkmalen identifiziert werden kann, die
            Ausdruck der physischen, physiologischen, genetischen, psychischen,
            wirtschaftlichen, kulturellen oder sozialen Identit&auml;t dieser
            nat&uuml;rlichen Person sind.{' '}
          </li>
          <li>
            <strong>Protokolldaten:</strong> Protokolldaten sind Informationen
            &uuml;ber Ereignisse oder Aktivit&auml;ten, die in einem System oder
            Netzwerk protokolliert wurden. Diese Daten enthalten typischerweise
            Informationen wie Zeitstempel, IP-Adressen, Benutzeraktionen,
            Fehlermeldungen und andere Details &uuml;ber die Nutzung oder den
            Betrieb eines Systems. Protokolldaten werden oft zur Analyse von
            Systemproblemen, zur Sicherheits&uuml;berwachung oder zur Erstellung
            von Leistungsberichten verwendet.{' '}
          </li>
          <li>
            <strong>Verantwortlicher:</strong> Als "Verantwortlicher" wird die
            nat&uuml;rliche oder juristische Person, Beh&ouml;rde, Einrichtung
            oder andere Stelle, die allein oder gemeinsam mit anderen &uuml;ber
            die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten
            entscheidet, bezeichnet.{' '}
          </li>
          <li>
            <strong>Verarbeitung:</strong> "Verarbeitung" ist jeder mit oder
            ohne Hilfe automatisierter Verfahren ausgef&uuml;hrte Vorgang oder
            jede solche Vorgangsreihe im Zusammenhang mit personenbezogenen
            Daten. Der Begriff reicht weit und umfasst praktisch jeden Umgang
            mit Daten, sei es das Erheben, das Auswerten, das Speichern, das
            &Uuml;bermitteln oder das L&ouml;schen.{' '}
          </li>
          <li>
            <strong>Vertragsdaten:</strong> Vertragsdaten sind spezifische
            Informationen, die sich auf die Formalisierung einer Vereinbarung
            zwischen zwei oder mehr Parteien beziehen. Sie dokumentieren die
            Bedingungen, unter denen Dienstleistungen oder Produkte
            bereitgestellt, getauscht oder verkauft werden. Diese Datenkategorie
            ist wesentlich f&uuml;r die Verwaltung und Erf&uuml;llung
            vertraglicher Verpflichtungen und umfasst sowohl die Identifikation
            der Vertragsparteien als auch die spezifischen Bedingungen und
            Konditionen der Vereinbarung. Vertragsdaten k&ouml;nnen Start- und
            Enddaten des Vertrages, die Art der vereinbarten Leistungen oder
            Produkte, Preisvereinbarungen, Zahlungsbedingungen,
            K&uuml;ndigungsrechte, Verl&auml;ngerungsoptionen und spezielle
            Bedingungen oder Klauseln umfassen. Sie dienen als rechtliche
            Grundlage f&uuml;r die Beziehung zwischen den Parteien und sind
            entscheidend f&uuml;r die Kl&auml;rung von Rechten und Pflichten,
            die Durchsetzung von Anspr&uuml;chen und die L&ouml;sung von
            Streitigkeiten.{' '}
          </li>
          <li>
            <strong>Zahlungsdaten:</strong> Zahlungsdaten umfassen
            s&auml;mtliche Informationen, die zur Abwicklung von
            Zahlungstransaktionen zwischen K&auml;ufern und Verk&auml;ufern
            ben&ouml;tigt werden. Diese Daten sind von entscheidender Bedeutung
            f&uuml;r den elektronischen Handel, das Online-Banking und jede
            andere Form der finanziellen Transaktion. Sie beinhalten Details wie
            Kreditkartennummern, Bankverbindungen, Zahlungsbetr&auml;ge,
            Transaktionsdaten, Verifizierungsnummern und Rechnungsinformationen.
            Zahlungsdaten k&ouml;nnen auch Informationen &uuml;ber den
            Zahlungsstatus, R&uuml;ckbuchungen, Autorisierungen und
            Geb&uuml;hren enthalten.{' '}
          </li>
        </ul>
      </div>
      <div id="privacy_policy_en">
        <h1>Privacy Policy</h1>
        <h2 id="m716">Preamble</h2>
        <p>
          With the following privacy policy we would like to inform you which
          types of your personal data (hereinafter also abbreviated as "data")
          we process for which purposes and in which scope. The privacy
          statement applies to all processing of personal data carried out by
          us, both in the context of providing our services and in particular on
          our websites, in mobile applications and within external online
          presences, such as our social media profiles (hereinafter collectively
          referred to as "online services").
        </p>
        <p>The terms used are not gender-specific.</p>
        <p>Last Update: 6. April 2025</p>
        <h2>Table of contents</h2>{' '}
        <ul className="index">
          <li>
            <a className="index-link" href="#m716">
              Preamble
            </a>
          </li>
          <li>
            <a className="index-link" href="#m3">
              Controller
            </a>
          </li>
          <li>
            <a className="index-link" href="#mOverview">
              Overview of processing operations
            </a>
          </li>
          <li>
            <a className="index-link" href="#m2427">
              Relevant legal bases
            </a>
          </li>
          <li>
            <a className="index-link" href="#m27">
              Security Precautions
            </a>
          </li>
          <li>
            <a className="index-link" href="#m25">
              Transmission of Personal Data
            </a>
          </li>
          <li>
            <a className="index-link" href="#m24">
              International data transfers
            </a>
          </li>
          <li>
            <a className="index-link" href="#m12">
              General Information on Data Retention and Deletion
            </a>
          </li>
          <li>
            <a className="index-link" href="#m10">
              Rights of Data Subjects
            </a>
          </li>
          <li>
            <a className="index-link" href="#m13">
              Business processes and operations
            </a>
          </li>
          <li>
            <a className="index-link" href="#m3575">
              Providers and services used in the course of business
            </a>
          </li>
          <li>
            <a className="index-link" href="#m326">
              Payment Procedure
            </a>
          </li>
          <li>
            <a className="index-link" href="#m225">
              Provision of online services and web hosting
            </a>
          </li>
          <li>
            <a className="index-link" href="#m367">
              Registration, Login and User Account
            </a>
          </li>
          <li>
            <a className="index-link" href="#m182">
              Contact and Inquiry Management
            </a>
          </li>
          <li>
            <a className="index-link" href="#m391">
              Communication via Messenger
            </a>
          </li>
          <li>
            <a className="index-link" href="#m735">
              Video Conferences, Online Meetings, Webinars and Screen-Sharing
            </a>
          </li>
          <li>
            <a className="index-link" href="#m29">
              Cloud Services
            </a>
          </li>
          <li>
            <a className="index-link" href="#m17">
              Newsletter and Electronic Communications
            </a>
          </li>
          <li>
            <a className="index-link" href="#m136">
              Profiles in Social Networks (Social Media)
            </a>
          </li>
          <li>
            <a className="index-link" href="#m723">
              Management, Organization and Utilities
            </a>
          </li>
          <li>
            <a className="index-link" href="#m15">
              Changes and Updates
            </a>
          </li>
          <li>
            <a className="index-link" href="#m42">
              Terminology and Definitions
            </a>
          </li>
        </ul>
        <h2 id="m3">Controller</h2>
        <p>
          SoCraTes Deutschland e.V.
          <br />
          Am Hirschanger 1<br />
          90610 Winkelhaid
        </p>
        <p>E-mail address: info@socrates-conference.de</p>
        <p>Phone: +499187 7061771</p>
        <h2 id="mOverview">Overview of processing operations</h2>
        <p>
          The following table summarises the types of data processed, the
          purposes for which they are processed and the concerned data subjects.
        </p>
        <h3>Categories of Processed Data</h3>
        <ul>
          <li>Inventory data.</li>
          <li>Payment Data.</li>
          <li>Contact data.</li>
          <li>Content data.</li>
          <li>Contract data.</li>
          <li>Usage data.</li>
          <li>Meta, communication and process data.</li>
          <li>Images and/ or video recordings.</li>
          <li>Audio recordings.</li>
          <li>Log data.</li>
        </ul>
        <h3>Categories of Data Subjects</h3>
        <ul>
          <li>Service recipients and clients.</li>
          <li>Employees.</li>
          <li>Prospective customers.</li>
          <li>Communication partner.</li>
          <li>Users.</li>
          <li>Members.</li>
          <li>Business and contractual partners.</li>
          <li>Persons depicted.</li>
          <li>Third parties.</li>
          <li>Customers.</li>
        </ul>
        <h3>Purposes of Processing</h3>
        <ul>
          <li>
            Provision of contractual services and fulfillment of contractual
            obligations.
          </li>
          <li>Communication.</li>
          <li>Security measures.</li>
          <li>Direct marketing.</li>
          <li>Office and organisational procedures.</li>
          <li>Organisational and Administrative Procedures.</li>
          <li>Feedback.</li>
          <li>Marketing.</li>
          <li>Provision of our online services and usability.</li>
          <li>Information technology infrastructure.</li>
          <li>Public relations and informational purposes.</li>
          <li>Financial and Payment Management.</li>
          <li>Public relations.</li>
          <li>Sales promotion.</li>
          <li>Business processes and management procedures.</li>
        </ul>
        <h2 id="m2427">Relevant legal bases</h2>
        <p>
          <strong>Relevant legal bases according to the GDPR: </strong>In the
          following, you will find an overview of the legal basis of the GDPR on
          which we base the processing of personal data. Please note that in
          addition to the provisions of the GDPR, national data protection
          provisions of your or our country of residence or domicile may apply.
          If, in addition, more specific legal bases are applicable in
          individual cases, we will inform you of these in the data protection
          declaration.
        </p>
        <ul>
          <li>
            <strong>Consent (Article 6 (1) (a) GDPR)</strong> - The data subject
            has given consent to the processing of his or her personal data for
            one or more specific purposes.
          </li>
          <li>
            <strong>
              Performance of a contract and prior requests (Article 6 (1) (b)
              GDPR)
            </strong>{' '}
            - Performance of a contract to which the data subject is party or in
            order to take steps at the request of the data subject prior to
            entering into a contract.
          </li>
          <li>
            <strong>
              Compliance with a legal obligation (Article 6 (1) (c) GDPR)
            </strong>{' '}
            - Processing is necessary for compliance with a legal obligation to
            which the controller is subject.
          </li>
          <li>
            <strong>Legitimate Interests (Article 6 (1) (f) GDPR)</strong> - the
            processing is necessary for the protection of the legitimate
            interests of the controller or a third party, provided that the
            interests, fundamental rights, and freedoms of the data subject,
            which require the protection of personal data, do not prevail.
          </li>
        </ul>
        <p>
          <strong>National data protection regulations in Germany: </strong>In
          addition to the data protection regulations of the GDPR, national
          regulations apply to data protection in Germany. This includes in
          particular the Law on Protection against Misuse of Personal Data in
          Data Processing (Federal Data Protection Act - BDSG). In particular,
          the BDSG contains special provisions on the right to access, the right
          to erase, the right to object, the processing of special categories of
          personal data, processing for other purposes and transmission as well
          as automated individual decision-making, including profiling.
          Furthermore, data protection laws of the individual federal states may
          apply.
        </p>
        <p>
          <strong>
            Reference to the applicability of the GDPR and the Swiss DPA:{' '}
          </strong>
          These privacy policy serves both to provide information pursuant to
          the Swiss Federal Act on Data Protection (FADP) and the General Data
          Protection Regulation (GDPR). For this reason, we ask you to note that
          due to the broader spatial application and comprehensibility, the
          terms used in the GDPR are applied. In particular, instead of the
          terms used in the Swiss FADP such as "processing" of "personal data",
          "predominant interest", and "particularly sensitive personal data",
          the terms used in the GDPR, namely "processing" of "personal data", as
          well as "legitimate interest" and "special categories of data" are
          used. However, the legal meaning of these terms will continue to be
          determined according to the Swiss FADP within its scope of
          application.
        </p>
        <h2 id="m27">Security Precautions</h2>
        <p>
          We take appropriate technical and organisational measures in
          accordance with the legal requirements, taking into account the state
          of the art, the costs of implementation and the nature, scope, context
          and purposes of processing as well as the risk of varying likelihood
          and severity for the rights and freedoms of natural persons, in order
          to ensure a level of security appropriate to the risk.
        </p>
        <p>
          The measures include, in particular, safeguarding the confidentiality,
          integrity and availability of data by controlling physical and
          electronic access to the data as well as access to, input,
          transmission, securing and separation of the data. In addition, we
          have established procedures to ensure that data subjects' rights are
          respected, that data is erased, and that we are prepared to respond to
          data threats rapidly. Furthermore, we take the protection of personal
          data into account as early as the development or selection of
          hardware, software and service providers, in accordance with the
          principle of privacy by design and privacy by default.
        </p>
        <p>
          Securing online connections through TLS/SSL encryption technology
          (HTTPS): To protect the data of users transmitted via our online
          services from unauthorized access, we employ TLS/SSL encryption
          technology. Secure Sockets Layer (SSL) and Transport Layer Security
          (TLS) are the cornerstones of secure data transmission on the
          internet. These technologies encrypt the information that is
          transferred between the website or app and the user's browser (or
          between two servers), thereby safeguarding the data from unauthorized
          access. TLS, as the more advanced and secure version of SSL, ensures
          that all data transmissions conform to the highest security standards.
          When a website is secured with an SSL/TLS certificate, this is
          indicated by the display of HTTPS in the URL. This serves as an
          indicator to users that their data is being securely and encryptedly
          transmitted.
        </p>
        <h2 id="m25">Transmission of Personal Data</h2>
        <p>
          In the course of processing personal data, it may happen that this
          data is transmitted to or disclosed to other entities, companies,
          legally independent organizational units, or individuals. Recipients
          of this data may include service providers tasked with IT duties or
          providers of services and content that are integrated into a website.
          In such cases, we observe the legal requirements and particularly
          conclude relevant contracts or agreements that serve to protect your
          data with the recipients of your data.
        </p>
        <h2 id="m24">International data transfers</h2>
        <p>
          Data Processing in Third Countries: If we transfer data to a third
          country (i.e., outside the European Union (EU) or the European
          Economic Area (EEA)), or if this occurs in the context of using
          third-party services or the disclosure or transfer of data to other
          individuals, entities, or companies (which becomes apparent either
          from the postal address of the respective provider or when explicitly
          mentioned in the privacy policy regarding data transfer to third
          countries), this is always done in accordance with legal requirements.
        </p>
        <p>
          For data transfers to the USA, we primarily rely on the Data Privacy
          Framework (DPF), which has been recognized as a secure legal framework
          by the EU Commission's adequacy decision of July 10, 2023.
          Additionally, we have concluded Standard Contractual Clauses with the
          respective providers, which comply with the EU Commission's
          requirements and establish contractual obligations to protect your
          data.
        </p>
        <p>
          This dual safeguard ensures comprehensive protection of your data: The
          DPF serves as the primary level of protection, while the Standard
          Contractual Clauses act as an additional security measure. Should any
          changes occur within the DPF framework, the Standard Contractual
          Clauses will serve as a reliable fallback option. This ensures that
          your data remains adequately protected even in the event of political
          or legal changes.
        </p>
        <p>
          For individual service providers, we will inform you whether they are
          certified under the DPF and if Standard Contractual Clauses are in
          place. The list of certified companies and further information about
          the DPF can be found on the U.S. Department of Commerce's website at{' '}
          <a
            href="https://www.dataprivacyframework.gov/"
            target="_blank"
            rel="noreferrer"
          >
            https://www.dataprivacyframework.gov/.
          </a>
        </p>
        <p>
          For data transfers to other third countries, appropriate safeguards
          apply, particularly Standard Contractual Clauses, explicit consent, or
          legally required transfers. Information on third-country transfers and
          applicable adequacy decisions can be found in the information provided
          by the EU Commission:{' '}
          <a
            href="https://commission.europa.eu/law/law-topic/data-protection/international-dimension-data-protection_en"
            target="_blank"
            rel="noreferrer"
          >
            https://commission.europa.eu/law/law-topic/data-protection/international-dimension-data-protection_en.
          </a>
        </p>
        <p>
          We will inform you which of our service providers are certified under
          the Data Privacy Framework as part of our data protection notices.
        </p>
        <h2 id="m12">General Information on Data Retention and Deletion</h2>
        <p>
          We delete personal data that we process in accordance with legal
          regulations as soon as the underlying consents are revoked or no
          further legal bases for processing exist. This applies to cases where
          the original purpose of processing is no longer applicable or the data
          is no longer needed. Exceptions to this rule exist if statutory
          obligations or special interests require a longer retention or
          archiving of the data.
        </p>
        <p>
          In particular, data that must be retained for commercial or tax law
          reasons, or whose storage is necessary for legal prosecution or
          protection of the rights of other natural or legal persons, must be
          archived accordingly.
        </p>
        <p>
          Our privacy notices contain additional information on the retention
          and deletion of data specifically applicable to certain processing
          processes.
        </p>
        <p>
          In cases where multiple retention periods or deletion deadlines for a
          date are specified, the longest period always prevails.
        </p>
        <p>
          If a period does not expressly start on a specific date and lasts at
          least one year, it automatically begins at the end of the calendar
          year in which the event triggering the period occurred. In the case of
          ongoing contractual relationships in the context of which data is
          stored, the event triggering the deadline is the time at which the
          termination or other termination of the legal relationship takes
          effect.
        </p>
        <p>
          Data that is no longer stored for its originally intended purpose but
          due to legal requirements or other reasons are processed exclusively
          for the reasons justifying their retention.
        </p>
        <p>
          <strong>
            Further information on processing methods, procedures and services
            used:
          </strong>
        </p>
        <ul className="m-elements">
          <li>
            <strong>Data Retention and Deletion: </strong>The following general
            deadlines apply for the retention and archiving according to German
            law:
            <ul>
              {' '}
              <li>
                10 Years - Fiscal Code/Commercial Code - Retention period for
                books and records, annual financial statements, inventories,
                management reports, opening balance sheet as well as the
                necessary work instructions and other organisational documents
                (Section 147 Paragraph 1 No. 1 in conjunction with Paragraph 3
                of the German General Tax Code (AO), Section 14b Paragraph 1 of
                the German VAT Act (UStG), Section 257 Paragraph 1 No. 1 in
                conjunction with Paragraph 4 of the German Commercial Code
                (HGB)).
              </li>
              <li>
                8 years - Accounting documents, such as invoices, booking and
                expense receipts (Section 147 Paragraph 1 No. 4 and 4a in
                conjunction with Paragraph 3 of the German General Tax Code
                (AO), Section 257 Paragraph 1 No. 4 in conjunction with
                Paragraph 4 of the German Commercial Code (HGB))
              </li>
              <li>
                6 Years - Other business documents: received commercial or
                business letters, copies of dispatched commercial or business
                letters, and other documents to the extent that they are
                significant for taxation purposes, for example, hourly wage
                slips, operating accounting sheets, calculation documents, price
                tags, as well as payroll accounting documents, provided they are
                not already accounting vouchers and cash register tapes Section
                (Section 147 Paragraph 1 No. 2, 3, 5 in conjunction with
                Paragraph 3 of the German General Tax Code (AO), Section 257
                Paragraph 1 No. 2 and 3 in conjunction with Paragraph 4 of the
                German Commercial Code (HGB)).
              </li>
              <li>
                3 Years - Data required to consider potential warranty and
                compensation claims or similar contractual claims and rights, as
                well as to process related inquiries, based on previous business
                experiences and common industry practices, will be stored for
                the duration of the regular statutory limitation period of three
                years. This period begins at the end of the year in which the
                relevant contractual transaction took place or the contractual
                relationship ended in the case of ongoing contracts (Sections
                195, 199 of the German Civil Code).
              </li>{' '}
            </ul>
          </li>
        </ul>
        <h2 id="m10">Rights of Data Subjects</h2>
        <p>
          Rights of the Data Subjects under the GDPR: As data subject, you are
          entitled to various rights under the GDPR, which arise in particular
          from Articles 15 to 21 of the GDPR:
        </p>
        <ul>
          <li>
            <strong>
              Right to Object: You have the right, on grounds arising from your
              particular situation, to object at any time to the processing of
              your personal data which is based on letter (e) or (f) of Article
              6(1) GDPR, including profiling based on those provisions. Where
              personal data are processed for direct marketing purposes, you
              have the right to object at any time to the processing of the
              personal data concerning you for the purpose of such marketing,
              which includes profiling to the extent that it is related to such
              direct marketing.
            </strong>
          </li>
          <li>
            <strong>Right of withdrawal for consents:</strong> You have the
            right to revoke consents at any time.
          </li>
          <li>
            <strong>Right of access:</strong> You have the right to request
            confirmation as to whether the data in question will be processed
            and to be informed of this data and to receive further information
            and a copy of the data in accordance with the provisions of the law.
          </li>
          <li>
            <strong>Right to rectification:</strong> You have the right, in
            accordance with the law, to request the completion of the data
            concerning you or the rectification of the incorrect data concerning
            you.
          </li>
          <li>
            <strong>
              Right to Erasure and Right to Restriction of Processing:
            </strong>{' '}
            In accordance with the statutory provisions, you have the right to
            demand that the relevant data be erased immediately or,
            alternatively, to demand that the processing of the data be
            restricted in accordance with the statutory provisions.
          </li>
          <li>
            <strong>Right to data portability:</strong> You have the right to
            receive data concerning you which you have provided to us in a
            structured, common and machine-readable format in accordance with
            the legal requirements, or to request its transmission to another
            controller.
          </li>
          <li>
            <strong>Complaint to the supervisory authority:</strong> In
            accordance with the law and without prejudice to any other
            administrative or judicial remedy, you also have the right to lodge
            a complaint with a data protection supervisory authority, in
            particular a supervisory authority in the Member State where you
            habitually reside, the supervisory authority of your place of work
            or the place of the alleged infringement, if you consider that the
            processing of personal data concerning you infringes the GDPR.
          </li>
        </ul>
        <h2 id="m13">Business processes and operations</h2>
        <p>
          Personal data of service recipients and clients - including customers,
          clients, or in specific cases, mandates, patients, or business
          partners as well as other third parties - are processed within the
          framework of contractual and comparable legal relationships and
          pre-contractual measures such as the initiation of business relations.
          This data processing supports and facilitates business processes in
          areas such as customer management, sales, payment transactions,
          accounting, and project management.
        </p>
        <p>
          The collected data is used to fulfil contractual obligations and make
          business processes efficient. This includes the execution of business
          transactions, the management of customer relationships, the
          optimisation of sales strategies, and ensuring internal invoicing and
          financial processes. Additionally, the data supports the protection of
          the rights of the controller and promotes administrative tasks as well
          as the organisation of the company.
        </p>
        <p>
          Personal data may be transferred to third parties if necessary for
          fulfilling the mentioned purposes or legal obligations. After legal
          retention periods expire or when the purpose of processing no longer
          applies, the data will be deleted. This also includes data that must
          be stored for longer periods due to tax law and legal obligations to
          provide evidence.
        </p>
        <ul className="m-elements">
          <li>
            <strong>Processed data types:</strong> Inventory data (For example,
            the full name, residential address, contact information, customer
            number, etc.); Payment Data (e.g. bank details, invoices, payment
            history); Contact data (e.g. postal and email addresses or phone
            numbers); Content data (e.g. textual or pictorial messages and
            contributions, as well as information pertaining to them, such as
            details of authorship or the time of creation.); Contract data (e.g.
            contract object, duration, customer category); Usage data (e.g. page
            views and duration of visit, click paths, intensity and frequency of
            use, types of devices and operating systems used, interactions with
            content and features); Meta, communication and process data (e.g. IP
            addresses, timestamps, identification numbers, involved parties).
            Log data (e.g. log files concerning logins or data retrieval or
            access times.).
          </li>
          <li>
            <strong>Data subjects:</strong> Service recipients and clients;
            Prospective customers; Communication partner (Recipients of e-mails,
            letters, etc.); Business and contractual partners; Customers; Third
            parties; Users (e.g. website visitors, users of online services).
            Employees (e.g. employees, job applicants, temporary workers, and
            other personnel.).
          </li>
          <li>
            <strong>Purposes of processing:</strong> Provision of contractual
            services and fulfillment of contractual obligations; Office and
            organisational procedures; Business processes and management
            procedures; Security measures; Provision of our online services and
            usability; Communication; Marketing; Sales promotion; Public
            relations; Financial and Payment Management. Information technology
            infrastructure (Operation and provision of information systems and
            technical devices, such as computers, servers, etc.)).
          </li>
          <li>
            <strong>Retention and deletion:</strong> Deletion in accordance with
            the information provided in the section "General Information on Data
            Retention and Deletion".
          </li>
          <li className="">
            <strong>Legal Basis:</strong> Performance of a contract and prior
            requests (Article 6 (1) (b) GDPR); Legitimate Interests (Article 6
            (1) (f) GDPR). Compliance with a legal obligation (Article 6 (1) (c)
            GDPR).
          </li>
        </ul>
        <p>
          <strong>
            Further information on processing methods, procedures and services
            used:
          </strong>
        </p>
        <ul className="m-elements">
          <li>
            <strong>
              Customer Management and Customer Relationship Management (CRM):{' '}
            </strong>
            Processes required in the context of customer management and
            Customer Relationship Management (CRM) include customer acquisition
            in compliance with data protection regulations, measures to promote
            customer retention and loyalty, effective customer communication,
            complaint management and customer service with consideration of data
            protection, data management and analysis to support the customer
            relationship, management of CRM systems, secure account management,
            customer segmentation and targeting;{' '}
            <span className="">
              <strong>Legal Basis:</strong> Performance of a contract and prior
              requests (Article 6 (1) (b) GDPR), Legitimate Interests (Article 6
              (1) (f) GDPR).
            </span>
          </li>
          <li>
            <strong>Contact management and contact maintenance: </strong>
            Processes required in the context of organizing, maintaining, and
            securing contact information (e.g., setting up and maintaining a
            central contact database, regular updates of contact information,
            monitoring data integrity, implementing data protection measures,
            ensuring access controls, conducting backups and restorations of
            contact data, training employees in effective use of contact
            management software, regular review of communication history and
            adjustment of contact strategies);{' '}
            <span className="">
              <strong>Legal Basis:</strong> Performance of a contract and prior
              requests (Article 6 (1) (b) GDPR), Legitimate Interests (Article 6
              (1) (f) GDPR).
            </span>
          </li>
          <li>
            <strong>Customer Account: </strong>Customers can create an account
            within our online offer (e.g. customer or user account, "customer
            account" for short). If the registration of a customer account is
            required, customers will be informed of this as well as of the
            details required for registration. The customer accounts are not
            public and cannot be indexed by search engines. In the course of
            registration and subsequent registration and use of the customer
            account, we store the IP addresses of the contractual partners along
            with the access times, in order to be able to prove the registration
            and prevent any misuse of the customer account. If the customer
            account has been terminated, the customer account data will be
            deleted after the termination date, unless it is retained for
            purposes other than provision in the customer account or must be
            retained for legal reasons (e.g. internal storage of customer data,
            order transactions or invoices). It is the customers' responsibility
            to back up their data when terminating the customer Account;{' '}
            <span className="">
              <strong>Legal Basis:</strong> Performance of a contract and prior
              requests (Article 6 (1) (b) GDPR), Legitimate Interests (Article 6
              (1) (f) GDPR).
            </span>
          </li>
          <li>
            <strong>General Payment Transactions: </strong>Procedures required
            for carrying out payment transactions, monitoring bank accounts, and
            controlling payment flows (e.g., creation and verification of
            transfers, processing of direct debit transactions, checking of
            account statements, monitoring of incoming and outgoing payments,
            management of chargebacks, account reconciliation, cash management);{' '}
            <span className="">
              <strong>Legal Basis:</strong> Performance of a contract and prior
              requests (Article 6 (1) (b) GDPR), Legitimate Interests (Article 6
              (1) (f) GDPR).
            </span>
          </li>
          <li>
            <strong>Accounting, accounts payable, accounts receivable: </strong>
            Procedures required for the collection, processing, and control of
            business transactions in the area of accounts payable and receivable
            accounting (e.g., creation and verification of incoming and outgoing
            invoices, monitoring and management of outstanding items, execution
            of payment transactions, handling of dunning processes, account
            reconciliation within the scope of receivables and payables,
            accounts payable accounting, and accounts receivable accounting);{' '}
            <span className="">
              <strong>Legal Basis:</strong> Performance of a contract and prior
              requests (Article 6 (1) (b) GDPR), Compliance with a legal
              obligation (Article 6 (1) (c) GDPR), Legitimate Interests (Article
              6 (1) (f) GDPR).
            </span>
          </li>
          <li>
            <strong>Financial Accounting and Taxes: </strong>Procedures required
            for the collection, management, and control of finance-related
            business transactions as well as for the calculation, reporting, and
            payment of taxes (e.g., accounting and posting of business
            transactions, preparation of quarterly and annual financial
            statements, execution of payment transactions, handling of dunning
            processes, account reconciliation, tax consulting, preparation and
            submission of tax returns, management of tax affairs);{' '}
            <span className="">
              <strong>Legal Basis:</strong> Performance of a contract and prior
              requests (Article 6 (1) (b) GDPR), Compliance with a legal
              obligation (Article 6 (1) (c) GDPR), Legitimate Interests (Article
              6 (1) (f) GDPR).
            </span>
          </li>
          <li>
            <strong>Marketing, advertising, and sales promotion: </strong>
            Processes required in the context of marketing, advertising, and
            sales promotion (e.g., market analysis and audience targeting,
            development of marketing strategies, planning and execution of
            advertising campaigns, design and production of advertising
            materials, online marketing including SEO and social media
            campaigns, event marketing and trade show participation, customer
            loyalty programs, sales promotion measures, performance measurement
            and optimisation of marketing activities, budget management and cost
            control);{' '}
            <span className="">
              <strong>Legal Basis:</strong> Legitimate Interests (Article 6 (1)
              (f) GDPR).
            </span>
          </li>
          <li>
            <strong>Public Relations: </strong>Processes required in the context
            of public relations and public relations activities (e.g.,
            development and implementation of communication strategies, planning
            and execution of PR campaigns, creation and distribution of press
            releases, maintenance of media contacts, monitoring and analysis of
            media response, organisation of press conferences and public events,
            crisis communication, creation of content for social media and
            corporate websites, management of corporate branding);{' '}
            <span className="">
              <strong>Legal Basis:</strong> Legitimate Interests (Article 6 (1)
              (f) GDPR).
            </span>
          </li>
          <li>
            <strong>Discord: </strong>Chat, audio and video broadcasting,
            instant messaging, and community management;{' '}
            <strong>Service provider</strong>: Discord, Inc., 444 De Haro St,
            Suite 200, San Francisco, California 94107, USA;{' '}
            <span className="">
              <strong>Legal Basis:</strong> Legitimate Interests (Article 6 (1)
              (f) GDPR);{' '}
            </span>
            <strong>Website:</strong>{' '}
            <a href="https://discordapp.com/" target="_blank" rel="noreferrer">
              https://discordapp.com/
            </a>
            ; <strong>Privacy Policy:</strong>{' '}
            <a
              href="https://discordapp.com/privacy"
              target="_blank"
              rel="noreferrer"
            >
              https://discordapp.com/privacy
            </a>
            . <strong>Basis for third-country transfers:</strong> Data Privacy
            Framework (DPF), Data Privacy Framework (DPF).
          </li>
        </ul>
        <h2 id="m3575">
          Providers and services used in the course of business
        </h2>
        <p>
          As part of our business activities, we use additional services,
          platforms, interfaces or plug-ins from third-party providers (in
          short, "services") in compliance with legal requirements. Their use is
          based on our interests in the proper, legal and economic management of
          our business operations and internal organization.
        </p>
        <ul className="m-elements">
          <li>
            <strong>Processed data types:</strong> Inventory data (For example,
            the full name, residential address, contact information, customer
            number, etc.); Payment Data (e.g. bank details, invoices, payment
            history); Contact data (e.g. postal and email addresses or phone
            numbers); Content data (e.g. textual or pictorial messages and
            contributions, as well as information pertaining to them, such as
            details of authorship or the time of creation.). Contract data (e.g.
            contract object, duration, customer category).
          </li>
          <li>
            <strong>Data subjects:</strong> Service recipients and clients;
            Prospective customers; Business and contractual partners. Members.
          </li>
          <li>
            <strong>Purposes of processing:</strong> Provision of contractual
            services and fulfillment of contractual obligations; Office and
            organisational procedures. Business processes and management
            procedures.
          </li>
          <li>
            <strong>Retention and deletion:</strong> Deletion in accordance with
            the information provided in the section "General Information on Data
            Retention and Deletion".
          </li>
          <li className="">
            <strong>Legal Basis:</strong> Legitimate Interests (Article 6 (1)
            (f) GDPR).
          </li>
        </ul>
        <p>
          <strong>
            Further information on processing methods, procedures and services
            used:
          </strong>
        </p>
        <ul className="m-elements">
          <li>
            <strong>Lexware: </strong>Software for invoicing, accounting,
            banking and tax filing with document storage;{' '}
            <strong>Service provider</strong>: Haufe Service Center GmbH,
            Munzinger Stra&szlig;e 9, 79111 Freiburg, Deutschland;{' '}
            <span className="">
              <strong>Legal Basis:</strong> Legitimate Interests (Article 6 (1)
              (f) GDPR);{' '}
            </span>
            <strong>Website:</strong>{' '}
            <a
              href="https://www.lexoffice.de/datenschutz/"
              target="_blank"
              rel="noreferrer"
            >
              https://www.lexoffice.de/datenschutz/
            </a>
            . <strong>Privacy Policy:</strong>{' '}
            <a
              href="https://datenschutz.lexware.de/"
              target="_blank"
              rel="noreferrer"
            >
              https://datenschutz.lexware.de/
            </a>
            .
          </li>
        </ul>
        <h2 id="m326">Payment Procedure</h2>
        <p>
          Within the framework of contractual and other legal relationships, due
          to legal obligations or otherwise on the basis of our legitimate
          interests, we offer data subjects efficient and secure payment options
          and use other service providers for this purpose in addition to banks
          and credit institutions (collectively referred to as "payment service
          providers").
        </p>
        <p>
          The data processed by the payment service providers includes inventory
          data, such as the name and address, bank data, such as account numbers
          or credit card numbers, passwords, TANs and checksums, as well as the
          contract, total and recipient-related information. The information is
          required to carry out the transactions. However, the data entered is
          only processed by the payment service providers and stored with them.
          I.e. we do not receive any account or credit card related information,
          but only information with confirmation or negative information of the
          payment. Under certain circumstances, the data may be transmitted by
          the payment service providers to credit agencies. The purpose of this
          transmission is to check identity and creditworthiness. Please refer
          to the terms and conditions and data protection information of the
          payment service providers.
        </p>
        <p>
          The terms and conditions and data protection information of the
          respective payment service providers apply to the payment transactions
          and can be accessed within the respective websites or transaction
          applications. We also refer to these for further information and the
          assertion of revocation, information and other data subject rights.
        </p>
        <ul className="m-elements">
          <li>
            <strong>Processed data types:</strong> Inventory data (For example,
            the full name, residential address, contact information, customer
            number, etc.); Payment Data (e.g. bank details, invoices, payment
            history); Contract data (e.g. contract object, duration, customer
            category); Usage data (e.g. page views and duration of visit, click
            paths, intensity and frequency of use, types of devices and
            operating systems used, interactions with content and features).
            Meta, communication and process data (e.g. IP addresses, timestamps,
            identification numbers, involved parties).
          </li>
          <li>
            <strong>Data subjects:</strong> Service recipients and clients;
            Business and contractual partners. Prospective customers.
          </li>
          <li>
            <strong>Purposes of processing:</strong> Provision of contractual
            services and fulfillment of contractual obligations. Business
            processes and management procedures.
          </li>
          <li>
            <strong>Retention and deletion:</strong> Deletion in accordance with
            the information provided in the section "General Information on Data
            Retention and Deletion".
          </li>
          <li className="">
            <strong>Legal Basis:</strong> Performance of a contract and prior
            requests (Article 6 (1) (b) GDPR). Legitimate Interests (Article 6
            (1) (f) GDPR).
          </li>
        </ul>
        <p>
          <strong>
            Further information on processing methods, procedures and services
            used:
          </strong>
        </p>
        <ul className="m-elements">
          <li>
            <strong>Stripe: </strong>Payment-Service-Provider (technical
            integration of online-payment-methods);{' '}
            <strong>Service provider</strong>: Stripe, Inc., 510 Townsend
            Street, San Francisco, CA 94103, USA;{' '}
            <span className="">
              <strong>Legal Basis:</strong> Performance of a contract and prior
              requests (Article 6 (1) (b) GDPR);{' '}
            </span>
            <strong>Website:</strong>{' '}
            <a href="https://stripe.com/de" target="_blank" rel="noreferrer">
              https://stripe.com/de
            </a>
            ; <strong>Privacy Policy:</strong>{' '}
            <a
              href="https://stripe.com/en-de/privacy"
              target="_blank"
              rel="noreferrer"
            >
              https://stripe.com/en-de/privacy
            </a>
            . <strong>Basis for third-country transfers:</strong> Data Privacy
            Framework (DPF), Data Privacy Framework (DPF).
          </li>
        </ul>
        <h2 id="m225">Provision of online services and web hosting</h2>
        <p>
          We process user data in order to be able to provide them with our
          online services. For this purpose, we process the IP address of the
          user, which is necessary to transmit the content and functions of our
          online services to the user's browser or terminal device.
        </p>
        <ul className="m-elements">
          <li>
            <strong>Processed data types:</strong> Usage data (e.g. page views
            and duration of visit, click paths, intensity and frequency of use,
            types of devices and operating systems used, interactions with
            content and features); Meta, communication and process data (e.g. IP
            addresses, timestamps, identification numbers, involved parties);
            Log data (e.g. log files concerning logins or data retrieval or
            access times.). Content data (e.g. textual or pictorial messages and
            contributions, as well as information pertaining to them, such as
            details of authorship or the time of creation.).
          </li>
          <li>
            <strong>Data subjects:</strong> Users (e.g. website visitors, users
            of online services). Business and contractual partners.
          </li>
          <li>
            <strong>Purposes of processing:</strong> Provision of our online
            services and usability; Information technology infrastructure
            (Operation and provision of information systems and technical
            devices, such as computers, servers, etc.)). Security measures.
          </li>
          <li>
            <strong>Retention and deletion:</strong> Deletion in accordance with
            the information provided in the section "General Information on Data
            Retention and Deletion".
          </li>
          <li className="">
            <strong>Legal Basis:</strong> Legitimate Interests (Article 6 (1)
            (f) GDPR).
          </li>
        </ul>
        <p>
          <strong>
            Further information on processing methods, procedures and services
            used:
          </strong>
        </p>
        <ul className="m-elements">
          <li>
            <strong>Provision of online offer on rented hosting space: </strong>
            For the provision of our online services, we use storage space,
            computing capacity and software that we rent or otherwise obtain
            from a corresponding server provider (also referred to as a "web
            hoster");{' '}
            <span className="">
              <strong>Legal Basis:</strong> Legitimate Interests (Article 6 (1)
              (f) GDPR).
            </span>
          </li>
          <li>
            <strong>Collection of Access Data and Log Files: </strong>Access to
            our online service is logged in the form of so-called "server log
            files". Server log files may include the address and name of the
            accessed web pages and files, date and time of access, transferred
            data volumes, notification of successful retrieval, browser type
            along with version, the user's operating system, referrer URL (the
            previously visited page), and typically IP addresses and the
            requesting provider. The server log files can be used for security
            purposes, e.g., to prevent server overload (especially in the case
            of abusive attacks, known as DDoS attacks), and to ensure server
            load management and stability;{' '}
            <span className="">
              <strong>Legal Basis:</strong> Legitimate Interests (Article 6 (1)
              (f) GDPR).{' '}
            </span>
            <strong>Retention period:</strong> Log file information is stored
            for a maximum period of 30 days and then deleted or anonymized.
            Data, the further storage of which is necessary for evidence
            purposes, are excluded from deletion until the respective incident
            has been finally clarified.
          </li>
          <li>
            <strong>Content-Delivery-Network: </strong>We use a so-called
            "Content Delivery Network" (CDN). A CDN is a service with whose help
            contents of our online services, in particular large media files,
            such as graphics or scripts, can be delivered faster and more
            securely with the help of regionally distributed servers connected
            via the Internet;{' '}
            <span className="">
              <strong>Legal Basis:</strong> Legitimate Interests (Article 6 (1)
              (f) GDPR).
            </span>
          </li>
          <li>
            <strong>Amazon Web Services (AWS): </strong>Services in the field of
            the provision of information technology infrastructure and related
            services (e.g. storage space and/or computing capacities);{' '}
            <strong>Service provider</strong>: Amazon Web Services EMEA SARL, 38
            avenue John F. Kennedy, 1855, Luxembourg;{' '}
            <span className="">
              <strong>Legal Basis:</strong> Legitimate Interests (Article 6 (1)
              (f) GDPR);{' '}
            </span>
            <strong>Website:</strong>{' '}
            <a href="https://aws.amazon.com/" target="_blank" rel="noreferrer">
              https://aws.amazon.com/
            </a>
            ; <strong>Privacy Policy:</strong>{' '}
            <a
              href="https://aws.amazon.com/privacy/"
              target="_blank"
              rel="noreferrer"
            >
              https://aws.amazon.com/privacy/
            </a>
            ; <strong>Data Processing Agreement:</strong>{' '}
            <a
              href="https://aws.amazon.com/compliance/gdpr-center/"
              target="_blank"
              rel="noreferrer"
            >
              https://aws.amazon.com/compliance/gdpr-center/
            </a>
            . <strong>Basis for third-country transfers:</strong> Data Privacy
            Framework (DPF), Standard Contractual Clauses (
            <a
              href="https://aws.amazon.com/en/service-terms/"
              target="_blank"
              rel="noreferrer"
            >
              https://aws.amazon.com/en/service-terms/
            </a>
            ), Data Privacy Framework (DPF)Standard Contractual Clauses (
            <a
              href="https://aws.amazon.com/en/service-terms/"
              target="_blank"
              rel="noreferrer"
            >
              https://aws.amazon.com/en/service-terms/
            </a>
            ).
          </li>
          <li>
            <strong>STRATO: </strong>Services in the field of the provision of
            information technology infrastructure and related services (e.g.
            storage space and/or computing capacities);{' '}
            <strong>Service provider</strong>: STRATO AG, Pascalstra&szlig;e
            10,10587 Berlin, Germany;{' '}
            <span className="">
              <strong>Legal Basis:</strong> Legitimate Interests (Article 6 (1)
              (f) GDPR);{' '}
            </span>
            <strong>Website:</strong>{' '}
            <a href="https://www.strato.de" target="_blank" rel="noreferrer">
              https://www.strato.de
            </a>
            ; <strong>Privacy Policy:</strong>{' '}
            <a
              href="https://www.strato.de/datenschutz"
              target="_blank"
              rel="noreferrer"
            >
              https://www.strato.de/datenschutz
            </a>
            . <strong>Data Processing Agreement:</strong> Provided by the
            service provider.
          </li>
          <li>
            <strong>Amazon CloudFront: </strong>Content-Delivery-Network (CDN) -
            service with whose help contents of our online services, in
            particular large media files, such as graphics or scripts, can be
            delivered faster and more securely with the help of regionally
            distributed servers connected via the Internet;{' '}
            <strong>Service provider</strong>: Amazon Web Services EMEA SARL, 38
            avenue John F. Kennedy, 1855, Luxembourg;{' '}
            <span className="">
              <strong>Legal Basis:</strong> Legitimate Interests (Article 6 (1)
              (f) GDPR);{' '}
            </span>
            <strong>Website:</strong>{' '}
            <a
              href="https://aws.amazon.com/cloudfront/"
              target="_blank"
              rel="noreferrer"
            >
              https://aws.amazon.com/cloudfront/
            </a>
            ; <strong>Privacy Policy:</strong>{' '}
            <a
              href="https://aws.amazon.com/privacy/"
              target="_blank"
              rel="noreferrer"
            >
              https://aws.amazon.com/privacy/
            </a>
            ; <strong>Data Processing Agreement:</strong>{' '}
            <a
              href="https://aws.amazon.com/compliance/gdpr-center/"
              target="_blank"
              rel="noreferrer"
            >
              https://aws.amazon.com/compliance/gdpr-center/
            </a>
            . <strong>Basis for third-country transfers:</strong> Standard
            Contractual Clauses (Provided by the service provider), Standard
            Contractual Clauses (Provided by the service provider).
          </li>
        </ul>
        <h2 id="m367">Registration, Login and User Account</h2>
        <p>
          Users can create a user account. Within the scope of registration, the
          required mandatory information is communicated to the users and
          processed for the purposes of providing the user account on the basis
          of contractual fulfilment of obligations. The processed data includes
          in particular the login information (name, password and an e-mail
          address).
        </p>
        <p>
          Within the scope of using our registration and login functions as well
          as the use of the user account, we store the IP address and the time
          of the respective user action. The storage is based on our legitimate
          interests, as well as the user's protection against misuse and other
          unauthorized use. This data will not be passed on to third parties
          unless it is necessary to pursue our claims or there is a legal
          obligation to do so.
        </p>
        <p>
          Users may be informed by e-mail of information relevant to their user
          account, such as technical changes.
        </p>
        <ul className="m-elements">
          <li>
            <strong>Processed data types:</strong> Inventory data (For example,
            the full name, residential address, contact information, customer
            number, etc.); Contact data (e.g. postal and email addresses or
            phone numbers); Content data (e.g. textual or pictorial messages and
            contributions, as well as information pertaining to them, such as
            details of authorship or the time of creation.); Usage data (e.g.
            page views and duration of visit, click paths, intensity and
            frequency of use, types of devices and operating systems used,
            interactions with content and features). Log data (e.g. log files
            concerning logins or data retrieval or access times.).
          </li>
          <li>
            <strong>Data subjects:</strong> Users (e.g. website visitors, users
            of online services).
          </li>
          <li>
            <strong>Purposes of processing:</strong> Provision of contractual
            services and fulfillment of contractual obligations; Security
            measures; Organisational and Administrative Procedures. Provision of
            our online services and usability.
          </li>
          <li>
            <strong>Retention and deletion:</strong> Deletion in accordance with
            the information provided in the section "General Information on Data
            Retention and Deletion". Deletion after termination.
          </li>
          <li className="">
            <strong>Legal Basis:</strong> Performance of a contract and prior
            requests (Article 6 (1) (b) GDPR). Legitimate Interests (Article 6
            (1) (f) GDPR).
          </li>
        </ul>
        <p>
          <strong>
            Further information on processing methods, procedures and services
            used:
          </strong>
        </p>
        <ul className="m-elements">
          <li>
            <strong>Registration with a real name: </strong>Due to the nature of
            our community, we ask users to use our services only with their real
            names. This means that the use of pseudonyms is not permitted;{' '}
            <span className="">
              <strong>Legal Basis:</strong> Performance of a contract and prior
              requests (Article 6 (1) (b) GDPR).
            </span>
          </li>
          <li>
            <strong>Users' profiles are public: </strong>The users' profiles are
            not publicly visible or accessible.
          </li>
          <li>
            <strong>Deletion of data after termination: </strong>If users have
            terminated their user account, their data relating to the user
            account will be deleted, subject to any legal permission, obligation
            or consent of the users;{' '}
            <span className="">
              <strong>Legal Basis:</strong> Performance of a contract and prior
              requests (Article 6 (1) (b) GDPR).
            </span>
          </li>
          <li>
            <strong>No obligation to retain data: </strong>It is the
            responsibility of the users to secure their data before the end of
            the contract in the event of termination. We are entitled to
            irretrievably delete all user data stored during the term of the
            contract;{' '}
            <span className="">
              <strong>Legal Basis:</strong> Performance of a contract and prior
              requests (Article 6 (1) (b) GDPR).
            </span>
          </li>
        </ul>
        <h2 id="m182">Contact and Inquiry Management</h2>
        <p>
          When contacting us (e.g. via mail, contact form, e-mail, telephone or
          via social media) as well as in the context of existing user and
          business relationships, the information of the inquiring persons is
          processed to the extent necessary to respond to the contact requests
          and any requested measures.
        </p>
        <ul className="m-elements">
          <li>
            <strong>Processed data types:</strong> Inventory data (For example,
            the full name, residential address, contact information, customer
            number, etc.); Contact data (e.g. postal and email addresses or
            phone numbers); Content data (e.g. textual or pictorial messages and
            contributions, as well as information pertaining to them, such as
            details of authorship or the time of creation.); Usage data (e.g.
            page views and duration of visit, click paths, intensity and
            frequency of use, types of devices and operating systems used,
            interactions with content and features). Meta, communication and
            process data (e.g. IP addresses, timestamps, identification numbers,
            involved parties).
          </li>
          <li>
            <strong>Data subjects:</strong> Communication partner (Recipients of
            e-mails, letters, etc.).
          </li>
          <li>
            <strong>Purposes of processing:</strong> Communication;
            Organisational and Administrative Procedures; Feedback (e.g.
            collecting feedback via online form). Provision of our online
            services and usability.
          </li>
          <li>
            <strong>Retention and deletion:</strong> Deletion in accordance with
            the information provided in the section "General Information on Data
            Retention and Deletion".
          </li>
          <li className="">
            <strong>Legal Basis:</strong> Legitimate Interests (Article 6 (1)
            (f) GDPR). Performance of a contract and prior requests (Article 6
            (1) (b) GDPR).
          </li>
        </ul>
        <p>
          <strong>
            Further information on processing methods, procedures and services
            used:
          </strong>
        </p>
        <ul className="m-elements">
          <li>
            <strong>Contact form: </strong>Upon contacting us via our contact
            form, email, or other means of communication, we process the
            personal data transmitted to us for the purpose of responding to and
            handling the respective matter. This typically includes details such
            as name, contact information, and possibly additional information
            provided to us that is necessary for appropriate processing. We use
            this data exclusively for the stated purpose of contact and
            communication;{' '}
            <span className="">
              <strong>Legal Basis:</strong> Performance of a contract and prior
              requests (Article 6 (1) (b) GDPR), Legitimate Interests (Article 6
              (1) (f) GDPR).
            </span>
          </li>
        </ul>
        <h2 id="m391">Communication via Messenger</h2>
        <p>
          We use messenger services for communication purposes and therefore ask
          you to observe the following information regarding the functionality
          of the messenger services, encryption, use of the metadata of the
          communication and your objection options.
        </p>
        <p>
          You can also contact us by alternative means, e.g. telephone or
          e-mail. Please use the contact options provided to you or use the
          contact options provided within our online services.
        </p>
        <p>
          In the case of encryption of content (i.e. the content of your message
          and attachments), we point out that the communication content (i.e.
          the content of the message and attachments) is encrypted end-to-end.
          This means that the content of the messages is not visible, not even
          by the messenger service providers themselves. You should always use a
          current version of the messenger service with activated encryption, so
          that the encryption of the message contents is guaranteed.{' '}
        </p>
        <p>
          However, we would like to point out to our communication partners that
          although messenger service providers do not see the content, they can
          find out that and when communication partners communicate with us and
          process technical information on the communication partner's device
          used and, depending on the settings of their device, also location
          information (so-called metadata).{' '}
        </p>
        <p>
          <strong>Information on Legal basis: </strong> If we ask communication
          partners for permission before communicating with them via messenger
          services, the legal basis of our processing of their data is their
          consent. Otherwise, if we do not request consent and you contact us,
          for example, voluntarily, we use messenger services in our dealings
          with our contractual partners and as part of the contract initiation
          process as a contractual measure and in the case of other interested
          parties and communication partners on the basis of our legitimate
          interests in fast and efficient communication and meeting the needs of
          our communication partners for communication via messenger services.
          We would also like to point out that we do not transmit the contact
          data provided to us to the messenger service providers for the first
          time without your consent.
        </p>
        <p>
          <strong>Withdrawal, objection and deletion: </strong> You can withdraw
          your consent or object to communication with us via messenger services
          at any time. In the case of communication via messenger services, we
          delete the messages in accordance with our general data retention
          policy (i.e. as described above after the end of contractual
          relationships, archiving requirements, etc.) and otherwise as soon as
          we can assume that we have answered any information provided by the
          communication partners, if no reference to a previous conversation is
          to be expected and there are no legal obligations to store the
          messages to prevent their deletion.
        </p>
        <p>
          <strong>
            Reservation of reference to other means of communication:
          </strong>{' '}
          For your security, we kindly ask for your understanding that we may
          not respond to enquiries via messenger for specific reasons. This
          applies in situations where contract details require heightened
          confidentiality or a response via messenger does not meet formal
          requirements. In such cases, we recommend using more appropriate
          communication channels.
        </p>
        <ul className="m-elements">
          <li>
            <strong>Processed data types:</strong> Contact data (e.g. postal and
            email addresses or phone numbers); Content data (e.g. textual or
            pictorial messages and contributions, as well as information
            pertaining to them, such as details of authorship or the time of
            creation.); Usage data (e.g. page views and duration of visit, click
            paths, intensity and frequency of use, types of devices and
            operating systems used, interactions with content and features).
            Meta, communication and process data (e.g. IP addresses, timestamps,
            identification numbers, involved parties).
          </li>
          <li>
            <strong>Data subjects:</strong> Communication partner (Recipients of
            e-mails, letters, etc.).
          </li>
          <li>
            <strong>Purposes of processing:</strong> Communication.
          </li>
          <li>
            <strong>Retention and deletion:</strong> Deletion in accordance with
            the information provided in the section "General Information on Data
            Retention and Deletion".
          </li>
          <li className="">
            <strong>Legal Basis:</strong> Consent (Article 6 (1) (a) GDPR);
            Performance of a contract and prior requests (Article 6 (1) (b)
            GDPR). Legitimate Interests (Article 6 (1) (f) GDPR).
          </li>
        </ul>
        <p>
          <strong>
            Further information on processing methods, procedures and services
            used:
          </strong>
        </p>
        <ul className="m-elements">
          <li>
            <strong>LinkedIn: </strong>Social network - We are jointly
            responsible with LinkedIn Ireland Unlimited Company for the
            collection (but not the further processing) of visitor data, which
            is used to create "Page Insights" (statistics) for our LinkedIn
            profiles. This data includes information about the types of content
            users view or interact with, as well as the actions they take. It
            also includes details about the devices used, such as IP addresses,
            operating systems, browser types, language settings, and cookie
            data, as well as profile details of users, such as job function,
            country, industry, seniority, company size, and employment status.
            Privacy information regarding the processing of user data by
            LinkedIn can be found in LinkedIn's privacy policy:{' '}
            <a
              href="https://www.linkedin.com/legal/privacy-policy"
              target="_blank"
              rel="noreferrer"
            >
              https://www.linkedin.com/legal/privacy-policy.
            </a>
            <br />
            We have entered into a special agreement with LinkedIn Ireland
            ("Page Insights Joint Controller Addendum,"{' '}
            <a
              href="https://legal.linkedin.com/pages-joint-controller-addendum"
              target="_blank"
              rel="noreferrer"
            >
              https://legal.linkedin.com/pages-joint-controller-addendum
            </a>
            ), which specifically regulates the security measures LinkedIn must
            comply with and in which LinkedIn has agreed to fulfill the rights
            of data subjects (i.e., users can, for example, direct requests for
            information or deletion directly to LinkedIn). The rights of users
            (particularly the right to information, deletion, objection, and to
            lodge a complaint with the competent supervisory authority) are not
            restricted by our agreements with LinkedIn. The joint responsibility
            is limited to the collection of data and its transmission to
            LinkedIn Ireland Unlimited Company, a company based in the EU.
            Further processing of the data is the sole responsibility of
            LinkedIn Ireland Unlimited Company, particularly concerning the
            transfer of data to the parent company LinkedIn Corporation in the
            USA; <strong>Service provider</strong>: LinkedIn Ireland Unlimited
            Company, Wilton Place, Dublin 2, Ireland;{' '}
            <span className="">
              <strong>Legal Basis:</strong> Legitimate Interests (Article 6 (1)
              (f) GDPR);{' '}
            </span>
            <strong>Website:</strong>{' '}
            <a href="https://www.linkedin.com" target="_blank" rel="noreferrer">
              https://www.linkedin.com
            </a>
            ; <strong>Privacy Policy:</strong>{' '}
            <a
              href="https://www.linkedin.com/legal/privacy-policy"
              target="_blank"
              rel="noreferrer"
            >
              https://www.linkedin.com/legal/privacy-policy
            </a>
            ; <strong>Basis for third-country transfers:</strong> Data Privacy
            Framework (DPF), Standard Contractual Clauses (
            <a
              href="https://legal.linkedin.com/dpa"
              target="_blank"
              rel="noreferrer"
            >
              https://legal.linkedin.com/dpa
            </a>
            ), Data Privacy Framework (DPF)Standard Contractual Clauses (
            <a
              href="https://legal.linkedin.com/dpa"
              target="_blank"
              rel="noreferrer"
            >
              https://legal.linkedin.com/dpa
            </a>
            ). <strong>Opt-Out:</strong>{' '}
            <a
              href="https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out"
              target="_blank"
              rel="noreferrer"
            >
              https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out
            </a>
            .
          </li>
          <li>
            <strong>LinkedIn Messages: </strong>Sending messages via the social
            network LinkedIn; <strong>Service provider</strong>: LinkedIn
            Ireland Unlimited Company, Wilton Place, Dublin 2, Ireland;{' '}
            <span className="">
              <strong>Legal Basis:</strong> Legitimate Interests (Article 6 (1)
              (f) GDPR);{' '}
            </span>
            <strong>Website:</strong>{' '}
            <a href="https://www.linkedin.com" target="_blank" rel="noreferrer">
              https://www.linkedin.com
            </a>
            ; <strong>Privacy Policy:</strong>{' '}
            <a
              href="https://www.linkedin.com/legal/privacy-policy"
              target="_blank"
              rel="noreferrer"
            >
              https://www.linkedin.com/legal/privacy-policy
            </a>
            . <strong>Basis for third-country transfers:</strong> Data Privacy
            Framework (DPF), Data Privacy Framework (DPF).
          </li>
        </ul>
        <h2 id="m735">
          Video Conferences, Online Meetings, Webinars and Screen-Sharing
        </h2>
        <p>
          We use platforms and applications of other providers (hereinafter
          referred to as "Conference Platforms") for the purpose of conducting
          video and audio conferences, webinars and other types of video and
          audio meetings (hereinafter collectively referred to as "Conference").
          When using the Conference Platforms and their services, we comply with
          the legal requirements.{' '}
        </p>
        <p>
          <strong>Data processed by Conference Platforms:</strong> In the course
          of participation in a Conference, the Data of the participants listed
          below are processed. The scope of the processing depends, on the one
          hand, on which data is requested in the context of a specific
          Conference (e.g., provision of access data or clear names) and which
          optional information is provided by the participants. In addition to
          processing for the purpose of conducting the conference, participants'
          Data may also be processed by the Conference Platforms for security
          purposes or service optimization. The processed Date includes personal
          information (first name, last name), contact information (e-mail
          address, telephone number), access data (access codes or passwords),
          profile pictures, information on professional position/function, the
          IP address of the internet access, information on the participants'
          end devices, their operating system, the browser and its technical and
          linguistic settings, information on the content-related communication
          processes, i.e. entries in chats and audio and video data, as well as
          the use of other available functions (e.g. surveys). The content of
          communications is encrypted to the extent technically provided by the
          conference providers. If participants are registered as users with the
          Conference Platforms, then further data may be processed in accordance
          with the agreement with the respective Conference Provider.
        </p>
        <p>
          <strong>Logging and recording:</strong> If text entries, participation
          results (e.g. from surveys) as well as video or audio recordings are
          recorded, this will be transparently communicated to the participants
          in advance and they will be asked - if necessary - for their consent.
        </p>
        <p>
          <strong>Data protection measures of the participants:</strong> Please
          refer to the data privacy information of the Conference Platforms for
          details on the processing of your data and select the optimum security
          and data privacy settings for you within the framework of the settings
          of the conference platforms. Furthermore, please ensure data and
          privacy protection in the background of your recording for the
          duration of a Conference (e.g., by notifying roommates, locking doors,
          and using the background masking function, if technically possible).
          Links to the conference rooms as well as access data, should not be
          passed on to unauthorized third parties.
        </p>
        <p>
          <strong>Notes on legal bases:</strong> Insofar as, in addition to the
          Conference Platforms, we also process users' data and ask users for
          their consent to use contents from the Conferences or certain
          functions (e.g. consent to a recording of Conferences), the legal
          basis of the processing is this consent. Furthermore, our processing
          may be necessary for the fulfillment of our contractual obligations
          (e.g. in participant lists, in the case of reprocessing of Conference
          results, etc.). Otherwise, user data is processed on the basis of our
          legitimate interests in efficient and secure communication with our
          communication partners.
        </p>
        <ul className="m-elements">
          <li>
            <strong>Processed data types:</strong> Inventory data (For example,
            the full name, residential address, contact information, customer
            number, etc.); Contact data (e.g. postal and email addresses or
            phone numbers); Content data (e.g. textual or pictorial messages and
            contributions, as well as information pertaining to them, such as
            details of authorship or the time of creation.); Usage data (e.g.
            page views and duration of visit, click paths, intensity and
            frequency of use, types of devices and operating systems used,
            interactions with content and features); Images and/ or video
            recordings (e.g. photographs or video recordings of a person); Audio
            recordings. Log data (e.g. log files concerning logins or data
            retrieval or access times.).
          </li>
          <li>
            <strong>Data subjects:</strong> Communication partner (Recipients of
            e-mails, letters, etc.); Users (e.g. website visitors, users of
            online services). Persons depicted.
          </li>
          <li>
            <strong>Purposes of processing:</strong> Provision of contractual
            services and fulfillment of contractual obligations; Communication.
            Office and organisational procedures.
          </li>
          <li>
            <strong>Retention and deletion:</strong> Deletion in accordance with
            the information provided in the section "General Information on Data
            Retention and Deletion".
          </li>
          <li className="">
            <strong>Legal Basis:</strong> Legitimate Interests (Article 6 (1)
            (f) GDPR).
          </li>
        </ul>
        <p>
          <strong>
            Further information on processing methods, procedures and services
            used:
          </strong>
        </p>
        <ul className="m-elements">
          <li>
            <strong>Discord: </strong>Chat, audio and video broadcasting,
            instant messaging, and community management;{' '}
            <strong>Service provider</strong>: Discord, Inc., 444 De Haro St,
            Suite 200, San Francisco, California 94107, USA;{' '}
            <span className="">
              <strong>Legal Basis:</strong> Legitimate Interests (Article 6 (1)
              (f) GDPR);{' '}
            </span>
            <strong>Website:</strong>{' '}
            <a href="https://discordapp.com/" target="_blank" rel="noreferrer">
              https://discordapp.com/
            </a>
            ; <strong>Privacy Policy:</strong>{' '}
            <a
              href="https://discordapp.com/privacy"
              target="_blank"
              rel="noreferrer"
            >
              https://discordapp.com/privacy
            </a>
            . <strong>Basis for third-country transfers:</strong> Data Privacy
            Framework (DPF), Data Privacy Framework (DPF).
          </li>
          <li>
            <strong>Slack: </strong>Messenger and conference software;{' '}
            <strong>Service provider</strong>: Slack Technologies Limited, Level
            1, Block A Nova Atria North, Sandyford Business District, Dublin 18,
            Ireland;{' '}
            <span className="">
              <strong>Legal Basis:</strong> Legitimate Interests (Article 6 (1)
              (f) GDPR);{' '}
            </span>
            <strong>Website:</strong>{' '}
            <a href="https://slack.com/" target="_blank" rel="noreferrer">
              https://slack.com/
            </a>
            ; <strong>Privacy Policy:</strong>{' '}
            <a
              href="https://slack.com/intl/en-de/legal"
              target="_blank"
              rel="noreferrer"
            >
              https://slack.com/intl/en-de/legal
            </a>
            ; <strong>Data Processing Agreement:</strong>{' '}
            <a
              href="https://slack.com/intl/de-de/terms-of-service/data-processing"
              target="_blank"
              rel="noreferrer"
            >
              https://slack.com/intl/de-de/terms-of-service/data-processing
            </a>
            . <strong>Basis for third-country transfers:</strong> Data Privacy
            Framework (DPF), Standard Contractual Clauses (
            <a
              href="https://slack.com/intl/de-de/terms-of-service/data-processing"
              target="_blank"
              rel="noreferrer"
            >
              https://slack.com/intl/de-de/terms-of-service/data-processing
            </a>
            ), Data Privacy Framework (DPF)Standard Contractual Clauses (
            <a
              href="https://slack.com/intl/de-de/terms-of-service/data-processing"
              target="_blank"
              rel="noreferrer"
            >
              https://slack.com/intl/de-de/terms-of-service/data-processing
            </a>
            ).
          </li>
        </ul>
        <h2 id="m29">Cloud Services</h2>
        <p>
          We use Internet-accessible software services (so-called "cloud
          services", also referred to as "Software as a Service") provided on
          the servers of its providers for the storage and management of content
          (e.g. document storage and management, exchange of documents, content
          and information with certain recipients or publication of content and
          information).
        </p>
        <p>
          Within this framework, personal data may be processed and stored on
          the provider's servers insofar as this data is part of communication
          processes with us or is otherwise processed by us in accordance with
          this privacy policy. This data may include in particular master data
          and contact data of data subjects, data on processes, contracts, other
          proceedings and their contents. Cloud service providers also process
          usage data and metadata that they use for security and service
          optimization purposes.
        </p>
        <p>
          If we use cloud services to provide documents and content to other
          users or publicly accessible websites, forms, etc., providers may
          store cookies on users' devices for web analysis or to remember user
          settings (e.g. in the case of media control).
        </p>
        <ul className="m-elements">
          <li>
            <strong>Processed data types:</strong> Inventory data (For example,
            the full name, residential address, contact information, customer
            number, etc.); Contact data (e.g. postal and email addresses or
            phone numbers); Content data (e.g. textual or pictorial messages and
            contributions, as well as information pertaining to them, such as
            details of authorship or the time of creation.). Usage data (e.g.
            page views and duration of visit, click paths, intensity and
            frequency of use, types of devices and operating systems used,
            interactions with content and features).
          </li>
          <li>
            <strong>Data subjects:</strong> Prospective customers; Communication
            partner (Recipients of e-mails, letters, etc.); Business and
            contractual partners. Users (e.g. website visitors, users of online
            services).
          </li>
          <li>
            <strong>Purposes of processing:</strong> Office and organisational
            procedures. Information technology infrastructure (Operation and
            provision of information systems and technical devices, such as
            computers, servers, etc.)).
          </li>
          <li>
            <strong>Retention and deletion:</strong> Deletion in accordance with
            the information provided in the section "General Information on Data
            Retention and Deletion".
          </li>
          <li className="">
            <strong>Legal Basis:</strong> Legitimate Interests (Article 6 (1)
            (f) GDPR).
          </li>
        </ul>
        <p>
          <strong>
            Further information on processing methods, procedures and services
            used:
          </strong>
        </p>
        <ul className="m-elements">
          <li>
            <strong>Google Cloud Storage: </strong>Cloud storage, cloud
            infrastructure services and cloud-based application software;{' '}
            <strong>Service provider</strong>: Google Cloud EMEA Limited, 70 Sir
            John Rogerson’s Quay, Dublin 2, Ireland;{' '}
            <span className="">
              <strong>Legal Basis:</strong> Legitimate Interests (Article 6 (1)
              (f) GDPR);{' '}
            </span>
            <strong>Website:</strong>{' '}
            <a
              href="https://cloud.google.com/"
              target="_blank"
              rel="noreferrer"
            >
              https://cloud.google.com/
            </a>
            ; <strong>Privacy Policy:</strong>{' '}
            <a
              href="https://policies.google.com/privacy"
              target="_blank"
              rel="noreferrer"
            >
              https://policies.google.com/privacy
            </a>
            ; <strong>Data Processing Agreement:</strong>{' '}
            <a
              href="https://cloud.google.com/terms/data-processing-addendum"
              target="_blank"
              rel="noreferrer"
            >
              https://cloud.google.com/terms/data-processing-addendum
            </a>
            ; <strong>Basis for third-country transfers:</strong> Data Privacy
            Framework (DPF), Standard Contractual Clauses (
            <a
              href="https://cloud.google.com/terms/eu-model-contract-clause"
              target="_blank"
              rel="noreferrer"
            >
              https://cloud.google.com/terms/eu-model-contract-clause
            </a>
            ), Data Privacy Framework (DPF)Standard Contractual Clauses (
            <a
              href="https://cloud.google.com/terms/eu-model-contract-clause"
              target="_blank"
              rel="noreferrer"
            >
              https://cloud.google.com/terms/eu-model-contract-clause
            </a>
            ). <strong>Further Information:</strong>{' '}
            <a
              href="https://cloud.google.com/privacy"
              target="_blank"
              rel="noreferrer"
            >
              https://cloud.google.com/privacy
            </a>
            .
          </li>
          <li>
            <strong>Google Workspace: </strong>Cloud storage, cloud
            infrastructure services and cloud-based application software;{' '}
            <strong>Service provider</strong>: Google Cloud EMEA Limited, 70 Sir
            John Rogerson’s Quay, Dublin 2, Ireland;{' '}
            <span className="">
              <strong>Legal Basis:</strong> Legitimate Interests (Article 6 (1)
              (f) GDPR);{' '}
            </span>
            <strong>Website:</strong>{' '}
            <a
              href="https://workspace.google.com/"
              target="_blank"
              rel="noreferrer"
            >
              https://workspace.google.com/
            </a>
            ; <strong>Privacy Policy:</strong>{' '}
            <a
              href="https://policies.google.com/privacy"
              target="_blank"
              rel="noreferrer"
            >
              https://policies.google.com/privacy
            </a>
            ; <strong>Data Processing Agreement:</strong>{' '}
            <a
              href="https://cloud.google.com/terms/data-processing-addendum"
              target="_blank"
              rel="noreferrer"
            >
              https://cloud.google.com/terms/data-processing-addendum
            </a>
            ; <strong>Basis for third-country transfers:</strong> Data Privacy
            Framework (DPF), Standard Contractual Clauses (
            <a
              href="https://cloud.google.com/terms/eu-model-contract-clause"
              target="_blank"
              rel="noreferrer"
            >
              https://cloud.google.com/terms/eu-model-contract-clause
            </a>
            ), Data Privacy Framework (DPF)Standard Contractual Clauses (
            <a
              href="https://cloud.google.com/terms/eu-model-contract-clause"
              target="_blank"
              rel="noreferrer"
            >
              https://cloud.google.com/terms/eu-model-contract-clause
            </a>
            ). <strong>Further Information:</strong>{' '}
            <a
              href="https://cloud.google.com/privacy"
              target="_blank"
              rel="noreferrer"
            >
              https://cloud.google.com/privacy
            </a>
            .
          </li>
          <li>
            <strong>ClickUp: </strong>Project management - organization and
            administration of teams, groups, workflows, projects and processes;{' '}
            <strong>Service provider</strong>: Mango Technologies, Inc., 580
            Howard St, Suite 101, San Francisco, California 94105, USA;{' '}
            <span className="">
              <strong>Legal Basis:</strong> Legitimate Interests (Article 6 (1)
              (f) GDPR);{' '}
            </span>
            <strong>Website:</strong>{' '}
            <a href="https://clickup.com/" target="_blank" rel="noreferrer">
              https://clickup.com/
            </a>
            ; <strong>Privacy Policy:</strong>{' '}
            <a
              href="https://clickup.com/privacy"
              target="_blank"
              rel="noreferrer"
            >
              https://clickup.com/privacy
            </a>
            ; <strong>Data Processing Agreement:</strong>{' '}
            <a
              href="https://clickup.com/terms/dpa"
              target="_blank"
              rel="noreferrer"
            >
              https://clickup.com/terms/dpa
            </a>
            . <strong>Basis for third-country transfers:</strong> Data Privacy
            Framework (DPF), Data Privacy Framework (DPF).
          </li>
        </ul>
        <h2 id="m17">Newsletter and Electronic Communications</h2>
        <p>
          We send newsletters, emails, and other electronic notifications
          (hereinafter "newsletters") exclusively with the consent of the
          recipients or based on a legal basis. If the contents of the
          newsletter are specified during registration for the newsletter, these
          contents are decisive for the users' consent. Normally, providing your
          email address is sufficient to sign up for our newsletter. However, to
          offer you a personalised service, we may ask for your name for
          personal salutation in the newsletter or for additional information if
          necessary for the purpose of the newsletter.
        </p>
        <p>
          Deletion and restriction of processing: We may store unsubscribed
          email addresses for up to three years based on our legitimate
          interests before deleting them to be able to demonstrate previously
          given consent. The processing of these data is limited to the purpose
          of potentially defending against claims. An individual request for
          deletion is possible at any time, provided that at the same time the
          former existence of consent is confirmed. In case of obligations to
          permanently observe objections, we reserve the right to store the
          email address solely for this purpose in a blocklist.
        </p>
        <p>
          The logging of the registration process is based on our legitimate
          interests for the purpose of proving its proper execution. If we
          commission a service provider to send emails, this is done based on
          our legitimate interests in an efficient and secure mailing system.
        </p>
        <strong>Contents:</strong>{' '}
        <p>Information regarding the lottery of the next conference</p>
        <ul className="m-elements">
          <li>
            <strong>Processed data types:</strong> Inventory data (For example,
            the full name, residential address, contact information, customer
            number, etc.); Contact data (e.g. postal and email addresses or
            phone numbers); Meta, communication and process data (e.g. IP
            addresses, timestamps, identification numbers, involved parties);
            Content data (e.g. textual or pictorial messages and contributions,
            as well as information pertaining to them, such as details of
            authorship or the time of creation.). Usage data (e.g. page views
            and duration of visit, click paths, intensity and frequency of use,
            types of devices and operating systems used, interactions with
            content and features).
          </li>
          <li>
            <strong>Data subjects:</strong> Communication partner (Recipients of
            e-mails, letters, etc.); Users (e.g. website visitors, users of
            online services). Business and contractual partners.
          </li>
          <li>
            <strong>Purposes of processing:</strong> Direct marketing (e.g. by
            e-mail or postal). Information technology infrastructure (Operation
            and provision of information systems and technical devices, such as
            computers, servers, etc.)).
          </li>
          <li className="">
            <strong>Legal Basis:</strong> Consent (Article 6 (1) (a) GDPR).
            Legitimate Interests (Article 6 (1) (f) GDPR).
          </li>
          <li>
            <strong>Opt-Out: </strong>You can cancel the receipt of our
            newsletter at any time, i.e. revoke your consent or object to
            further receipt. You will find a link to cancel the newsletter
            either at the end of each newsletter or you can otherwise use one of
            the contact options listed above, preferably e-mail.{' '}
          </li>
        </ul>
        <p>
          <strong>
            Further information on processing methods, procedures and services
            used:
          </strong>
        </p>
        <ul className="m-elements">
          <li>
            <strong>Amazon Web Services (AWS): </strong>Services in the field of
            the provision of information technology infrastructure and related
            services (e.g. storage space and/or computing capacities);{' '}
            <strong>Service provider</strong>: Amazon Web Services EMEA SARL, 38
            avenue John F. Kennedy, 1855, Luxembourg;{' '}
            <span className="">
              <strong>Legal Basis:</strong> Legitimate Interests (Article 6 (1)
              (f) GDPR);{' '}
            </span>
            <strong>Website:</strong>{' '}
            <a href="https://aws.amazon.com/" target="_blank" rel="noreferrer">
              https://aws.amazon.com/
            </a>
            ; <strong>Privacy Policy:</strong>{' '}
            <a
              href="https://aws.amazon.com/privacy/"
              target="_blank"
              rel="noreferrer"
            >
              https://aws.amazon.com/privacy/
            </a>
            ; <strong>Data Processing Agreement:</strong>{' '}
            <a
              href="https://aws.amazon.com/compliance/gdpr-center/"
              target="_blank"
              rel="noreferrer"
            >
              https://aws.amazon.com/compliance/gdpr-center/
            </a>
            . <strong>Basis for third-country transfers:</strong> Data Privacy
            Framework (DPF), Standard Contractual Clauses (
            <a
              href="https://aws.amazon.com/en/service-terms/"
              target="_blank"
              rel="noreferrer"
            >
              https://aws.amazon.com/en/service-terms/
            </a>
            ), Data Privacy Framework (DPF)Standard Contractual Clauses (
            <a
              href="https://aws.amazon.com/en/service-terms/"
              target="_blank"
              rel="noreferrer"
            >
              https://aws.amazon.com/en/service-terms/
            </a>
            ).
          </li>
        </ul>
        <h2 id="m136">Profiles in Social Networks (Social Media)</h2>
        <p>
          We maintain online presences within social networks and process user
          data in this context in order to communicate with the users active
          there or to offer information about us.
        </p>
        <p>
          We would like to point out that user data may be processed outside the
          European Union. This may entail risks for users, e.g. by making it
          more difficult to enforce users' rights.
        </p>
        <p>
          In addition, user data is usually processed within social networks for
          market research and advertising purposes. For example, user profiles
          can be created on the basis of user behaviour and the associated
          interests of users. The user profiles can then be used, for example,
          to place advertisements within and outside the networks which are
          presumed to correspond to the interests of the users. For these
          purposes, cookies are usually stored on the user's computer, in which
          the user's usage behaviour and interests are stored. Furthermore, data
          can be stored in the user profiles independently of the devices used
          by the users (especially if the users are members of the respective
          networks or will become members later on).
        </p>
        <p>
          For a detailed description of the respective processing operations and
          the opt-out options, please refer to the respective data protection
          declarations and information provided by the providers of the
          respective networks.
        </p>
        <p>
          Also in the case of requests for information and the exercise of
          rights of data subjects, we point out that these can be most
          effectively pursued with the providers. Only the providers have access
          to the data of the users and can directly take appropriate measures
          and provide information. If you still need help, please do not
          hesitate to contact us.
        </p>
        <ul className="m-elements">
          <li>
            <strong>Processed data types:</strong> Contact data (e.g. postal and
            email addresses or phone numbers); Content data (e.g. textual or
            pictorial messages and contributions, as well as information
            pertaining to them, such as details of authorship or the time of
            creation.); Usage data (e.g. page views and duration of visit, click
            paths, intensity and frequency of use, types of devices and
            operating systems used, interactions with content and features).
            Meta, communication and process data (e.g. IP addresses, timestamps,
            identification numbers, involved parties).
          </li>
          <li>
            <strong>Data subjects:</strong> Users (e.g. website visitors, users
            of online services).
          </li>
          <li>
            <strong>Purposes of processing:</strong> Communication; Feedback
            (e.g. collecting feedback via online form); Public relations. Public
            relations and informational purposes.
          </li>
          <li>
            <strong>Retention and deletion:</strong> Deletion in accordance with
            the information provided in the section "General Information on Data
            Retention and Deletion".
          </li>
          <li className="">
            <strong>Legal Basis:</strong> Legitimate Interests (Article 6 (1)
            (f) GDPR).
          </li>
        </ul>
        <p>
          <strong>
            Further information on processing methods, procedures and services
            used:
          </strong>
        </p>
        <ul className="m-elements">
          <li>
            <strong>Bluesky: </strong>Decentralised social media network -
            allows for the creation, sharing and commenting on content, as well
            as following user profiles; <strong>Service provider</strong>:
            Bluesky, PBLLC., Seattle, USA,{' '}
            <a href="mailto:support@bsky.app">support@bsky.app</a>;{' '}
            <span className="">
              <strong>Legal Basis:</strong> Legitimate Interests (Article 6 (1)
              (f) GDPR);{' '}
            </span>
            <strong>Website:</strong>{' '}
            <a href="https://bsky.social/" target="_blank" rel="noreferrer">
              https://bsky.social/
            </a>
            . <strong>Privacy Policy:</strong>{' '}
            <a
              href="https://bsky.social/about/support/privacy-policy"
              target="_blank"
              rel="noreferrer"
            >
              https://bsky.social/about/support/privacy-policy
            </a>
            .
          </li>
          <li>
            <strong>LinkedIn: </strong>Social network - We are jointly
            responsible with LinkedIn Ireland Unlimited Company for the
            collection (but not the further processing) of visitor data, which
            is used to create "Page Insights" (statistics) for our LinkedIn
            profiles. This data includes information about the types of content
            users view or interact with, as well as the actions they take. It
            also includes details about the devices used, such as IP addresses,
            operating systems, browser types, language settings, and cookie
            data, as well as profile details of users, such as job function,
            country, industry, seniority, company size, and employment status.
            Privacy information regarding the processing of user data by
            LinkedIn can be found in LinkedIn's privacy policy:{' '}
            <a
              href="https://www.linkedin.com/legal/privacy-policy"
              target="_blank"
              rel="noreferrer"
            >
              https://www.linkedin.com/legal/privacy-policy.
            </a>
            <br />
            We have entered into a special agreement with LinkedIn Ireland
            ("Page Insights Joint Controller Addendum,"{' '}
            <a
              href="https://legal.linkedin.com/pages-joint-controller-addendum"
              target="_blank"
              rel="noreferrer"
            >
              https://legal.linkedin.com/pages-joint-controller-addendum
            </a>
            ), which specifically regulates the security measures LinkedIn must
            comply with and in which LinkedIn has agreed to fulfill the rights
            of data subjects (i.e., users can, for example, direct requests for
            information or deletion directly to LinkedIn). The rights of users
            (particularly the right to information, deletion, objection, and to
            lodge a complaint with the competent supervisory authority) are not
            restricted by our agreements with LinkedIn. The joint responsibility
            is limited to the collection of data and its transmission to
            LinkedIn Ireland Unlimited Company, a company based in the EU.
            Further processing of the data is the sole responsibility of
            LinkedIn Ireland Unlimited Company, particularly concerning the
            transfer of data to the parent company LinkedIn Corporation in the
            USA; <strong>Service provider</strong>: LinkedIn Ireland Unlimited
            Company, Wilton Place, Dublin 2, Ireland;{' '}
            <span className="">
              <strong>Legal Basis:</strong> Legitimate Interests (Article 6 (1)
              (f) GDPR);{' '}
            </span>
            <strong>Website:</strong>{' '}
            <a href="https://www.linkedin.com" target="_blank" rel="noreferrer">
              https://www.linkedin.com
            </a>
            ; <strong>Privacy Policy:</strong>{' '}
            <a
              href="https://www.linkedin.com/legal/privacy-policy"
              target="_blank"
              rel="noreferrer"
            >
              https://www.linkedin.com/legal/privacy-policy
            </a>
            ; <strong>Basis for third-country transfers:</strong> Data Privacy
            Framework (DPF), Standard Contractual Clauses (
            <a
              href="https://legal.linkedin.com/dpa"
              target="_blank"
              rel="noreferrer"
            >
              https://legal.linkedin.com/dpa
            </a>
            ), Data Privacy Framework (DPF)Standard Contractual Clauses (
            <a
              href="https://legal.linkedin.com/dpa"
              target="_blank"
              rel="noreferrer"
            >
              https://legal.linkedin.com/dpa
            </a>
            ). <strong>Opt-Out:</strong>{' '}
            <a
              href="https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out"
              target="_blank"
              rel="noreferrer"
            >
              https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out
            </a>
            .
          </li>
        </ul>
        <h2 id="m723">Management, Organization and Utilities</h2>
        <p>
          We use services, platforms and software from other providers
          (hereinafter referred to as " third-party providers") for the purposes
          of organizing, administering, planning and providing our services.
          When selecting third-party providers and their services, we comply
          with the legal requirements.{' '}
        </p>
        <p>
          Within this context, personal data may be processed and stored on the
          servers of third-party providers. This may include various data that
          we process in accordance with this privacy policy. This data may
          include in particular master data and contact data of users, data on
          processes, contracts, other processes and their contents.
        </p>
        <p>
          If users are referred to the third-party providers or their software
          or platforms in the context of communication, business or other
          relationships with us, the third-party provider processing may process
          usage data and metadata that can be processed by them for security
          purposes, service optimisation or marketing purposes. We therefore ask
          you to read the data protection notices of the respective third party
          providers.
        </p>
        <ul className="m-elements">
          <li>
            <strong>Processed data types:</strong> Content data (e.g. textual or
            pictorial messages and contributions, as well as information
            pertaining to them, such as details of authorship or the time of
            creation.); Usage data (e.g. page views and duration of visit, click
            paths, intensity and frequency of use, types of devices and
            operating systems used, interactions with content and features).
            Meta, communication and process data (e.g. IP addresses, timestamps,
            identification numbers, involved parties).
          </li>
          <li>
            <strong>Data subjects:</strong> Communication partner (Recipients of
            e-mails, letters, etc.); Users (e.g. website visitors, users of
            online services). Business and contractual partners.
          </li>
          <li>
            <strong>Purposes of processing:</strong> Provision of contractual
            services and fulfillment of contractual obligations; Office and
            organisational procedures; Provision of our online services and
            usability; Information technology infrastructure (Operation and
            provision of information systems and technical devices, such as
            computers, servers, etc.)). Security measures.
          </li>
          <li>
            <strong>Retention and deletion:</strong> Deletion in accordance with
            the information provided in the section "General Information on Data
            Retention and Deletion".
          </li>
          <li className="">
            <strong>Legal Basis:</strong> Legitimate Interests (Article 6 (1)
            (f) GDPR).
          </li>
        </ul>
        <p>
          <strong>
            Further information on processing methods, procedures and services
            used:
          </strong>
        </p>
        <ul className="m-elements">
          <li>
            <strong>GitHub: </strong>Platform for version control of software
            projects. Developers are enabled to upload their code to
            repositories and track changes, as well as use tools for project
            management in software development;{' '}
            <strong>Service provider</strong>: GitHub B.V., Netherlands,{' '}
            <a
              href="https://support.github.com/contact/privacy"
              target="_blank"
              rel="noreferrer"
            >
              https://support.github.com/contact/privacy
            </a>
            ;{' '}
            <span className="">
              <strong>Legal Basis:</strong> Legitimate Interests (Article 6 (1)
              (f) GDPR);{' '}
            </span>
            <strong>Website:</strong>{' '}
            <a href="https://github.com" target="_blank" rel="noreferrer">
              https://github.com
            </a>
            . <strong>Privacy Policy:</strong>{' '}
            <a
              href="https://docs.github.com/en/site-policy/privacy-policies/github-general-privacy-statement"
              target="_blank"
              rel="noreferrer"
            >
              https://docs.github.com/en/site-policy/privacy-policies/github-general-privacy-statement
            </a>
            .
          </li>
          <li>
            <strong>GitLab: </strong>GitLab provides a comprehensive solution
            for software development with features such as repository management
            to centrally store and version code. Continuous
            Integration/Continuous Deployment (CI/CD) automates the processes of
            testing and deploying code. With code review and collaboration,
            teams can review changes and work efficiently on the source code.
            Issue tracking enables the management of tasks and bugs.
            Additionally, GitLab offers project management tools for planning
            and organising projects. Security and compliance features ensure the
            protection of the code, while monitoring and error tracking support
            supervision and rapid problem detection;{' '}
            <strong>Service provider</strong>: GitLab B.V., Eduard van
            Beinumstraat 28, 1077 CZ Amsterdam, Netherlands;{' '}
            <span className="">
              <strong>Legal Basis:</strong> Legitimate Interests (Article 6 (1)
              (f) GDPR);{' '}
            </span>
            <strong>Website:</strong>{' '}
            <a
              href="https://about.gitlab.com/"
              target="_blank"
              rel="noreferrer"
            >
              https://about.gitlab.com/
            </a>
            ; <strong>Privacy Policy:</strong>{' '}
            <a
              href="https://about.gitlab.com/privacy/"
              target="_blank"
              rel="noreferrer"
            >
              https://about.gitlab.com/privacy/
            </a>
            ; <strong>Data Processing Agreement:</strong>{' '}
            <a
              href="https://handbook.gitlab.com/handbook/legal/data-processing-agreement/"
              target="_blank"
              rel="noreferrer"
            >
              https://handbook.gitlab.com/handbook/legal/data-processing-agreement/
            </a>{' '}
            Provided by the service provider.{' '}
            <strong>Basis for third-country transfers:</strong> Data Privacy
            Framework (DPF), Standard Contractual Clauses (
            <a
              href="https://handbook.gitlab.com/handbook/legal/data-processing-agreement/"
              target="_blank"
              rel="noreferrer"
            >
              https://handbook.gitlab.com/handbook/legal/data-processing-agreement/
            </a>
            ), Data Privacy Framework (DPF)Standard Contractual Clauses (
            <a
              href="https://handbook.gitlab.com/handbook/legal/data-processing-agreement/"
              target="_blank"
              rel="noreferrer"
            >
              https://handbook.gitlab.com/handbook/legal/data-processing-agreement/
            </a>
            ).
          </li>
        </ul>
        <h2 id="m15">Changes and Updates</h2>
        <p>
          We kindly ask you to inform yourself regularly about the contents of
          our data protection declaration. We will adjust the privacy policy as
          changes in our data processing practices make this necessary. We will
          inform you as soon as the changes require your cooperation (e.g.
          consent) or other individual notification.
        </p>
        <p>
          If we provide addresses and contact information of companies and
          organizations in this privacy policy, we ask you to note that
          addresses may change over time and to verify the information before
          contacting us.
        </p>
        <h2 id="m42">Terminology and Definitions</h2>
        <p>
          In this section, you will find an overview of the terminology used in
          this privacy policy. Where the terminology is legally defined, their
          legal definitions apply. The following explanations, however, are
          primarily intended to aid understanding.
        </p>
        <ul className="glossary">
          <li>
            <strong>Contact data:</strong> Contact details are essential
            information that enables communication with individuals or
            organizations. They include, among others, phone numbers, postal
            addresses, and email addresses, as well as means of communication
            like social media handles and instant messaging identifiers.{' '}
          </li>
          <li>
            <strong>Content data:</strong> Content data comprise information
            generated in the process of creating, editing, and publishing
            content of all types. This category of data may include texts,
            images, videos, audio files, and other multimedia content published
            across various platforms and media. Content data are not limited to
            the content itself but also include metadata providing information
            about the content, such as tags, descriptions, authorship details,
            and publication dates.{' '}
          </li>
          <li>
            <strong>Contract data:</strong> Contract data are specific details
            pertaining to the formalisation of an agreement between two or more
            parties. They document the terms under which services or products
            are provided, exchanged, or sold. This category of data is essential
            for managing and fulfilling contractual obligations and includes
            both the identification of the contracting parties and the specific
            terms and conditions of the agreement. Contract data may encompass
            the start and end dates of the contract, the nature of the
            agreed-upon services or products, pricing arrangements, payment
            terms, termination rights, extension options, and special conditions
            or clauses. They serve as the legal foundation for the relationship
            between the parties and are crucial for clarifying rights and
            duties, enforcing claims, and resolving disputes.{' '}
          </li>
          <li>
            <strong>Controller:</strong> "Controller" means the natural or legal
            person, public authority, agency or other body which, alone or
            jointly with others, determines the purposes and means of the
            processing of personal data.{' '}
          </li>
          <li>
            <strong>Employees:</strong> As employees, individuals are those who
            are engaged in an employment relationship, whether as staff,
            employees, or in similar positions. Employment refers to a legal
            relationship between an employer and an employee, established
            through an employment contract or agreement. It entails the
            obligation of the employer to pay the employee remuneration while
            the employee performs their work. The employment relationship
            encompasses various stages, including establishment, where the
            employment contract is concluded, execution, where the employee
            carries out their work activities, and termination, when the
            employment relationship ends, whether through termination, mutual
            agreement, or otherwise. Employee data encompasses all information
            pertaining to these individuals within the context of their
            employment. This includes aspects such as personal identification
            details, identification numbers, salary and banking information,
            working hours, holiday entitlements, health data, and performance
            assessments.{' '}
          </li>
          <li>
            <strong>Inventory data:</strong> Inventory data encompass essential
            information required for the identification and management of
            contractual partners, user accounts, profiles, and similar
            assignments. These data may include, among others, personal and
            demographic details such as names, contact information (addresses,
            phone numbers, email addresses), birth dates, and specific
            identifiers (user IDs). Inventory data form the foundation for any
            formal interaction between individuals and services, facilities, or
            systems, by enabling unique assignment and communication.{' '}
          </li>
          <li>
            <strong>Log data:</strong> Protocol data, or log data, refer to
            information regarding events or activities that have been logged
            within a system or network. These data typically include details
            such as timestamps, IP addresses, user actions, error messages, and
            other specifics about the usage or operation of a system. Protocol
            data is often used for analyzing system issues, monitoring security,
            or generating performance reports.{' '}
          </li>
          <li>
            <strong>Meta, communication and process data:</strong> Meta-,
            communication, and procedural data are categories that contain
            information about how data is processed, transmitted, and managed.
            Meta-data, also known as data about data, include information that
            describes the context, origin, and structure of other data. They can
            include details about file size, creation date, the author of a
            document, and modification histories. Communication data capture the
            exchange of information between users across various channels, such
            as email traffic, call logs, messages in social networks, and chat
            histories, including the involved parties, timestamps, and
            transmission paths. Procedural data describe the processes and
            operations within systems or organisations, including workflow
            documentations, logs of transactions and activities, and audit logs
            used for tracking and verifying procedures.{' '}
          </li>
          <li>
            <strong>Payment Data:</strong> Payment data comprise all information
            necessary for processing payment transactions between buyers and
            sellers. This data is crucial for e-commerce, online banking, and
            any other form of financial transaction. It includes details such as
            credit card numbers, bank account information, payment amounts,
            transaction dates, verification numbers, and billing information.
            Payment data may also contain information on payment status,
            chargebacks, authorizations, and fees.{' '}
          </li>
          <li>
            <strong>Personal Data:</strong> "personal data" means any
            information relating to an identified or identifiable natural person
            ("data subject"); an identifiable natural person is one who can be
            identified, directly or indirectly, in particular by reference to an
            identifier such as a name, an identification number, location data,
            an online identifier or to one or more factors specific to the
            physical, physiological, genetic, mental, economic, cultural or
            social identity of that natural person.{' '}
          </li>
          <li>
            <strong>Processing:</strong> The term "processing" covers a wide
            range and practically every handling of data, be it collection,
            evaluation, storage, transmission or erasure.{' '}
          </li>
          <li>
            <strong>Usage data:</strong> Usage data refer to information that
            captures how users interact with digital products, services, or
            platforms. These data encompass a wide range of information that
            demonstrates how users utilise applications, which features they
            prefer, how long they spend on specific pages, and through what
            paths they navigate an application. Usage data can also include the
            frequency of use, timestamps of activities, IP addresses, device
            information, and location data. They are particularly valuable for
            analysing user behaviour, optimising user experiences, personalising
            content, and improving products or services. Furthermore, usage data
            play a crucial role in identifying trends, preferences, and
            potential problem areas within digital offerings{' '}
          </li>
        </ul>
      </div>
    </div>
  );
}
